import React, { useState } from "react";
import {
  Button,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  styled,
  Select,
  MenuItem,
  Container,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";

import { useMutation, useQuery } from "react-query";
import {
  deleteFirm,
  getAllBusinessCategory,
  getAllFirm,
} from "app/services/schoolService/firm.service";
import DialogHeader from "app/components/common/DialogHeader";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "app/components/common/CustomLoader";
import { LoadingButton } from "@mui/lab";
import { IndianCurrency } from "app/utils/helper";
import CommonTable1 from "app/components/Tables/CommonTable1";
import ExportDialog from "app/components/common/exportDialog";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import CommonFilterDialog from "app/components/common/Dialogs/CommonFilterDialog";
import FilterButton from "app/components/common/FilterButton";
import TableActionButtons from "app/components/common/TableActionButtons";
import AddEditFirmDialog from "app/components/common/Dialogs/ledger/AddEditFirmDialog";
import ByBusinessCategory from "app/components/commonSeperateMultipleFilter/ByBusinessCategory";
import { Helmet } from "react-helmet";
const Firms = () => {
  const [state, setState] = useState({
    tableData: [],
    cat: null,
    selectedCat: "",
    edit: false,
    editData: {},
    addEditDialog: false,
  });
  const [dialogs, setDialogs] = useState({
    add: false,
    delete: false,
    update: false,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const [selectedBusinessCategory, setSelectedBusinessCategory] = useState([]);
  const [checkboxes, setCheckboxes] = useState({
    one: false,
  });

  const {
    isLoading,
    isFetching,
    refetch: getAllFirmRefetch,
  } = useQuery({
    queryKey: ["getAllFirm"],
    queryFn: () =>
      getAllFirm({
        withMoney: "Yes",
        ...(checkboxes?.one && {
          ...(selectedBusinessCategory?.length > 0 && {
            reqBusinessCategoryList: JSON.stringify(
              selectedBusinessCategory
                .filter((item) => item.checked)
                .map((item) => item.businessCategoryName)
            ),
          }),
        }),
        // { businessCategory: state?.selectedCat }
      }),
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, tableData: data?.list }));
      setModalOpen(false);
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  const { mutate: delFirmMutate, loading: delFirmLoading } = useMutation(
    deleteFirm,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setDialogs((prev) => ({ ...prev, delete: false }));
        getAllFirmRefetch();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    }
  );

  const columns = [
    {
      header: "Sr.No",
      Cell: ({
        cell: {
          row: { id },
        },
      }) => (
        <Typography>
          {parseInt(id) + 1 < 10 ? `0${parseInt(id) + 1}` : parseInt(id) + 1}
        </Typography>
      ),
      size: 30,
    },
    {
      header: "Firm Name",
      accessorKey: "firmName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Link
            style={{
              fontWeight: "bold",
            }}
            to={`/ledger-n-account/firm/profile/${original?._id}`}
          >
            {original?.firmName}
          </Link>
        );
      },
      size: 40,
    },
    {
      header: "Owner Name",
      accessorKey: "firstName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            {" "}
            {original?.firstName ?? ""} {original?.middleName ?? ""}{" "}
            {original?.lastName ?? ""}
          </Typography>
        );
      },
      size: 30,
    },
    {
      header: "Business Category",
      accessorKey: "businessCategory",
      size: 30,
    },
    {
      header: "Total Collection",
      accessorKey: "collectionAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>{IndianCurrency(original?.collectionAmount)}</Typography>
        );
      },
      size: 25,
    },
    {
      header: "Total Expense",
      accessorKey: "expenseAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>{IndianCurrency(original?.expenseAmount)}</Typography>
        );
      },
      size: 25,
    },
    {
      header: "Created By",
      accessorKey: "createdBy.personName",
      size: 30,
    },
    {
      header: "Actions",
      Cell: ({ cell }) => {
        return (
          <TableActionButtons
            showDelete
            showEdit
            handleEdit={() => {
              setState((prev) => ({
                ...prev,
                edit: true,
                editData: cell?.row?.original,
                addEditDialog: true,
              }));
            }}
            handleDelete={() => {
              setState((prev) => ({
                ...prev,
                delActive: cell?.row?.original?._id,
              }));
              setDialogs((prev) => ({ ...prev, delete: true }));
            }}
          />
        );
      },
      size: 30,
    },
  ];

  const {
    isLoading: getAllBusinessCategoryLoading,
    isFetching: getAllBusinessCategoryFetching,
    refetch: getAllBusinessCategoryRefetch,
  } = useQuery({
    queryKey: ["getAllBusinessCategory"],
    queryFn: () => getAllBusinessCategory(),
    onSuccess: (success) => {
      setState((prev) => ({ ...prev, cat: success?.data?.list }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  let header = [
    "Firm_Name",
    "Owner_Name",
    "Business_Category",
    "Collection",
    "Expense",
    "Created_By",
  ];

  let exportArray = state.tableData?.map((item) => {
    return {
      Firm_Name: item?.firmName ?? "--",
      Owner_Name: `${item?.firstName ?? ""} ${item?.middleName ?? ""} ${
        item?.lastName ?? ""
      }`,
      Business_Category: item?.businessCategory ?? "--",
      Collection: item?.totalCollection ?? "--",
      Expense: item?.totalExpense ?? "--",
      Created_By: item?.createdBy?.personName ?? "--",
    };
  });
  return (
    <>
      <Helmet>
        <title>Ledger & Account | Firms</title>
      </Helmet>
      <CustomLoader
        show={getAllBusinessCategoryLoading || getAllBusinessCategoryFetching}
      />
      {/* exprot dialog */}
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Firms"
      />
      <CommonFilterDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Filter"
        handleSaveAndView={() => getAllFirmRefetch()}
        loading={isLoading || isFetching}
      >
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={checkboxes.one}
                onClick={() => {
                  checkboxes?.one === true &&
                    setState((prev) => ({ ...prev, selectedCat: "" }));
                  setCheckboxes((prev) => ({ ...prev, one: !prev.one }));
                }}
              />
            }
            label="Business Category"
          />
          {checkboxes.one && (
            <Box>
              <ByBusinessCategory
                prevBusinessCategoryList={selectedBusinessCategory}
                setSelectedBusinessCategory={setSelectedBusinessCategory}
              />
              {/* <Stack>
                <CustomSelect
                  displayEmpty
                  value={state?.selectedCat}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      selectedCat: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={""} disabled>
                    Select
                  </MenuItem>
                  {state?.cat?.map((cat) => (
                    <MenuItem value={cat}>{cat}</MenuItem>
                  ))}
                </CustomSelect>
              </Stack> */}
            </Box>
          )}
        </DialogContent>
      </CommonFilterDialog>
      {/* Filter modal */}

      {/* Add / Edit New Firm dialog */}
      <AddEditFirmDialog
        open={state.addEditDialog}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            addEditDialog: false,
            edit: false,
            editData: {},
          }))
        }
        edit={state.edit}
        editData={state.editData}
        refetch={() => {
          getAllFirmRefetch();
          getAllBusinessCategory();
        }}
        businessCategory={state.cat}
        getAllBusinessCategoryRefetch={() => getAllBusinessCategoryRefetch()}
      />

      {/* Delete firm dialog */}
      <Dialog
        open={dialogs.delete}
        onClose={() => setDialogs((prev) => ({ ...prev, delete: false }))}
        maxWidth={700}
      >
        <DialogHeader
          title="Delete Firm"
          handleClose={() => {
            setDialogs((prev) => ({ ...prev, delete: false }));
          }}
        />
        <DialogContent
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src="/deactivate_account.png" />
          <Typography fontSize="2rem">Are you sure want to delete</Typography>
          <Stack flex direction="row" gap={2}>
            <Button
              size="large"
              variant="outlined"
              color="error"
              onClick={() => setDialogs((prev) => ({ ...prev, delete: false }))}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              variant="outlined"
              color="secondary"
              loading={delFirmLoading}
              onClick={() => delFirmMutate(state?.delActive)}
            >
              Yes
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FilterButton
            count={
              Object.values(checkboxes).filter((item) => item === true).length
            }
            hanldeClick={() => setModalOpen(true)}
          />

          <Stack direction={"row"} gap={2}>
            <Button
              onClick={() =>
                setState((prev) => ({ ...prev, addEditDialog: true }))
              }
              variant="outlined"
              color="secondary"
              size="large"
            >
              + Add New
            </Button>
            <Box sx={{ mt: "-10px" }}>
              <ExportButtonGroup
                handleClickExcel={() => setOpenCheckBox(true)}
                showPdf={false}
              />
            </Box>
          </Stack>
        </Stack>

        {/* <Table columns={columns} data={tableData || []} /> */}
        <Stack mt={2}>
          <CommonTable1
            columns={columns}
            data={state.tableData}
            maxHeight="75vh"
          />
        </Stack>
      </Container>
    </>
  );
};

export default Firms;

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  color: "#0C2F49",
  fontWeight: 500,
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  width: "200px",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));
