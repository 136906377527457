import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";
import {
  addFeeStructureInGroup,
  approveFeeStructure,
  deleteFeeStructure2,
  rejectFeeStructure,
  updateFeeStructureInGroup,
} from "app/services/management";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { toast } from "react-toastify";
import DialogHeader from "app/components/common/DialogHeader";
import { getClassDropdownLogic } from "app/services/reception";
import { useSelector } from "react-redux";
import {
  // isCollege,
  // isSchool,
  // selectActiveSemester,
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { Check, Close, Delete } from "@mui/icons-material";
import { PencilSquare } from "react-bootstrap-icons";
import {
  deleteFeeStructureGroup,
  getFeeStructureGroup,
  getFeeType,
  getSummarizedFeeStructureGroup,
} from "app/services/schoolService/newFee.service";
import FeeLinkingDialog from "./FeeLinkingDialog";
import ViewFineDetailDialog from "./ViewFineDetailDialog";
import AddGroupFeeStructureDialog from "./AddGroupFeeStructureDialog";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import ApproveDialog from "app/components/ConfirmationDialog/ApproveDialog";
import RejectDialog from "app/components/ConfirmationDialog/RejectDialog";
import { getClassDropdown } from "app/services/schoolService/common.service";
const initialState = {
  classDocId: "",
  sectionList: [],
  section: "",
  feeStructureGroupDocId: "",
  feeStructureGroupDocIdForFine: "",
  feeStructureDocId: "",
  feeType: "",
  feeTypeFirstCall: false,
  feeTypeList: [],
  feeStructureList: [],
  feeStructureGroupObj: null,
  addEditFeeStructureDialog: false,
  linkingDialog: false,
  feeStructureLinkingGroupDocId: "",
  linkDialogData: {},
  feeOccurenceDialog: false,
  feeOccurrence: "",
  deleteId: "",
  approveId: "",
  rejeectId: "",
  dialog: false,
  deleteDialog: false,
  approveDialog: false,
  rejectDialog: false,
};
const ViewGroupedFeeStructureAcademic = ({
  // open,
  // handleClose,
  refetch,
  classDocId,
}) => {
  const [state, setState] = useState(initialState);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  // const verifyIsSchool = useSelector(isSchool);
  // const verifyIsCollege = useSelector(isCollege);
  // const selectedSemester = useSelector(selectActiveSemester);
  const calulatePercentage = (amount, percentage) => {
    if (percentage) {
      return Number((Number(amount) * Number(percentage ?? 0)) / 100);
    } else {
      return 0;
    }
  };
  const handleCloseDialog = () => {
    setState((prev) => ({
      ...prev,
      classDocId: "",
      feeTypeFirstCall: false,
      section: "",
      feeType: "",
    }));
    // handleClose();
  };

  const handleAddEditFeeStructure = (item) => {
    const discountedFeeAmount =
      Number(item?.generalFeeAmount) - Number(item?.categoryDiscountAmount);
    const percentage = (discountedFeeAmount * Number(item?.taxRate ?? 0)) / 100;
    if (item?.edit) {
      const body = {
        feeRelaxationCategory: item?.feeRelaxationCategory,
        feeStructureDocId: item?._id,
        categoryDiscountAmount: item?.categoryDiscountAmount,
        discountedFeeAmount,
        taxRate: item?.taxRate ?? 0,
        feeAmount: discountedFeeAmount + Number(percentage),
      };
      updateFeeStructureInGroupMutate(body);
    } else {
      const body = {
        feeRelaxationCategory: item?.feeRelaxationCategory,
        feeStructureGroupDocId: state.feeStructureGroupObj?._id,
        categoryDiscountAmount: item?.categoryDiscountAmount,
        discountedFeeAmount,
        taxRate: item?.taxRate ?? 0,
        feeAmount: discountedFeeAmount + Number(percentage),
      };
      addFeeStructureInGroupMutate(body);
    }
  };
  const {
    mutate: addFeeStructureInGroupMutate,
    isLoading: addFeeStructureInGroupLoading,
  } = useMutation(addFeeStructureInGroup, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      getFeeStructureGroupRefetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    mutate: updateFeeStructureInGroupMutate,
    isLoading: updateFeeStructureInGroupLoading,
  } = useMutation(updateFeeStructureInGroup, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      getFeeStructureGroupRefetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { isLoading: loadingClass, data: classData } = useQuery({
    queryKey: ["getClassInfo", selectedSession, selectedMedium],
    queryFn: () =>
      getClassDropdownLogic({ schoolDocId, selectedSession, selectedMedium }),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: state.viewDialog ? true : false,
  });
  const {
    data: classData2,
    isLoading: getClassDataLoading,
    isFetching: getClassDataFetching,
  } = useQuery({
    queryKey: [
      "getClassDropdown",
      selectedSchoolId,
      // ...(verifyIsSchool ? [selectedMedium] : []),
      // ...(verifyIsCollege ? [selectedSemester] : []),
    ],
    queryFn: () =>
      getClassDropdown(selectedSchoolId, {
        // ...(verifyIsCollege && { semester: selectedSemester }),
        // ...(verifyIsSchool && { medium: selectedMedium }),
      }),
    onSuccess: (data) => {},
    // enabled: open ? true : false,
  });
  const {
    isLoading: getSummarizedFeeStructureGroupLoading,
    isFetching: getSummarizedFeeStructureGroupFetching,
  } = useQuery({
    queryKey: [
      "getSummarizedFeeStructureGroupByClassDocId",
      selectedSession,
      state.classDocId,
    ],
    queryFn: () =>
      getSummarizedFeeStructureGroup({
        session: selectedSession,
        classDocId: state.classDocId,
        // ...(verifyIsCollege && { semester: selectedSemester }),
        // ...(verifyIsSchool && { medium: selectedMedium }),
      }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        sectionList: success?.data?.obj?.sectionList,
        section: success?.data?.obj?.sectionList[0]?.sectionName,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: state.classDocId ? true : false,
  });
  const { isLoading: getFeeTypeLoading, isFetching: getFeeTypeFetching } =
    useQuery({
      queryKey: ["getFeeType", selectedSession, state.section],
      queryFn: () =>
        getFeeType({
          classDocId: state.classDocId,
          section: state.section,
          session: selectedSession,
          // ...(verifyIsCollege && { semester: selectedSemester }),
        }),
      onSuccess: (success) => {
        setState((prev) => ({
          ...prev,
          feeTypeList: success?.data?.feeTypes,
          feeType: success?.data?.feeType,
          feeStructureList: success?.data?.feeStructureList,
          feeStructureGroupObj: success?.data?.feeStructureGroup,
          feeTypeFirstCall: true,
        }));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
        setState((prev) => ({
          ...prev,
          feeStructureList: [],
          feeStructureGroupObj: null,
        }));
      },
      enabled: state.section ? true : false,
    });

  const {
    mutate: deleteFeeStructureGroupMutate,
    isLoading: deleteFeeStructureGroupLoading,
  } = useMutation(deleteFeeStructureGroup, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      getFeeStructureGroupRefetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    isLoading: getFeeStructureGroupLoading,
    isFetching: getFeeStructureGroupFetching,
    refetch: getFeeStructureGroupRefetch,
  } = useQuery({
    queryKey: ["getFeeStructureGroup", selectedSession, state.feeType],
    queryFn: () =>
      getFeeStructureGroup({
        classDocId: state.classDocId,
        section: state.section,
        session: selectedSession,
        feeType: state.feeType,
      }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        feeType: success?.data?.feeType,
        feeStructureList: success?.data?.feeStructureList,
        feeStructureGroupObj: success?.data?.feeStructureGroup,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      setState((prev) => ({
        ...prev,
        feeStructureList: [],
        feeStructureGroupObj: null,
      }));
    },
    enabled:
      state.feeTypeFirstCall && state.feeType && state.section ? true : false,
  });
  const {
    mutate: deleteFeeStructure2Mutate,
    isLoading: deleteFeeStructure2Loading,
  } = useMutation(deleteFeeStructure2, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      getFeeStructureGroupRefetch();
      handleCloseDeleteDialog();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    mutate: approveFeeStructureMutate,
    isLoading: approveFeeStructureLoading,
  } = useMutation(approveFeeStructure, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      getFeeStructureGroupRefetch();
      handleCloseApproveDialog();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    mutate: rejectFeeStructureMutate,
    isLoading: rejectFeeStructureLoading,
  } = useMutation(rejectFeeStructure, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      getFeeStructureGroupRefetch();
      handleCloseRejectDialog();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  useEffect(() => {
    if (classDocId) {
      setState((prev) => ({ ...prev, classDocId }));
    }
  }, [classDocId]);

  const handleCloseDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteId: "",
      deleteDialog: false,
    }));
  };
  const handleCloseApproveDialog = () => {
    setState((prev) => ({
      ...prev,
      approveId: "",
      approveDialog: false,
    }));
  };
  const handleCloseRejectDialog = () => {
    setState((prev) => ({
      ...prev,
      rejeectId: "",
      rejectDialog: false,
    }));
  };
  const handleOpenDeleteDialog = (data) => {
    setState((prev) => ({
      ...prev,
      deleteId: data,
      deleteDialog: true,
    }));
  };
  const handleOpenArroveDialog = (data) => {
    setState((prev) => ({
      ...prev,
      approveId: data,
      approveDialog: true,
    }));
  };
  const handleOpenRejectDialog = (data) => {
    setState((prev) => ({
      ...prev,
      rejeectId: data,
      rejectDialog: true,
    }));
  };
  const handledeleteFeeStructure = () => {
    deleteFeeStructure2Mutate(state.deleteId);
  };
  const handleApproveFeeStructure = () => {
    approveFeeStructureMutate(state.approveId);
  };
  const handleRejectFeeStructure = () => {
    rejectFeeStructureMutate(state.rejeectId);
  };
  console.log("classData2", classData2);

  return (
    <>
      <CustomLoader
        show={
          getSummarizedFeeStructureGroupLoading ||
          getSummarizedFeeStructureGroupFetching ||
          approveFeeStructureLoading ||
          rejectFeeStructureLoading ||
          deleteFeeStructure2Loading ||
          addFeeStructureInGroupLoading ||
          updateFeeStructureInGroupLoading ||
          getFeeStructureGroupLoading ||
          getFeeStructureGroupFetching
        }
      />
      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handledeleteFeeStructure}
        loading={deleteFeeStructure2Loading}
      />
      {/* Approve Dialog Confirmation */}
      <ApproveDialog
        open={state.approveDialog}
        handleClose={handleCloseApproveDialog}
        handleApprove={handleApproveFeeStructure}
        loading={approveFeeStructureLoading}
      />
      {/* Reject Dialog Confirmation */}
      <RejectDialog
        open={state.rejectDialog}
        handleClose={handleCloseRejectDialog}
        handleReject={handleRejectFeeStructure}
        loading={rejectFeeStructureLoading}
      />

      {/* linking dialog */}
      <FeeLinkingDialog
        open={state.linkingDialog}
        handleClose={() =>
          setState((prev) => ({ ...prev, linkingDialog: false }))
        }
        refetch={() => {
          getFeeStructureGroupRefetch();
        }}
        feeStructureDocId={state.linkDialogData?._id}
        linkDialogData={state.linkDialogData}
      />
      {/* view fine Detail Dialog */}
      <ViewFineDetailDialog
        open={state.feeOccurenceDialog}
        handleClose={() =>
          setState((prev) => ({
            ...prev,
            feeOccurenceDialog: false,
            feeStructureGroupDocIdForFine: "",
            feeOccurrence: "",
          }))
        }
        feeOccurrence={state.feeOccurrence}
        refetch={() => getFeeStructureGroupRefetch()}
        feeStructureGroupDocId={state.feeStructureGroupDocIdForFine}
      />
      <AddGroupFeeStructureDialog
        showDialog={state.dialog}
        onClose={() => setState((prev) => ({ ...prev, dialog: false }))}
        refetch={() => getFeeStructureGroupRefetch()}
      />
      {/* <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: { minWidth: "90%" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Fee Structure View"
            handleClose={handleCloseDialog}
          />
        </DialogTitle> */}
      {/* <DialogContent> */}
      {(getFeeTypeLoading ||
        getFeeTypeFetching ||
        loadingClass ||
        getClassDataLoading) && (
        <Stack direction={"row"} justifyContent={"center"} py={1}>
          <CircularProgress />
        </Stack>
      )}
      {/* class detail */}
      <Stack direction={"row"} gap={2} sx={{ py: 1 }}>
        <Box>
          <TextFieldLabel title={"Class-Stream"} />
          <CustomSelectBox
            size="small"
            MenuProps={MenuProps}
            displayEmpty
            fullWidth
            sx={{ width: "200px" }}
            value={state.classDocId}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                classDocId: e.target.value,
                section: "",
              }))
            }
          >
            {classData2?.data?.list?.map((item, index) => (
              <MenuItem value={item?.classDocId} key={index}>
                {item?.className_stream}
              </MenuItem>
            ))}
          </CustomSelectBox>
        </Box>
        <Box>
          <TextFieldLabel title={"Section"} />
          <Stack
            direction={"row"}
            sx={{
              border: "1px solid rgba(0,0,0,0.5)",
              borderRadius: "3px",
            }}
          >
            {state.sectionList?.map((item, i) => (
              <Typography
                key={item}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    section: item?.sectionName,
                  }))
                }
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  cursor: "pointer",
                  borderRight:
                    i !== state.sectionList?.length - 1 &&
                    "1px solid rgba(0,0,0,0.5)",
                  py: 1,
                  border:
                    item?.sectionName === state.section && "2px solid #2789FD",
                  px: 1.5,
                  backgroundColor:
                    item?.status === "REQUESTED"
                      ? "#F0C62E"
                      : item?.status === "IN_COMPLETED"
                      ? "#F10F0F"
                      : item?.status === "COMPLETED" && "#5BCE00",
                  color: "#0c0a0a",
                }}
              >
                {item?.sectionName}
              </Typography>
            ))}
          </Stack>
        </Box>
      </Stack>
      {/* fee Type Detail */}
      <FeeTypeWrapper>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          flexWrap={"wrap"}
        >
          {state.feeTypeList?.map((item, index) => (
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setState((prev) => ({ ...prev, feeType: item?.feeType }))
              }
            >
              <Stack direction={"row"} gap={0.7} alignItems={"flex-start"}>
                <FeeTypeText
                  key={index}
                  sx={{
                    borderBottom:
                      item?.feeType === state.feeType && "2px solid #2789FD",
                  }}
                >
                  {item?.feeType}
                </FeeTypeText>
                {item?.countReuestedFeeStructure === 0 &&
                  item?.incompleteFeeStructure > 0 && (
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        minHeight: "20px",
                        minWidth: "20px",
                        borderRadius: "50%",
                        border: "1px solid red",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "red",
                        }}
                      >
                        {item?.incompleteFeeStructure}
                      </Typography>
                    </Stack>
                  )}
                {item?.countReuestedFeeStructure > 0 && (
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      minHeight: "20px",
                      minWidth: "20px",
                      borderRadius: "50%",
                      border: "1px solid #F0C62E",
                      mt: "-5px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#F0C62E",
                      }}
                    >
                      {item?.countReuestedFeeStructure}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          ))}
        </Stack>
      </FeeTypeWrapper>
      {!state.feeStructureGroupObj ? (
        <>
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            py={3}
          >
            <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
              No Fee Structure Group Found !
            </Typography>
            <Button
              onClick={() => setState((prev) => ({ ...prev, dialog: true }))}
              variant="outlined"
              color="secondary"
              size="large"
              sx={{
                py: 1.3,
                background: "#fff",
                borderRadius: "10px",
                fontSize: "15px",
                mt: 2,
              }}
            >
              Create New Fee Structure
            </Button>
          </Stack>
        </>
      ) : (
        state.section && (
          <>
            {/* student detail */}
            <ViewDialogStudentWrapper>
              <Stack direction="row" gap={3}>
                <Box>
                  <StudentLabel>Student : Admission Type</StudentLabel>
                  <StudentText>
                    {state.feeStructureGroupObj?.studentAdmissionType ===
                    "oldStudent"
                      ? "Old Student"
                      : state.feeStructureGroupObj?.studentAdmissionType ===
                        "newStudent"
                      ? "New Student"
                      : "All Student"}
                  </StudentText>
                </Box>
                <Box>
                  <StudentLabel>Gender</StudentLabel>
                  <StudentText>
                    {state.feeStructureGroupObj?.gender === "allGender"
                      ? "All"
                      : state.feeStructureGroupObj?.gender}
                  </StudentText>
                </Box>
                <Box>
                  <StudentLabel>Fee Occurrence</StudentLabel>
                  <StudentText
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        feeOccurenceDialog: true,
                        feeStructureGroupDocIdForFine:
                          state.feeStructureGroupObj?._id,
                        feeOccurrence:
                          state.feeStructureGroupObj?.feeOccurrence,
                      }))
                    }
                    sx={{
                      color: "rgba(39, 137, 253, 1)",
                      cursor: "pointer",
                    }}
                  >
                    {state.feeStructureGroupObj?.feeOccurrence === "oneTime"
                      ? "One Time"
                      : "Recurring"}
                  </StudentText>
                </Box>
                <Box>
                  <StudentLabel>General Fee Amount</StudentLabel>
                  <Stack direction={"row"} gap={1}>
                    <StudentText>
                      {state.feeStructureGroupObj?.generalFeeAmount}
                    </StudentText>
                    <Delete
                      titleAccess="Delete"
                      color="error"
                      onClick={() =>
                        deleteFeeStructureGroupMutate(
                          state.feeStructureGroupObj?._id
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    />
                  </Stack>
                </Box>
                <Box>
                  <StudentLabel>Tax Rate</StudentLabel>
                  <StudentText>
                    {state.feeStructureGroupObj?.taxRate}
                  </StudentText>
                </Box>
              </Stack>
            </ViewDialogStudentWrapper>

            {/* fee detail */}
            <FeeRelaxationWrapper>
              <div className="upper_box_wrap">
                <Grid
                  container
                  spacing={1.5}
                  display={"flex"}
                  alignItems={"center"}
                  columns={15.2}
                >
                  <Grid item xs={1}>
                    <Typography className="upper_box_headingTitle">
                      Sr No.
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className="upper_box_headingTitle">
                      Fee Relaxation Category
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="upper_box_headingTitle">
                      General Fee
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="upper_box_headingTitle">
                      Discount
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={2}>
                        <Typography className="upper_box_headingTitle">
                          Discounted Fee
                        </Typography>
                      </Grid> */}

                  <Grid item xs={2}>
                    <Typography className="upper_box_headingTitle">
                      Final Fee
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="upper_box_headingTitle">
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="upper_box_headingTitle">
                      Action
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography className="upper_box_headingTitle">
                      Linked <br /> Student
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              {state.feeStructureList?.map((item, index) =>
                item?.status === "Not Created" || item?.edit ? (
                  <Grid
                    container
                    spacing={1.5}
                    display={"flex"}
                    alignItems={"center"}
                    columns={15.2}
                    key={index}
                    sx={{ padding: "10px" }}
                  >
                    <Grid item xs={1}>
                      <Typography className="upper_box_headingTitle">
                        {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className="upper_box_headingTitle">
                        {item?.feeRelaxationCategory}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="upper_box_headingTitle">
                        {IndianCurrency(item.generalFeeAmount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <CustomTextField
                        fullWidth
                        size="small"
                        type="number"
                        placeholder="discount"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography>&#8377;</Typography>
                            </InputAdornment>
                          ),
                        }}
                        value={item?.categoryDiscountAmount}
                        onChange={(e) => {
                          item.categoryDiscountAmount = e.target.value;
                          setState((prev) => ({ ...prev }));
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={2}>
                          <Typography className="upper_box_headingTitle">
                            {IndianCurrency(
                              Number(item?.generalFeeAmount) -
                                Number(item?.categoryDiscountAmount)
                            )}
                          </Typography>
                        </Grid> */}

                    <Grid item xs={2}>
                      <Typography className="upper_box_headingTitle">
                        {IndianCurrency(
                          Number(item.generalFeeAmount) -
                            Number(item.categoryDiscountAmount) +
                            calulatePercentage(
                              Number(item.generalFeeAmount) -
                                Number(item.categoryDiscountAmount),
                              item.taxRate
                            )
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        className="upper_box_headingTitle"
                        sx={{
                          fontWeight: 600,
                          color:
                            item?.status === "APPROVED"
                              ? "#00A907"
                              : item?.status === "REJECTED"
                              ? "#F10F0F"
                              : item?.status === "Not Created"
                              ? "#2789FD"
                              : "#F6A70D",
                        }}
                      >
                        {item?.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <IconButton
                          onClick={() => handleAddEditFeeStructure(item)}
                          disabled={item.categoryDiscountAmount ? false : true}
                        >
                          <Check
                            color="success"
                            sx={{
                              opacity: item.categoryDiscountAmount ? 1 : 0.5,
                            }}
                          />
                        </IconButton>
                        {item?.edit && (
                          <Close
                            sx={{ cursor: "pointer" }}
                            color="error"
                            onClick={() => {
                              item.edit = false;
                              setState((prev) => ({ ...prev }));
                            }}
                          />
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={1.2}>
                      <Typography className="upper_box_headingTitle">
                        0
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={1.5}
                    display={"flex"}
                    alignItems={"center"}
                    columns={15.2}
                    key={index}
                    sx={{ padding: "10px" }}
                  >
                    <Grid item xs={1}>
                      <Typography className="upper_box_headingTitle">
                        {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {!item?.feeStructureGroupDocId ? (
                        <Typography
                          className="upper_box_headingTitle"
                          sx={{
                            color: "red",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              linkDialogData: item,
                              linkingDialog: true,
                            }))
                          }
                        >
                          {item?.feeRelaxationCategory}
                        </Typography>
                      ) : (
                        <Typography className="upper_box_headingTitle">
                          {item?.feeRelaxationCategory}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="upper_box_headingTitle">
                        {IndianCurrency(item.generalFeeAmount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="upper_box_headingTitle">
                        {IndianCurrency(item?.categoryDiscountAmount)}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={2}> 
                          <Typography className="upper_box_headingTitle">
                            {IndianCurrency(
                              Number(item?.generalFeeAmount) -
                                Number(item?.categoryDiscountAmount)
                            )}
                          </Typography>
                        </Grid> */}

                    <Grid item xs={2}>
                      <Typography className="upper_box_headingTitle">
                        {IndianCurrency(item?.feeAmount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        className="upper_box_headingTitle"
                        sx={{
                          color:
                            item?.status === "APPROVED"
                              ? "#00A907"
                              : item?.status === "REJECTED"
                              ? "#F10F0F"
                              : "#F6A70D",
                          fontWeight: 600,
                        }}
                      >
                        {item?.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="upper_box_headingTitle">
                        {item?.status === "APPROVED" && (
                          <Stack direction="row" alignItems={"center"} gap={1}>
                            <Delete
                              titleAccess="Delete"
                              color="error"
                              onClick={
                                () => handleOpenDeleteDialog(item?._id)
                                // deleteFeeStructure2Mutate(item?._id)
                              }
                              sx={{ cursor: "pointer" }}
                            />
                          </Stack>
                        )}
                        {item?.status === "REQUESTED" && (
                          <Stack direction="row" alignItems={"center"} gap={1}>
                            <PencilSquare
                              title="Edit"
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() => {
                                item.edit = true;
                                setState((prev) => ({ ...prev }));
                              }}
                            />
                            <Check
                              titleAccess="Approve"
                              color="success"
                              sx={{ cursor: "pointer" }}
                              onClick={
                                () => handleOpenArroveDialog(item?._id)
                                // approveFeeStructureMutate(item?._id)
                              }
                            />
                            <Close
                              titleAccess="Reject"
                              color="error"
                              sx={{ cursor: "pointer" }}
                              onClick={
                                () => handleOpenRejectDialog(item?._id)
                                // rejectFeeStructureMutate(item?._id)
                              }
                            />
                            <Delete
                              titleAccess="Delete"
                              color="error"
                              onClick={
                                () => handleOpenDeleteDialog(item?._id)
                                // deleteFeeStructure2Mutate(item?._id)
                              }
                              sx={{ cursor: "pointer" }}
                            />
                          </Stack>
                        )}
                        {item?.status === "REJECTED" && (
                          <Stack direction="row" alignItems={"center"} gap={1}>
                            <PencilSquare
                              title="Edit"
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() => {
                                item.edit = true;
                                setState((prev) => ({ ...prev }));
                              }}
                            />

                            <Delete
                              titleAccess="Delete"
                              color="error"
                              sx={{ cursor: "pointer" }}
                              onClick={
                                () => handleOpenDeleteDialog(item?._id)
                                // deleteFeeStructure2Mutate(item?._id)
                              }
                            />
                          </Stack>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={1.2}>
                      <Typography className="upper_box_headingTitle">
                        0
                      </Typography>
                    </Grid>
                  </Grid>
                )
              )}
            </FeeRelaxationWrapper>
          </>
        )
      )}
      {/* </DialogContent> */}
      {/* </Dialog> */}
    </>
  );
};

export default ViewGroupedFeeStructureAcademic;
const FeeRelaxationWrapper = styled(Box)`
  border-radius: 15px;
  border: 1px solid #b6c8d6;
  margin-top: 10px;
  .upper_box_wrap {
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid #b6c8d6;
    background: #f6f8fc;
    padding: 10px;
  }
  .upper_box_headingTitle {
    font-size: 14px;
    font-weight: 500;
  }
  .upper_box_addmore {
    color: #2789fd;
    cursor: pointer;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    margin: 10px;
    display: inline;
  }
`;
const AddNewButton = styled(Typography)`
  color: #2789fd;
  cursor: pointer;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  margin: 10px;
  display: inline-block;
`;
const FeeTypeWrapper = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.05);
  padding: 10px 15px 0 15px;
`;
const FeeTypeText = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 10px;
`;
const ViewDialogStudentWrapper = styled(Box)`
  border-radius: 10px;
  border-bottom: 1px solid #b6c8d6;
  background: #f6f8fc;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const StudentLabel = styled(Typography)`
  color: #0c2f49;
  font-size: 14px;
  font-weight: 400;
`;
const StudentText = styled(Typography)`
  color: #0c2f49;
  font-size: 16px;
  font-weight: 600;
`;
