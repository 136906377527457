export const DashboardYouTubeLink = [
  {
    link: "https://www.youtube.com/embed/7dV4EjRrAH8?si=DoeQcbjhSFCLSxpj",
    title: "Student Card",
    description:
      "In this video, you will see how the student card displays the total number of students, old and new students,  Additionally, you can directly admit students from this section.",
  },
  {
    link: "https://www.youtube.com/embed/aucqBZHBzSY?si=cmBEzyS9hhRutkDh",
    title: "Staff Card",
    description:
      "In this video, you can see the total number of staff members in your school.",
  },
  {
    link: "https://www.youtube.com/embed/CLRmAxJdpOo?si=4rOf9qRGkYvCQH34",
    title: "Fee Collection Details",
    description:
      "In this video, you can view the total fee details of your school and send reminders for pending dues.",
  },
  {
    link: "https://www.youtube.com/embed/pfdZsn4yOcU?si=_hP7xRBZUbK9tv5F",
    title: "Pending Approvals",
    description: "In this video you can see request for pending approval",
  },
  {
    link: "https://www.youtube.com/embed/Cn-cG5onXPE?si=KstoJAo144zO9Xdj",
    title: "Day Book",
    description:
      "In this video you can see the total collection & expense & its balance amount with deferent types",
  },
  {
    link: "https://www.youtube.com/embed/bSVvdJtjOJA?si=jQrCk5Q53kXLh3-u",
    title: "Birthday Event Card",
    description:
      "In this video you can see or wish to student & staff birthday events",
  },
  {
    link: "https://www.youtube.com/embed/YIStQbc6sVE?si=hj_kyiMDOJy7fP90",
    title: "Student Attendance Card",
    description:
      "In this video you can see student attendance with deferent classes & send notification to parent with text SMS",
  },
  {
    link: "https://www.youtube.com/embed/PepTJGQdeyg?si=ksg2OmGO_RSko0wt",
    title: "Notice Board",
    description:
      "In this video you can see/Add notice for whole school or another",
  },
  {
    link: "https://www.youtube.com/embed/4nePMckXr7E?si=nCLQXMYk4X0W0V55 ",
    title: "Staff Attendance Card",
    description:
      "In this video you can see staff attendance with chart/graph on dashboard",
  },
  {
    link: "https://www.youtube.com/embed/A3puFxYawnM?si=IOp6cYpDyn49Z0QC",
    title: "Collection vs Expense Graph",
    description:
      "In this video you can see session wise all collection & expense graph on dashboard ",
  },
  {
    link: "https://www.youtube.com/embed/uoL4jKt3gZM?si=casdTPGbbzHm1_0U",
    title: "Academic Calendar",
    description: "In this video you can see / Add school events",
  },
];

export const StudentManagementStudentLinks = [
  {
    link: "https://www.youtube.com/embed/s-VGydcoB-Q?si=uI4aKzDxoXAIu9LL",
    title: "How to add new Student",
    description: "In this video you can add new student in school",
  },
];
export const StudentManagementAdmissionApprovalinks = [
  {
    link: "https://www.youtube.com/embed/9zUwdfbickA?si=VknWfPa7qv1DnlE-",
    title: "Admission Approval",
    description: "In this video you can see/add student with external request ",
  },
];
export const StudentManagementStudentProfilelinks = [
  {
    link: "https://www.youtube.com/embed/ez-gp4MAWWY?si=W1vglXEffe7lTjID",
    title: "Student Profile Detail",
    description:
      "In this video you can see/update student details like personal, parent, address, fees, admission, report card, documents etc.",
  },
];
export const StudentManagementStudentReportlinks = [
  {
    link: "https://www.youtube.com/embed/Wff5V6U5dxM?si=krfGvULleG60SWXH",
    title: "Student report Filter",
    description:
      "In this video you can see deferent type of filter & apply on students",
  },
  {
    link: "https://www.youtube.com/embed/0W3OXK6UP-4?si=VJfXrqqBNUZ9-Fuf",
    title: "Student report table data",
    description:
      "In this video you can see/update student admission details like Suspention, Promotion, Migration, Deletion, Activation",
  },
  {
    link: "https://www.youtube.com/embed/62a4b8W7aGU?si=Wq3jLqlC0OPEIKoX",
    title: "Student Report Summary",
    description:
      "In this video you can see the tabular view for student counting in deferent class & sections",
  },
  {
    link: "https://www.youtube.com/embed/cBAR8HApj0A?si=BJQ1XdOVKp22wOg6",
    title: "Student Report App download",
    description:
      "In this video you can see the student list for parent application downloaded or not",
  },
  {
    link: "https://www.youtube.com/embed/sUrUC3IF_bQ?si=6Hb2vTlEL4f_W9vi",
    title: "Student Report Deleted Student",
    description: "In this Video you can see the list of deleted student",
  },
];

export const StudentManagementBulkEditlinks = [
  {
    link: "https://www.youtube.com/embed/x9li-63Sqdg?si=T6WvhAWjLfNMuwiN",
    title: "Student Roll Number Bulk Edit",
    description:
      "In this video you can see / update student roll number in bulk with one by one class",
  },
];
export const StudentManagementPromotionMigrationlinks = [
  {
    link: "https://www.youtube.com/embed/_KZsXVapFOM?si=vfPXwoX7DxCCKPch",
    title: "Promotion & Migration Filter details",
    description:
      "In this video you can see the table data for promotion or migration with deferent filter",
  },
  {
    link: "https://www.youtube.com/embed/FqzZZD7Uz3g?si=IJ2kLO3qOjoFFPjz",
    title: "Student Promotion in next Session",
    description:
      "In this video you can see / update student promotion in next session",
  },
];
export const StudentManagementTransferCertificatelinks = [
  {
    link: "https://www.youtube.com/embed/UGb_HAV3PdE?si=0y6uF9kW01ImhgX-",
    title: "Student Transfer Certificates(TC)",
    description:
      "In this video you can see / update , Print or delete student transfer certificate ",
  },
];
export const StudentManagementMiscCertificatelinks = [
  {
    link: "https://www.youtube.com/embed/D5tN1WxMh3M?si=Tx0-ZJpRgEE-tiJW ",
    title: "Student Misc Certificates ",
    description:
      "In this video you can see & print student all type of Misc Certificates",
  },
];

export const FeeStructureLinks = [
  {
    link: "https://www.youtube.com/embed/kQu9O2I0KLY?si=c-SmWuZAkllGOv_5",
    title: "Deferent type fee Relaxation Category add/view",
    description:
      "In this video you can see & add deferent type of fee relaxation category",
  },
  {
    link: "https://www.youtube.com/embed/ZvegQABws_Y?si=ZmqJtQb9sU5fRyT7",
    title: "Deferent type of fee type add",
    description:
      "In this video you can Add deferent type of Fee type in school",
  },
  {
    link: "https://www.youtube.com/embed/tcKSa6l3sKM?si=7cghgGAL6LySBaRq",
    title: "Deferent type of fee type view",
    description:
      "In this video you can View deferent type of Fee type in school",
  },
  {
    link: "https://www.youtube.com/embed/wwLNeIRvNvQ?si=IvwiwhX8ggkVRt_S",
    title: "Fee Structure Summarized View",
    description:
      "In this video you can see the fee structure data in summarized view",
  },
  {
    link: "https://www.youtube.com/embed/zwFBRYCtiLs?si=N8Yyec6whyz8eGek",
    title: "Add General Fee Structure without Fine",
    description:
      "In this video you can add new general fee structure without fine fees amount",
  },
  {
    link: "https://www.youtube.com/embed/Hj0XowBSZ7U?si=IV0ndF11MRyeUmMz",
    title: "Add Optional Fee Structure without Fine",
    description:
      "In this video you can add new optional fee structure without fine fees amount",
  },
  {
    link: "https://www.youtube.com/embed/Jksn1gtAlRw?si=c_ibCwwRFkBL5wHk",
    title: "Fee Structure Individual View",
    description:
      "In this video you can see the fee structure data individually & approve requested structure & apply on students",
  },
  {
    link: "https://www.youtube.com/embed/gGassCetMDA?si=54NbLHvjgsBz7iUg",
    title: "Fee Structure Grouped View",
    description:
      "In this video you can see the fee structure data in grouped view",
  },
];

export const FeeCollectionLinks = [
  {
    link: "https://www.youtube.com/embed/kHw3e_npNqA?si=Bd0DQkG-9d1fwSsF",
    title: "Academic Fee Collection",
    description:
      "In this video you can collect student dues fee with deferent filter ",
  },
];

export const DuesListLinks = [
  {
    link: "https://www.youtube.com/embed/pLCM4wp2S-4?si=m7Wbj5yiTB4wYj5_ ",
    title: "Academic Fee Due List",
    description: "In this video you can see dues list with deferent filter ",
  },
  {
    link: "https://www.youtube.com/embed/yx6JKr8qgxk?si=l_Vf-YotDnXPizMa",
    title: "Academic Fee Due List Summary",
    description:
      "In this video you can see deues list summary class wise with deferent filter",
  },
  {
    link: "https://www.youtube.com/embed/lts5CCldAr4?si=_l9pKD9nr8i-Lkxk ",
    title: "Academic Fee Due Slip ",
    description: "In this video you can see how to view & print dues slip",
  },
];
export const DiscountLinks = [
  {
    link: "https://www.youtube.com/embed/CFlIcduDD-Q?si=Ht2CYKKRBv44Wzly",
    title: "Academic Discount Fee Details",
    description:
      "In this video you can see all type of academic discount given for students",
  },
];

export const ArrearLinks = [
  {
    link: "https://www.youtube.com/embed/37-nnVFF9kY?si=mmnBSEfpydzYC2DT",
    title: "Add new arrears fees",
    description: "In this video you can see how to add arrears amount ",
  },
  {
    link: "https://www.youtube.com/embed/J0PCA3L1IBk?si=XQbHOyYez5yH4IeQ",
    title: "View Arrears Summary",
    description:
      "In this video you can see the arrears amount summary with arrears invoices",
  },
];
