import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;
let school = `${SCHOOL_BASE}/school`;

export const getClassDropdown = (selectedSchoolId, params) => {
  return schoolTokenAxios.get(
    `${school}/getClassDropdown/${selectedSchoolId}`,
    {
      params: params,
    }
  );
};
