import React from "react";
import styled from "styled-components";
import { Grid, Typography, Box, Stack, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import dayjs from "dayjs";
import { IndianCurrency, NumberInWord } from "app/utils/helper";

const InvoiceTemplate1 = ({
  invoiceText,
  data,
  invoiceItems,
  calculateGrandTotal,
  calculateTotalDiscount,
  calculateTotalFine,
  calculateTotalBalance,
}) => {
  const selectedSchool = useSelector(selectSelectedSchool);

  return (
    <LeftSideDiv>
      <InvoiceHeader>
        <div
          style={{
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={2}>
              <img
                src={selectedSchool?.schoolLogo}
                style={{
                  height: "60px",
                  width: "60px",
                  objectFit: "contain",
                  marginLeft: "5px",
                }}
                alt="school-logo"
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {selectedSchool?.schoolName}
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {`${selectedSchool?.schoolAddress?.address}, ${selectedSchool?.schoolAddress?.dist}, ${selectedSchool?.schoolAddress?.state}, ${selectedSchool?.schoolAddress?.pinCode}`}
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {selectedSchool?.schoolContactNumber}, {selectedSchool?.email}
              </Typography>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
              textTransform: "capitalize",
              textAlign: "center",
              color: "#FFFFFF",
              backgroundColor: "#0C4B7E",
            }}
          >
            {invoiceText} - {`${data?.session}`}
          </div>
        </div>
      </InvoiceHeader>
      <InvoiceBody>
        <Grid container sx={{ px: 1, pt: 0.5 }}>
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
              Invoice No.
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
              {data?.invoiceNumber}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
              Collection Date
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
              {dayjs(data?.collectionDate).format("DD MMM, YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
              Depositor Name
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
              {data?.depositorName}
            </Typography>
          </Grid>
          {/* student detail */}
          <StudentDetailBox>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <img
                  src={
                    data?.studentDetail?.profilePic ?? "/image-placeholder.jpg"
                  }
                  style={{
                    height: "60px",
                    width: "60px",
                    objectFit: "contain",
                    marginLeft: "5px",
                  }}
                  alt="student-pic"
                />
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                      Name :
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
                      {data?.studentDetail?.studentName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                      Reg No :
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
                      {data?.studentDetail?.registrationNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                      Adm No :
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
                      {data?.studentDetail?.admissionNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                      Class-Str-Sec :
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
                      {data?.studentDetail?.className_stream_sec}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                      Father's Name :
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
                      {data?.studentDetail?.fatherName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                      Mobile Number :
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
                      {data?.studentDetail?.mobileNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                      Vechicle No :
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
                      {data?.transportInfo?.vehicleNumber ?? "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                      Stop Name :
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "10px" }}>
                      {data?.transportInfo?.to ?? "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StudentDetailBox>
        </Grid>

        <Box sx={{ background: "#ffaf0d", py: 1, px: 1 }}>
          <Grid container>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Fee Type
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Paying
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {invoiceItems?.map((invoice, i) => (
          <Box key={i}>
            <Grid container sx={{ px: 1 }}>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  {invoice?.feeType}
                </Typography>
                <Stack direction={"row"} gap={0.5}>
                  <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                    {[...new Set(invoice?.months)]?.join(", ")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  {IndianCurrency(invoice?.totalAmount)}
                </Typography>
              </Grid>
            </Grid>
            {invoiceItems?.length !== i + 1 && (
              <Divider
                sx={{ background: (theme) => theme.palette.primary.main }}
              />
            )}
          </Box>
        ))}
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={9}>
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
              Grand Total
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
              {IndianCurrency(calculateGrandTotal())}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={4}>
            {calculateTotalDiscount() !== 0 && (
              <>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Total Discount
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                  {IndianCurrency(calculateTotalDiscount())}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
              Total Fine
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
              {IndianCurrency(calculateTotalFine())}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
              Total Balance
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
              {IndianCurrency(calculateTotalBalance())}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={9}>
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
              Paid Amount :{" "}
              {NumberInWord(calculateGrandTotal() + calculateTotalFine())}{" "}
              Rupees Only
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
              {IndianCurrency(
                Number(calculateGrandTotal()) + Number(calculateTotalFine())
              )}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />

        <Grid container sx={{ px: 1 }}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
              Payment Mode :{" "}
              {data?.paymentDetails
                ? Object.keys(data?.paymentDetails)[0]
                : "--"}
            </Typography>
          </Grid>
          {data?.paymentDetails?.UpiMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  UPI Number: {data?.paymentDetails?.UpiMode?.upiNumber}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Transaction ID: {data?.paymentDetails?.UpiMode?.transactionId}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.UpiMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data?.paymentDetails?.CardMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Card Holder Name:{" "}
                  {data?.paymentDetails?.CardMode?.cardHolderName}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Card Number: {data?.paymentDetails?.CardMode?.cardNumber}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.CardMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data?.paymentDetails?.ChequeMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Cheque No.: {data?.paymentDetails?.ChequeMode?.chequeNumber}
                </Typography>

                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.ChequeMode?.amount}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Deposition Date:{" "}
                  {dayjs(
                    data?.paymentDetails?.ChequeMode?.depositionDate
                  ).format("DD MMM YYYY")}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data?.paymentDetails?.NEFTMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Reference No.:{" "}
                  {data?.paymentDetails?.NEFTMode?.referenceNumber}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.NEFTMode?.amount}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Transaction No.:{" "}
                  {data?.paymentDetails?.NEFTMode?.transactionNumber}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data?.paymentDetails?.CashMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.CashMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>

        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
          <Box>
            <Typography sx={{ fontSize: "12px" }}>
              Created By : {data?.createdBy?.personName}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              Created On : {dayjs(data?.createdOn).format("DD MMM YYYY h:mm A")}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px" }}>For,</Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {data?.schoolName}
            </Typography>
            <Typography sx={{ fontSize: "12px", pt: 2, pb: 1 }}>
              Authorised Sign.
            </Typography>
          </Box>
        </Stack>
      </InvoiceBody>
    </LeftSideDiv>
  );
};

export default InvoiceTemplate1;

const LeftSideDiv = styled.div`
  margin: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: #fff;
`;

const InvoiceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InvoiceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const StudentDetailBox = styled.div`
  border-radius: 8px;
  border: 1px solid #d0d0e2;
  background: rgba(208, 208, 226, 0.08);
  padding: 10px;
  width: 100%;
  margin: 10px 0;
`;
