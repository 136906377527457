import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import CustomMenu from "app/components/CustomMenu";
import { ChevronDown } from "react-bootstrap-icons";
import CustomToggle from "app/components/CustomDropdownToggle";

import styled from "styled-components";
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { CopyAll, Email, SupportAgent, WhatsApp } from "@mui/icons-material";
import { toast } from "react-toastify";

const Session = ({ handleSessionSelection, selectedSession, sessions }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <StyledWrapper className="d-flex text-color">
      <Dropdown
        className={isMobile ? "mobile-session-container" : "session-container"}
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
          size="sm"
        >
          <div
            className={isMobile ? "fs-6 text-color" : "text-color"}
            style={{ marginBottom: "-10px", marginTop: "7px" }}
          >
            Session: {selectedSession} <ChevronDown />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} className="w-100 p-3 text-color">
          {sessions?.map((session) => (
            <Dropdown.Item
              key={session}
              onClick={() => handleSessionSelection(session)}
              active={session === selectedSession}
              eventKey={session}
              className="p-3 w-100"
            >
              {session}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledWrapper>
  );
};

export const Medium = ({
  handleMediumSelection,
  mediumList,
  selectedMedium,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <StyledWrapper className="d-flex text-color">
      <Dropdown
        className={isMobile ? "mobile-session-container" : "session-container"}
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
          size="sm"
        >
          <>
            <div
              className={isMobile ? " fs-6 text-color" : "text-color"}
              style={{ marginBottom: "-10px", marginTop: "7px" }}
            >
              Medium-{selectedMedium} <ChevronDown />
            </div>
          </>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} className="w-100 p-3 text-color">
          {mediumList?.map((medium) => (
            <Dropdown.Item
              key={medium}
              onClick={() => handleMediumSelection(medium)}
              active={medium === selectedMedium}
              eventKey={medium}
              className="p-3 w-100"
            >
              {medium}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledWrapper>
  );
};

export const SupportContainer = () => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          border: "1px solid #b6c8d6",
          borderRadius: "8px",
        }}
      >
        <SupportAgent
          sx={{
            color: "#084c84",
            fontSize: "2rem",
          }}
        />
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Stack direction={"column"}>
            <Typography
              sx={{
                color: "#084c84",
                p: 0,
              }}
            >
              08069328986{" "}
            </Typography>
            <Typography
              sx={{
                color: "green",
                p: 0,
              }}
            >
              support@ekalsutra.com{" "}
            </Typography>
          </Stack>
          {/* icons */}
          <Stack direction={"column"}>
            <IconButton
              sx={{
                color: "#084c84",
                p: 0,
                alignItems: "center",
              }}
              onClick={() => {
                navigator.clipboard.writeText("08069328986");
                toast.success("Copied to clipboard");
              }}
            >
              <CopyAll fontSize="small" />
            </IconButton>
            <IconButton
              sx={{
                color: "#084c84",
                p: 0,
                alignItems: "center",
              }}
              onClick={() => {
                // window.open("mailto:support@ekalsutra.com", "_blank");
                navigator.clipboard.writeText("support@ekalsutra.com");
                toast.success("Copied to clipboard");
              }}
            >
              <Email fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const StyledWrapper = styled.div`
  .text-color {
    color: #084c84;
    font-size: 1rem;
  }

  .mobile-session-container {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 8px;
    font-weight: 200;
    padding: 0 5px 0 5px;
    font-size: 5px;
    align-items: center;
  }

  .session-container {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 8px;
    font-weight: 200;
    padding: 0 10px;
    margin: 0 5px;
    font-size: 5px;
    align-items: center;
  }
`;

export default Session;
