import { useEffect } from "react";
import { Delete } from "@mui/icons-material";
import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Field, FieldArray, useField } from "formik";
import styled from "styled-components";
import TextfieldComponent from "./TextfieldComponent";
import { Input } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { getSubjectByClassDocIdSection } from "app/services/management";
import { getClassDropdown } from "app/services/student-management";
import ClassSelectComponent from "./ClassSelectComponent";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Box } from "@mui/material";
import {
  selectedMediumSelector,
  selectActiveSession,
} from "app/modules/schools/selectors";
import { selectSelectedSchoolId } from "app/modules/schools/selectors";
import { getAllClassDropdowns } from "app/services/management";
import { CircularProgress, FormControl } from "@mui/material";

import { useState } from "react";

import SectionSelectComponent from "./SectionSelectComponent";
import SubjectSelectComponent from "./SubjectSelectComponent";
import useFolderWiseFileUploader from "app/hooks/upload/useFolderWiseFileUploader";
export default function FieldArrayComponent({
  name,
  mainFormik,
  ...otherProps
}) {
  const [open, setOpen] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [isLoading, setLoading] = useState(-1);
  const [streamValue, setStreamValue] = useState("");
  const selectedSession = useSelector(selectActiveSession);
  const [formikErrors, setFormikErrors] = useState(mainFormik?.errors);
  const [classOptions, setClassOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [disableBtn, setDisablebtn] = useState(true);
  const [subjectCode, setSubjectCode] = useState();
  const [selectedIndex, setIndex] = useState(0);
  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const [section, setSection] = useState("");
  const [field, metaData] = useField(name);
  const fieldArrayConfig = {
    ...field,
  };
  const [selected, setSelected] = useState("");

  const selectionChangeHandler = (event) => {
    setSelected(event.target.value);
  };

  const {
    isLoading: loadingSubjects,
    isError: subjectsError,
    isSuccess: subjectsLoaded,
    data: subjectsResponse,
  } = useQuery({
    queryKey: ["getSubjectByClassDocIdSection", streamValue, section],
    queryFn: () => getSubjectByClassDocIdSection(streamValue, section),
    enabled: streamValue.length > 0 && section.length > 0,
  });

  const subjects = subjectsResponse?.data?.list;

  useEffect(() => {
    const fetchClassData = async () => {
      const res = await getAllClassDropdowns(
        selectedSchoolId,
        selectedSession,
        selectedMedium
      );
      const data = res.data;
      setClassOptions(data?.list);
    };

    fetchClassData();
  }, [selectedSession, selectedMedium]);

  useEffect(() => {
    const sectionArray = classOptions.filter((item) => {
      return item.classDocId === streamValue;
    });

    setSectionOptions(sectionArray[0]?.sections);
    if (streamValue.length > 0) setDisablebtn(false);
  }, [streamValue, classOptions]);

  const {
    isLoading: loadingclasses,
    isError: errorclasses,
    isSuccess: loadedclasses,
    data: classesResponse,
  } = useQuery({
    queryKey: ["getClassDropdown", selectedSchoolId, selectedMedium],
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
  });

  useEffect(() => {
    if (mainFormik) {
      setFormikErrors(mainFormik.errors);
    }
  }, []);
  const { handleUploadImage } = useFolderWiseFileUploader();
  return (
    <FieldArray
      name={name}
      {...fieldArrayConfig}
      render={(fieldArrayProps) => {
        const { form, push, remove } = fieldArrayProps;
        const { values } = form;
        const {
          feeDetails,
          stopList,
          educationInfo,
          experiences,
          assignedFor,
          experiencesAddNew,
          educationInfoAddNew,
        } = values;

        if (assignedFor) {
          assignedFor[selectedIndex].subjectCode = subjectCode;
        }
        const handleUploadFile = async (index) => {
          if (attachmentFile) {
            setLoading(index);
            let attachmentUrl = await handleUploadImage(
              true,
              attachmentFile,
              "withSession",
              "feeManagement",
              "fee"
            );
            if (experiences) {
              experiences[index].experienceCertificate = attachmentUrl.fileURL;
            } else {
              experiencesAddNew[index].experienceCertificate =
                attachmentUrl.fileURL;
            }
            setLoading(-1);
          }
        };

        const handleExperienceFileUpload = (index, e) => {
          setAttachmentFile(e.target.files[0]);
          handleUploadFile(index);
        };

        return (
          <div>
            <Dialog
              sx={{ height: "auto" }}
              open={open}
              onClose={() => setOpen(false)}
            >
              <DialogTitle>
                <h3>Upload Attachments</h3>
              </DialogTitle>
              <DialogContent>
                <Input
                  className="m-2"
                  type="file"
                  onChange={(e) => setAttachmentFile(e.target.files[0])}
                ></Input>
                <ButtonWrapper>
                  <UploadBtn
                    className="mt-5 "
                    onClick={() => {
                      handleUploadFile();
                    }}
                  >
                    Upload
                  </UploadBtn>
                </ButtonWrapper>
              </DialogContent>
            </Dialog>
            {/* <table
              style={{
                width: "100%",
              }}
            >
              <TableHeadRow>
                <th>Sr No</th>
                <TableHeadCell>Fee Name</TableHeadCell>
                <TableHeadCell>Description</TableHeadCell>
                <TableHeadCell>Amount</TableHeadCell>
              </TableHeadRow>
            </table> */}
            <div>
              {feeDetails?.map((obj, index) => (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  gap={1}
                  sx={{ py: 1 }}
                >
                  <label style={{ marginTop: "8px", fontWeight: 600 }}>
                    {index + 1}
                  </label>

                  <TextfieldComponent
                    name={`feeDetails[${index}].feeCategory`}
                    label="Fee Name"
                  />

                  <TextfieldComponent
                    name={`feeDetails[${index}].description`}
                    label="Description"
                  />

                  <TextfieldComponent
                    name={`feeDetails[${index}].amount`}
                    label="Amount"
                  />
                  <Box>
                    <IconButton onClick={() => remove(index)}>
                      <Delete color="error" />
                    </IconButton>
                  </Box>
                </Stack>
              ))}
              {feeDetails ? (
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() =>
                    push({
                      feeCategory: "",
                      description: "",
                      amount: "",
                    })
                  }
                >
                  + Add More Fee
                </Button>
              ) : (
                ""
              )}
            </div>
            <div>
              {stopList?.map((obj, index) => (
                <tr
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <BodyWrapper>
                    <label>{index + 1}</label>

                    <TextfieldComponent
                      name={`stopList[${index}].distance`}
                      label="Distance"
                    />

                    {/* <InputLabel>Hour</InputLabel>
                    <Select value={selected} onChange={selectionChangeHandler}>
                      {Hour.map((selected, index) => (
                        <option key={index} value={selected}>
                          {selected}
                        </option>
                      ))}
                    </Select> */}

                    <TextfieldComponent
                      name={`stopList[${index}].duration`}
                      label="Duration"
                    />

                    <TextfieldComponent
                      name={`stopList[${index}].stopName`}
                      label="Stop Name"
                    />

                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      variant="outlined"
                      color="error"
                      style={{ height: "50px" }}
                    >
                      <Delete />
                    </Button>
                  </BodyWrapper>
                </tr>
              ))}
              {stopList ? (
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() =>
                    push({
                      distance: "",
                      duration: "",
                      stopName: "",
                    })
                  }
                >
                  + Add Stop list
                </Button>
              ) : (
                ""
              )}
            </div>
            <div>
              {educationInfo?.map((obj, index) => (
                <tr
                  key={index}
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    margin: "0",
                  }}
                >
                  <BodyWrapper>
                    <label>{index + 1}</label>
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row m-3">
                        <TextfieldComponent
                          name={`educationInfo[${index}].className`}
                          label="Class Name"
                          sx={{ margin: "5px" }}
                        />

                        <TextfieldComponent
                          name={`educationInfo[${index}].instituteName`}
                          label="University/Grade"
                          sx={{ margin: "5px" }}
                        />
                      </div>
                      <div className="d-flex flex-row m-3">
                        <TextfieldComponent
                          name={`educationInfo[${index}].percentage`}
                          label="Percentage/Grade"
                          sx={{ margin: "5px" }}
                        />
                        <TextfieldComponent
                          name={`educationInfo[${index}].passingYear`}
                          label="Passing Year"
                          sx={{ margin: "5px" }}
                        />
                      </div>
                    </div>
                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      variant="outlined"
                      color="error"
                      style={{ height: "50px" }}
                    >
                      <Delete />
                    </Button>
                  </BodyWrapper>
                </tr>
              ))}
              {educationInfo ? (
                <Button
                  type="button"
                  variant="outlined"
                  sx={{ marginLeft: "10px", marginBottom: "10px" }}
                  onClick={() =>
                    push({
                      className: "",
                      instituteName: "",
                      percentage: "",
                      passingYear: "",
                    })
                  }
                >
                  +
                </Button>
              ) : (
                ""
              )}
            </div>
            <div>
              {experiences?.map((obj, index) => (
                <tr
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    margin: "0",
                  }}
                >
                  <BodyWrapper>
                    <label>{index + 1}</label>
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row m-3">
                        <TextfieldComponent
                          name={`experiences[${index}].employerName`}
                          label="Employer's Name"
                          sx={{ margin: "5px" }}
                        />
                        <TextfieldComponent
                          name={`experiences[${index}].designation`}
                          label="Designation"
                          sx={{ margin: "5px" }}
                        />
                      </div>
                      <div className="d-flex flex-row m-3">
                        <TextfieldComponent
                          name={`experiences[${index}].from`}
                          label="From"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                          sx={{ margin: "5px" }}
                        />
                        <TextfieldComponent
                          name={`experiences[${index}].to`}
                          label="To"
                          sx={{ margin: "5px" }}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                        />
                      </div>
                      <div className="d-flex flex-row m-3">
                        <TextfieldComponent
                          name={`experiences[${index}].salary`}
                          type="number"
                          label="Salary"
                          sx={{ margin: "5px" }}
                        />
                        {/* <TextfieldComponent
                          name={`experiences[${index}].experienceCertificate`}
                          label="Attactment"
                          sx={{ margin: "5px" }}
                        /> */}
                        <TextfieldComponent
                          name={`experiences[${index}].worksOn`}
                          label="Worked On"
                          sx={{ margin: "5px" }}
                        />
                      </div>
                      <div className="d-flex flex-row m-3">
                        <Input
                          className="m-2"
                          sx={{ width: "15rem" }}
                          type="file"
                          onChange={(e) => setAttachmentFile(e.target.files[0])}
                        ></Input>
                        <ButtonWrapper>
                          {isLoading == index ? (
                            <CircularProgress />
                          ) : (
                            <UploadBtn
                              className="mt-2"
                              type="button"
                              onClick={() => {
                                handleUploadFile(index);
                              }}
                            >
                              Upload
                            </UploadBtn>
                          )}
                        </ButtonWrapper>
                      </div>
                    </div>
                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      variant="outlined"
                      color="error"
                      style={{ height: "50px" }}
                    >
                      <Delete />
                    </Button>
                  </BodyWrapper>
                </tr>
              ))}
              {experiences ? (
                <Button
                  type="button"
                  variant="outlined"
                  sx={{ marginLeft: "10px", marginBottom: "10px" }}
                  onClick={() =>
                    push({
                      from: "",
                      to: "",
                      employerName: "",
                      designation: "",
                      worksOn: "",
                      experienceCertificate: "",
                      salary: 0,
                    })
                  }
                >
                  +
                </Button>
              ) : (
                ""
              )}
            </div>
            <div>
              {experiencesAddNew?.map((obj, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "flex-start",
                      margin: "0",
                    }}
                  >
                    <Grid container direction="column" justifyContent="center">
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <TextfieldComponent
                            name={`experiencesAddNew[${index}].employerName`}
                            label="Employer's Name"
                            error={
                              mainFormik?.errors?.experiences?.[index]
                                ?.employerName
                            }
                            sx={{ margin: "5px" }}
                            onChange={(e) => {
                              mainFormik.values.experiences[
                                index
                              ].employerName = e.target.value;
                              mainFormik.validateForm();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextfieldComponent
                            name={`experiencesAddNew[${index}].designation`}
                            label="Designation"
                            error={
                              mainFormik?.errors?.experiences?.[index]
                                ?.designation
                            }
                            sx={{ margin: "5px" }}
                            onChange={(e) => {
                              mainFormik.values.experiences[index].designation =
                                e.target.value;
                              mainFormik.validateForm();
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextfieldComponent
                            name={`experiencesAddNew[${index}].from`}
                            label="From"
                            error={
                              mainFormik?.errors?.experiences?.[index]?.from
                            }
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                            sx={{ margin: "5px" }}
                            onChange={(e) => {
                              mainFormik.values.experiences[index].from =
                                e.target.value;
                              mainFormik.validateForm();
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextfieldComponent
                            name={`experiencesAddNew[${index}].to`}
                            label="To"
                            error={mainFormik?.errors?.experiences?.[index]?.to}
                            sx={{ margin: "5px" }}
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                            onChange={(e) => {
                              mainFormik.values.experiences[index].to =
                                e.target.value;
                              mainFormik.validateForm();
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          Duration
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextfieldComponent
                            name={`experiencesAddNew[${index}].worksOn`}
                            label="Worked On"
                            error={
                              mainFormik?.errors?.experiences?.[index]?.worksOn
                            }
                            sx={{ margin: "5px" }}
                            onChange={(e) => {
                              mainFormik.values.experiences[index].worksOn =
                                e.target.value;
                              mainFormik.validateForm();
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextfieldComponent
                            name={`experiencesAddNew[${index}].salary`}
                            type="number"
                            error={
                              mainFormik?.errors?.experiences?.[index]?.salary
                            }
                            label="Salary"
                            sx={{ margin: "5px" }}
                            onChange={(e) => {
                              mainFormik.values.experiences[index].salary =
                                e.target.value;
                              mainFormik.validateForm();
                            }}
                          />
                        </Grid>
                        <Grid item xs direction="column" sx={{ margin: "5px" }}>
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#0C2F49",
                            }}
                          >
                            Experience Certificate
                          </Typography>
                          <div className="d-flex flex-row mt-2">
                            <Button variant="contained" component="label">
                              Upload
                              <input
                                hidden
                                multiple
                                type="file"
                                onChange={(e) =>
                                  handleExperienceFileUpload(index, e)
                                }
                              />
                            </Button>
                            <Typography sx={{ margin: "5px" }}>
                              {attachmentFile?.name}
                            </Typography>
                          </div>

                          {/* <Input className='m-2' sx={{ width: "15rem" }} type="file" onChange={(e) => setAttachmentFile(e.target.files[0])}></Input> */}
                          {/* {isLoading == index ? <CircularProgress /> :
                            <UploadBtn className='mt-2' type="button" onClick={() => {
                              handleUploadFile(index);
                            }}>Upload</UploadBtn>} */}
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="m-3">
                      <Button
                        type="button"
                        onClick={() => remove(index)}
                        variant="outlined"
                        color="error"
                        style={{ height: "50px" }}
                      >
                        <Delete />
                      </Button>
                    </div>
                  </tr>
                );
              })}
              {experiencesAddNew ? (
                <Button
                  type="button"
                  variant="outlined"
                  sx={{ marginLeft: "10px", marginBottom: "10px" }}
                  onClick={() =>
                    push({
                      from: "",
                      to: "",
                      employerName: "",
                      designation: "",
                      worksOn: "",
                      experienceCertificate: "",
                      salary: 0,
                    })
                  }
                >
                  + ADD MORE
                </Button>
              ) : (
                ""
              )}
            </div>
            <div>
              {educationInfoAddNew?.map((obj, index) => (
                <tr
                  key={index}
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    margin: "0",
                  }}
                >
                  <BodyWrapper>
                    <label>{index + 1}</label>
                    <div className="d-flex flex-">
                      <div className="d-flex flex-row m-3 ">
                        <TextfieldComponent
                          name={`educationInfoAddNew[${index}].className`}
                          label="Class Name"
                          sx={{ margin: "5px" }}
                        />

                        <TextfieldComponent
                          name={`educationInfoAddNew[${index}].instituteName`}
                          label="University/Grade"
                          sx={{ margin: "5px" }}
                        />

                        <TextfieldComponent
                          name={`educationInfoAddNew[${index}].percentage`}
                          label="Percentage/Grade"
                          sx={{ margin: "5px" }}
                        />
                        <TextfieldComponent
                          name={`educationInfoAddNew[${index}].passingYear`}
                          label="Passing Year"
                          sx={{ margin: "5px" }}
                        />
                      </div>
                    </div>
                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      variant="outlined"
                      color="error"
                      style={{ height: "50px" }}
                    >
                      <Delete />
                    </Button>
                  </BodyWrapper>
                </tr>
              ))}
              {educationInfoAddNew ? (
                <Button
                  type="button"
                  variant="outlined"
                  sx={{ marginLeft: "10px", marginBottom: "10px" }}
                  onClick={() =>
                    push({
                      className: "",
                      instituteName: "",
                      percentage: "",
                      passingYear: "",
                    })
                  }
                >
                  + ADD MORE
                </Button>
              ) : (
                ""
              )}
            </div>
            <div>
              {assignedFor?.map((obj, index) => {
                setIndex(index);
                return (
                  <tr
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      margin: "0",
                    }}
                  >
                    <BodyWrapper>
                      <label>{index + 1}</label>
                      <div className="d-flex flex-row">
                        <div className="d-flex flex-column">
                          <Box sx={{ width: "10vw", marginRight: "1rem" }}>
                            <FormControl fullWidth>
                              <ClassSelectComponent
                                name={`class_stream`}
                                label="Class Stream"
                                options={classOptions}
                                setStreamValue={setStreamValue}
                              />
                            </FormControl>
                          </Box>
                          <Box sx={{ width: "10vw", marginRight: "1rem" }}>
                            <FormControl fullWidth>
                              <SubjectSelectComponent
                                name={`assignedFor[${index}].subjectName`}
                                label="Subject"
                                options={subjects ? subjects : []}
                                setSubjectCode={setSubjectCode}
                              />
                            </FormControl>
                          </Box>
                        </div>
                        <div className="d-flex flex-column">
                          <Box sx={{ width: "10vw", marginRight: "1rem" }}>
                            <FormControl fullWidth>
                              <SectionSelectComponent
                                name={`section`}
                                label="Section"
                                options={sectionOptions}
                                setSection={setSection}
                              />
                            </FormControl>
                          </Box>
                        </div>
                      </div>
                      <Button
                        type="button"
                        onClick={() => remove(index)}
                        variant="outlined"
                        color="error"
                        style={{ height: "50px" }}
                      >
                        <Delete />
                      </Button>
                    </BodyWrapper>
                  </tr>
                );
              })}
              {assignedFor ? (
                <Button
                  type="button"
                  variant="outlined"
                  sx={{ marginLeft: "10px", marginBottom: "10px" }}
                  onClick={() =>
                    push({
                      subjectName: "",
                      subjectCode: "",
                    })
                  }
                >
                  +
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      }}
    />
  );
}

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: felx-start;
  padding: 10px;
  gap: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 10px;
`;

const UploadBtn = styled.button`
  height: 50px;
  width: 100%;
  background: white;
  margin: 5px;
  width: 10rem;
  border-radius: 5px;
  color: #2789fd;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  border: 2px solid #2789fd;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  &:hover {
    background-color: #2789fd;
    border: 2px solid #2789fd;
    color: white;
    transition: 100ms all ease-in-out;
  }
`;

// const TableHeadRow = styled.div`
//   width: 100%;
//   background-color: aqua;
// `;

// const TableHeadCell = styled.th`
//   /* width: 100%; */
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-start;
// `;
