import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let subject_url = `${SCHOOL_BASE}/subject`;

export const addNewAvailableSubject = (payload) => {
  return schoolTokenAxios.post(
    `${subject_url}/addNewAvailableSubject`,
    payload
  );
};
export const copySubject = (payload) => {
  return schoolTokenAxios.put(`${subject_url}/copySubject`, payload);
};
export const upsertSubjectClassMappingRule = (payload) => {
  return schoolTokenAxios.post(
    `${subject_url}/upsertSubjectClassMappingRule`,
    payload
  );
};

export const getAvailbaleSubjects = (params) => {
  return schoolTokenAxios.get(`${subject_url}/getAvailbaleSubjects`, {
    params: params,
  });
};

export const getSubjectByMultipleClass = (params) => {
  return schoolTokenAxios.get(`${subject_url}/getSubjectByMultipleClass`, {
    params: params,
  });
};

export const getSummarizedSubjectReport = (params) => {
  return schoolTokenAxios.get(`${subject_url}/getSummarizedSubjectReport`, {
    params: params,
  });
};
export const getStudentSubjectMappingByClass = (params) => {
  return schoolTokenAxios.get(
    `${subject_url}/getStudentSubjectMappingByClass`,
    {
      params: params,
    }
  );
};

export const getSubjectByClassDocId = (params) => {
  return schoolTokenAxios.get(`${subject_url}/getSubjectByClassDocId`, {
    params: params,
  });
};

export const updateAvailableSubject = (payload) => {
  return schoolTokenAxios.put(`${subject_url}/updateAvailableSubject`, payload);
};

export const mapSubjectWithClass = (payload) => {
  return schoolTokenAxios.put(`${subject_url}/mapSubjectWithClass`, payload);
};

export const addStudentInSubject = (payload) => {
  return schoolTokenAxios.put(`${subject_url}/addStudentInSubject`, payload);
};

export const removeStudentFromSubject = (payload) => {
  return schoolTokenAxios.put(
    `${subject_url}/removeStudentFromSubject`,
    payload
  );
};

export const deleteAvailableSubject = (payload) => {
  return schoolTokenAxios.delete(
    `${subject_url}/deleteAvailableSubject/${payload}`
  );
};

export const deleteSubjectByDocId = (payload) => {
  return schoolTokenAxios.delete(
    `${subject_url}/deleteSubjectByDocId/${payload}`
  );
};
