import React, { useState } from "react";
import CommonDialog from "../../CommonDialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import PaymentModeComponent from "app/components/common/PaymentModeComponent";
import { getAllFirm } from "app/services/schoolService/firm.service";
import { getStaffByDepartmentDocId } from "app/services/management";
import { getAllDepartmentLogic } from "app/services/reception";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { payExpense } from "app/services/ledgerAccount";
import CustomLoader from "app/components/common/CustomLoader";
import { StaffCardDropDown } from "app/components/common/StaffCardDropDown";
import { FirmCardDropDown } from "app/components/common/FirmCardDropDown";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import StaffSearchInput from "app/components/common/StaffSearchInput";
import { Close } from "@mui/icons-material";
import useFolderWiseFileUploader from "app/hooks/upload/useFolderWiseFileUploader";
const LabelText = ({ label = "", value = "" }) => {
  return (
    <Stack direction={"row"} gap={1}>
      <Typography
        sx={{
          color: "primary.main",
          fontWeight: 600,
          opacity: 0.7,
          fontSize: "12px",
        }}
      >
        {label}{" "}
      </Typography>
      <Typography
        sx={{ color: "primary.main", fontWeight: 600, fontSize: "13px" }}
      >
        {value}{" "}
      </Typography>
    </Stack>
  );
};
export const StaffCard = ({ item }) => {
  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems={"center"} px={2}>
        {/* student profile pic */}
        <div>
          <img
            src={
              item?.profilePic ? item?.profilePic : "/image-placeholder.jpeg"
            }
            alt="student"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        {/* student details */}
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "15px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {item?.name}
          </Typography>

          <LabelText
            label="Department:- "
            value={item?.departmentName ?? "--"}
          />
          <LabelText label="Designation:-" value={item?.designation ?? "--"} />
        </Stack>
      </Stack>
    </Box>
  );
};
const PayExpenseDialog = ({
  open = false,
  onClose = () => {},
  data = {},
  refetch = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    payImage: {
      file: "",
      link: "",
    },
    viaStaffDetail: {},
  });
  const [payClicked, setPayClicked] = useState(false);
  const payFormik = useFormik({
    initialValues: {
      type: "Staff",
      dept: "",
      name: "",
      fName: "",
      date: new Date(),
    },
    validationSchema: yup.object({
      type: yup.string().required("Type is required"),
      dept: yup.mixed().when("type", {
        is: (val) => val === "Staff",
        then: yup.mixed().required("Department is required"),
        otherwise: yup.mixed(),
      }),
      name: yup.string().when("type", {
        is: (val) => val === "Staff",
        then: yup.string().required("Name is required"),
        otherwise: yup.string(),
      }),
      fName: yup.string().when("type", {
        is: (val) => val === "Other",
        then: yup.string().required("Firm Name is required"),
        otherwise: yup.string(),
      }),
    }),
    onSubmit: (values) => {
      handleUploadPayImage(values);
    },
  });
  const handleVia = (payload) => {
    setState((prev) => ({ ...prev, viaStaffDetail: payload }));
  };
  const handleClose = () => {
    payFormik.resetForm();
    setPayClicked(false);
    onClose();
    setState((prev) => ({
      ...prev,
      payImage: {
        file: "",
        link: "",
      },
    }));
  };
  const handlePayUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }

    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      payImage: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };
  const { handleUploadImage } = useFolderWiseFileUploader();
  const handleUploadPayImage = async (values) => {
    setLoading(true);
    try {
      let attachmentUrl = await handleUploadImage(
        true,
        state.payImage.file,
        "withSession",
        "ledgerAccount",
        "expense"
      );
      const obj = {
        expenseDocId: data?._id,
        recipientPersonRole: values.type,
        recipientPersonDocId:
          values.type === "Staff" ? values.name : values.fName,
        expenseDate: values.date,
        attachmentUrl: attachmentUrl.fileURL,
        paymentDetails: values.paymentDetails,
        ...(Object.keys(state.viaStaffDetail).length > 0 && {
          viaPersonDocId: state.viaStaffDetail?.staffDocId,
        }),
      };
      payExpenseMutate(obj);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  const { mutate: payExpenseMutate, isLoading: payExpenseLoading } =
    useMutation(payExpense, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        handleClose();
        refetch();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const { isLoading: getAllDepartmentsLoading, data: getAllDepartmentsData } =
    useQuery({
      queryKey: ["getAllDepartments"],
      queryFn: () => getAllDepartmentLogic({ selectedSession, selectedMedium }),
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
      enabled: open ? true : false,
    });
  const { data: getAllFirmData, isLoading: getAllFirmLoading } = useQuery({
    queryKey: ["getAllFirm"],
    queryFn: () => getAllFirm(),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        firms: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.firmName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: payFormik.values.type === "Other" ? true : false,
  });

  const { mutate: getStaffNames } = useMutation(getStaffByDepartmentDocId, {
    onSuccess: ({ data }) => {
      payFormik.setValues((prev) => ({ ...prev, staff: data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  return (
    <>
      <CustomLoader show={getAllDepartmentsLoading || getAllFirmLoading} />
      <CommonDialog
        open={open}
        onClose={handleClose}
        title="Pay Expense"
        minWidth="700px"
      >
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
        >
          <Stack direction="column" gap={2}>
            <Stack direction="row" gap={2}>
              <Stack flex={1}>
                <Label>Expense Head Name</Label>
                <Content>{data?.expenseHeadName || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Sub Head Name</Label>
                <Content>{data?.expenseSubHeadName || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Recipient Person Name</Label>
                <Content>{data?.createdBy?.personName || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Requested Amount</Label>
                <Content>{IndianCurrency(data?.requestedAmount)}</Content>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <Stack sx={{ width: "150px" }}>
                <Label>Remarks</Label>
                <Content>{data?.remarks || "--"}</Content>
              </Stack>

              <Stack
                direction={{ xs: "column", md: "row" }}
                gap={4}
                alignItems={"center"}
                flex={1}
              >
                <Box>
                  <TextFieldLabel title={"Through / Via Person"} />
                  <StaffSearchInput returnResponse={handleVia} />
                </Box>

                <Stack
                  sx={{ flex: 1 }}
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                >
                  {Object.keys(state.viaStaffDetail).length > 0 && (
                    <>
                      <StaffCard item={state.viaStaffDetail} />
                      <Box>
                        <IconButton
                          sx={{ background: "#f6f6f6" }}
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              viaStaffDetail: {},
                            }))
                          }
                        >
                          <Close sx={{ color: "red" }} />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>

            <Grid container>
              <Grid item xs={3}>
                <Label>Approval Amount</Label>
                <Content>{IndianCurrency(data?.approvedAmount)}</Content>
              </Grid>
              <Grid item xs={3}>
                <Label>Approval/Rejection Remarks</Label>
                <Content>{data?.remarks || "--"}</Content>
              </Grid>
            </Grid>
          </Stack>
          <Grid container mt={2}>
            <Grid item xs={4}>
              <Label>
                Created By: <span>{data?.createdBy?.personName || "--"}</span>
              </Label>
              <Label>
                Created On:{" "}
                <span>
                  {data?.createdOn
                    ? dayjs(data?.createdOn).format("DD MMM, YYYY")
                    : "--"}
                </span>
              </Label>
            </Grid>
            <Grid item xs={4}>
              <Label>
                Action Taken By:{" "}
                <span>{data?.actionTakenBy?.personName || "--"}</span>
              </Label>
              <Label>
                Action Taken On:{" "}
                <span>
                  {data?.actionTakenOn
                    ? dayjs(data?.actionTakenOn).format("DD MMM, YYYY")
                    : "--"}
                </span>
              </Label>
            </Grid>
            <Grid item xs={4}>
              <Label>
                Paid By: <span>{data?.paidBy?.personName || "--"}</span>
              </Label>
              <Label>
                Paid On:{" "}
                <span>
                  {data?.paidOn
                    ? dayjs(data?.paidOn).format("DD MMM, YYYY")
                    : "--"}
                </span>
              </Label>
            </Grid>
          </Grid>
          <Stack alignSelf="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => setPayClicked(!payClicked)}
            >
              Pay
            </Button>
          </Stack>
          {payClicked && (
            <>
              <form id="payForm" onSubmit={payFormik.handleSubmit}>
                <Stack>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Label>Recipient Person Type</Label>
                      <CustomSelectBox
                        name="type"
                        value={payFormik.values.type}
                        onBlur={payFormik.handleBlur}
                        onChange={payFormik.handleChange}
                        displayEmpty
                        MenuProps={MenuProps}
                        fullWidth
                        size="medium"
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="Staff">Staff</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </CustomSelectBox>
                      {payFormik.touched.type && payFormik.errors.type && (
                        <Typography sx={{ color: "red" }}>
                          {payFormik.errors.type}
                        </Typography>
                      )}
                    </Grid>
                    {payFormik.values.type === "Staff" && (
                      <>
                        <Grid item xs={4}>
                          <Label>Department</Label>
                          <CustomSelectBox
                            onBlur={payFormik.handleBlur}
                            MenuProps={MenuProps}
                            value={payFormik.values.dept}
                            displayEmpty
                            onChange={(e) => {
                              payFormik.setValues((prev) => ({
                                ...prev,
                                dept: e.target.value,
                              }));
                              getStaffNames(e.target.value);
                            }}
                            fullWidth
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            {getAllDepartmentsData?.data?.list?.map((item) => (
                              <MenuItem value={item._id}>
                                {item.departmentName}
                              </MenuItem>
                            ))}
                          </CustomSelectBox>
                          {payFormik.touched.dept && payFormik.errors.dept && (
                            <Typography sx={{ color: "red" }}>
                              {payFormik.errors.dept}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <Label>Recipient Name</Label>
                          <CustomSelectBox
                            disabled={!payFormik.values.dept}
                            onBlur={payFormik.handleBlur}
                            fullWidth
                            MenuProps={MenuProps}
                            name="name"
                            displayEmpty
                            renderValue={() =>
                              payFormik.values.staff?.find(
                                (item) =>
                                  item.staffDocId === payFormik.values.name
                              )?.firstName
                            }
                            value={payFormik.values.name}
                            onChange={(e) => {
                              payFormik.handleChange(e);
                              // collectionFormik.setValues(prev => ({...prev, class: e.target.value, sections: sections(e.target.value)}))
                            }}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            {payFormik.values.staff?.map((item) => (
                              <MenuItem value={item.staffDocId}>
                                <StaffCardDropDown item={item} />
                              </MenuItem>
                            ))}
                          </CustomSelectBox>
                          {payFormik.touched.name && payFormik.errors.name && (
                            <Typography sx={{ color: "red" }}>
                              {payFormik.errors.name}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}
                    {payFormik.values.type === "Other" && (
                      <>
                        <Grid item xs={4}>
                          <Label>Firm Name</Label>
                          <CustomSelectBox
                            fullWidth
                            onBlur={payFormik.handleBlur}
                            MenuProps={MenuProps}
                            name="fName"
                            displayEmpty
                            value={payFormik.values.fName}
                            onChange={payFormik.handleChange}
                            // renderValue={
                            //   payFormik?.values?.fName
                            //     ? () =>
                            //         getAllFirmData?.data?.list?.find(
                            //           (item) =>
                            //             item.id === payFormik?.values?.fName
                            //         )?.name
                            //     : () => ""
                            // }
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            {getAllFirmData?.data?.list?.map((item) => (
                              <MenuItem value={item._id}>
                                <FirmCardDropDown item={item} />
                              </MenuItem>
                            ))}
                          </CustomSelectBox>
                          {payFormik.touched.fName &&
                            payFormik.errors.fName && (
                              <Typography sx={{ color: "red" }}>
                                {payFormik.errors.fName}
                              </Typography>
                            )}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4}>
                      <Label>Expense Date</Label>
                      <DatePicker
                        inputFormat="DD-MM-YYYY"
                        name="collectionDate"
                        value={payFormik.values.date}
                        onChange={(e) => {
                          payFormik.setValues((prev) => ({
                            ...prev,
                            date: e,
                          }));
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            placeholder="Select"
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Label>Attachment (if any)</Label>
                      <Box>
                        <img
                          src={
                            state?.payImage?.link
                              ? state?.payImage?.link
                              : "/image-placeholder.jpeg"
                          }
                          style={{
                            height: "100px",
                            width: "100px",
                            objectFit: "cover",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                          alt="ekalsutra"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          color="secondary"
                          component="label"
                        >
                          <input
                            type="file"
                            accept="image/*,application/pdf"
                            hidden
                            onChange={handlePayUpload}
                          />
                          Upload
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
              <Stack>
                <PaymentModeComponent
                  amount={data?.approvedAmount}
                  formId="payForm2"
                  onClickSubmit={(data) => {
                    payFormik.setValues((prev) => ({
                      ...prev,
                      paymentDetails: data,
                    }));
                    payFormik.handleSubmit();
                  }}
                />
              </Stack>
            </>
          )}
        </DialogContent>
        {payClicked && (
          <DialogActions>
            <LoadingButton
              loading={payExpenseLoading || loading}
              type="submit"
              form="payForm2"
              variant="contained"
              color="secondary"
              size="large"
            >
              Submit
            </LoadingButton>
          </DialogActions>
        )}
      </CommonDialog>
    </>
  );
};

export default PayExpenseDialog;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 500,
  color: "#0C2F49",
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  fontWeight: 700,
  color: "#0C2F49",
}));
