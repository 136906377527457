import { Stack, Typography } from "@mui/material";
import { FormatFullName } from "app/utils/helper";
import moment from "moment";
import React, { useState } from "react";

export const CertificateHeaderDetailsA4 = ({
  label,
  value,
  showAdm = true,
  showSrNo = true,
  showRegNo = true,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      sx={{
        width: "100%",
        display: "flex",
        alingself: "center",
        flex: 1,
        padding: "8px",
      }}
    >
      {showAdm && (
        <Typography
          sx={{
            fontSize: "16px",
          }}
          style={{ fontFamily: "monospace" }}
        >
          Adm. No:{" "}
          <span style={{ fontWeight: 700 }}>
            {value?.admissionNumber ?? "--"}
          </span>
        </Typography>
      )}
      {showSrNo && (
        <Typography
          sx={{
            fontSize: "16px",
          }}
          style={{ fontFamily: "monospace" }}
        >
          SR No:{" "}
          <span style={{ fontWeight: 700 }}>{value?.srNumber ?? "--"}</span>
        </Typography>
      )}
      {showRegNo && (
        <Typography
          sx={{
            fontSize: "16px",
          }}
          style={{ fontFamily: "monospace" }}
        >
          Reg. No.:{" "}
          <span style={{ fontWeight: 700 }}>
            {value?.registrationNumber ?? "--"}
          </span>
        </Typography>
      )}
    </Stack>
  );
};

export const CertificateHeaderDetails = ({
  label,
  value,
  showAdm = true,
  showSrNo = true,
  showRegNo = true,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      marginTop={2}
      sx={{
        width: "88%",
        display: "flex",
        alingself: "center",
        flex: 1,
        marginLeft: "6%",
      }}
    >
      {showAdm && (
        <Typography className="content" style={{ fontFamily: "monospace" }}>
          Admission No:{" "}
          <span style={{ fontWeight: 700 }}>
            {value?.admissionNumber ?? "--"}
          </span>
        </Typography>
      )}
      {showSrNo && (
        <Typography className="content" style={{ fontFamily: "monospace" }}>
          SR No:{" "}
          <span style={{ fontWeight: 700 }}>{value?.srNumber ?? "--"}</span>
        </Typography>
      )}
      {showRegNo && (
        <Typography className="content" style={{ fontFamily: "monospace" }}>
          Registration No.:{" "}
          <span style={{ fontWeight: 700 }}>
            {value?.registrationNumber ?? "--"}
          </span>
        </Typography>
      )}
    </Stack>
  );
};

export const CertificateBottomDetailsA4 = () => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap={"55%"}
      alignItems={"center"}
      sx={{}}
    >
      <Typography
        sx={{
          fontSize: "16px",
        }}
        style={{
          fontFamily: "monospace",
        }}
      >
        .........
        <br />
        <span style={{ alignSelf: "center", marginLeft: 15 }}>Date</span>
      </Typography>
      <Typography
        style={{
          fontFamily: "monospace",
        }}
        sx={{
          fontSize: "16px",
        }}
      >
        ...........
        <br />
        <span style={{ alignSelf: "center", marginLeft: 5 }}>(Principal)</span>
      </Typography>
    </Stack>
  );
};

export const CertificateBottomDetails = () => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap={"60%"}
      alignItems={"center"}
      sx={{}}
    >
      <Typography
        className="content"
        style={{
          fontFamily: "monospace",
        }}
      >
        .........
        <br />
        <span style={{ alignSelf: "center", marginLeft: 15 }}>Date</span>
      </Typography>
      <Typography
        style={{
          fontFamily: "monospace",
        }}
        className="content"
      >
        ...........
        <br />
        <span style={{ alignSelf: "center", marginLeft: 5 }}>(Principal)</span>
      </Typography>
    </Stack>
  );
};

const CustomInput = ({
  type = "text",
  value,
  onChange,
  fontSize = 20,
  fontWeight = 700,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      {type === "date" ? (
        <input
          type={isFocused ? "date" : "text"}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
          value={isFocused ? value : moment(value).format("DD-MM-YYYY")}
          style={{
            fontWeight: fontWeight,
            fontSize: fontSize,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            width: "150px", // Fixed width for date input
          }}
          className="custom-date-input" // Add a class for custom styling
        />
      ) : type === "number" ? (
        <input
          type={isFocused ? "number" : "text"}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
          value={value}
          style={{
            fontWeight: fontWeight,
            fontSize: fontSize,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            width: (String(value)?.length || 1) * 12,
          }}
        />
      ) : (
        <input
          type={type}
          onChange={onChange}
          value={value}
          placeholder="0"
          style={{
            fontWeight: fontWeight,
            fontSize: fontSize,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            width: (String(value)?.length || 1) * 12,
          }}
        />
      )}
      <style jsx>{`
        .custom-date-input::-webkit-calendar-picker-indicator {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
        .custom-date-input::-webkit-calendar-picker-indicator:hover {
          opacity: 0.8;
        }
      `}</style>
    </>
  );
};

export default CustomInput;

export const ContentOfCertificate = ({
  certificateType,
  studentData,
  schoolDetail = " ",
  participationData,
  feeTypes = [],
  feeSummary = {},
}) => {
  let content = "";
  const [fullName, setFullName] = React.useState(
    FormatFullName(
      studentData?.firstName,
      studentData?.middleName,
      studentData?.lastName
    )
  );

  const [state, setState] = React.useState({
    fatherName: FormatFullName(
      studentData?.fatherInfo?.firstName,
      studentData?.fatherInfo?.middleName,
      studentData?.fatherInfo?.lastName
    ),
    motherName: FormatFullName(
      studentData?.motherInfo?.firstName,
      studentData?.motherInfo?.middleName,
      studentData?.motherInfo?.lastName
    ),
    Address: studentData?.currentAddress?.address ?? "--",
    Dist: studentData?.currentAddress?.dist ?? "--",
    PinCode: studentData?.currentAddress?.pinCode ?? "--",
    State: studentData?.currentAddress?.state ?? "--",
    className: `${studentData?.className ?? "--"} ${
      studentData?.stream ?? "--"
    } ${studentData?.section ?? "--"}`,
    DOB: new Date(studentData?.DOB) ?? new Date(),
    eventDate: participationData?.eventDate ?? new Date(),
    eventName: participationData?.eventName ?? "--",
    eventDetails: participationData?.eventDetails ?? "--",
    session: studentData?.session ?? "--",
    rollNumber: studentData?.rollNumber ?? "--",
    designation: studentData?.designation ?? "--",
    joiningDate: studentData?.joiningDate ?? new Date(),
    leavingDate: studentData?.leavingDate ?? new Date(),
    schoolLeavingReason: "whatever legal reason it may concern",

    //FEE CERTIFICATE INPUTS
    feeTypes: feeTypes,
    feeSummary: feeSummary,
  });

  switch (certificateType) {
    case "character":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          It is certify that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          , son/daughter of{" "}
          <CustomInput
            value={state.fatherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                fatherName: e.target.value,
              }))
            }
          />
          , address -
          <CustomInput
            value={state.Address}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Address: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.Dist}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Dist: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.State}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                State: e.target.value,
              }))
            }
          />
          (
          {
            <CustomInput
              value={state.PinCode}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  PinCode: e.target.value,
                }))
              }
            />
          }
          ) is a bonafide student of class -
          <CustomInput
            value={state.className}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                className: e.target.value,
              }))
            }
          />
          . his/her date of birth is{" "}
          <CustomInput
            type={"date"}
            value={state?.DOB}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                DOB: e.target.value,
              }))
            }
          />
          according to the scholar register. I know about him/her that he/she
          has a good character
          <br></br>
          God bless him/her with a good future.
        </Typography>
      );
      break;
    case "schoolLeaving":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          This is to certified that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          , son/daughter of Mr.{" "}
          <CustomInput
            value={state.fatherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                fatherName: e.target.value,
              }))
            }
          />{" "}
          {studentData?.motherInfo && <span>and Mrs. </span>}
          <CustomInput
            value={state.motherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                motherName: e.target.value,
              }))
            }
          />
          ,is a bonafide student of this school. His/Her date of birth is{" "}
          <CustomInput
            type={"date"}
            value={state.DOB}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                DOB: e.target.value,
              }))
            }
          />{" "}
          according to the school records. He/She has passed class{" "}
          <CustomInput
            value={state.className}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                className: e.target.value,
              }))
            }
          />{" "}
          <br></br>
          We wish him/her all the best for his/her future. This certificate is
          issued on his/her request for the purpose of{" "}
          <CustomInput
            value={state.schoolLeavingReason}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                schoolLeavingReason: e.target.value,
              }))
            }
          />
        </Typography>
      );
      break;
    case "DOB":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          This is certify that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          , son/daughter of{" "}
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            Mr.{" "}
            <CustomInput
              value={state.fatherName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  fatherName: e.target.value,
                }))
              }
            />
            and Mrs.{" "}
            <CustomInput
              value={state.motherName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  motherName: e.target.value,
                }))
              }
            />
          </span>
          , Address -
          <CustomInput
            value={state.Address}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Address: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.Dist}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Dist: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.State}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                State: e.target.value,
              }))
            }
          />
          (
          <CustomInput
            value={state.PinCode}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                PinCode: e.target.value,
              }))
            }
          />
          ) is a bonafide student of this school.
          <br></br>
          His/Her date of birth is{" "}
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            {studentData?.DOB
              ? moment(studentData?.DOB).format("DD-MM-YYYY")
              : null}
          </span>{" "}
          according to the school records.
          <br></br>
          We wish him/her all the best for his/her future.
        </Typography>
      );
      break;
    case "participation":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          This is certify that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />{" "}
          has actively participated in{" "}
          <CustomInput
            value={state.eventName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                eventName: e.target.value,
              }))
            }
          />
          . In recogniion of his/her active and invaluable participation during
          the conduct of three days workshop on the{" "}
          <CustomInput
            value={state.eventDetails}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                eventDetails: e.target.value,
              }))
            }
          />{" "}
          held on{" "}
          <CustomInput
            type={"date"}
            value={state.eventDate}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                eventDate: e.target.value,
              }))
            }
          />
          . He/She has been awarded this certificate.
        </Typography>
      );
      break;
    case "bonafied":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          This is to Certified that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />{" "}
          ,son/daughter of Mr.{" "}
          <CustomInput
            value={state.fatherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                fatherName: e.target.value,
              }))
            }
          />
          {studentData?.motherInfo && <span>and Mrs. </span>}
          <CustomInput
            value={state.motherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                motherName: e.target.value,
              }))
            }
          />
          ,bearing roll no{" "}
          <CustomInput
            value={state.rollNumber}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                rollNumber: e.target.value,
              }))
            }
          />{" "}
          is a student of class{" "}
          <CustomInput
            value={state.className}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                className: e.target.value,
              }))
            }
          />{" "}
          for the academic year{" "}
          <CustomInput
            value={state.session}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                session: e.target.value,
              }))
            }
          />
          .He/She is bonafide student of this school.
          <br></br>
          His/Her date of birth is{" "}
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            {studentData?.DOB
              ? moment(studentData?.DOB).format("DD-MM-YYYY")
              : null}
          </span>{" "}
          according to the school records.
        </Typography>
      );
      break;
    case "fee":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          <br></br>
          This is to Certified that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />{" "}
          ,son/daughter of{" "}
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            Mr.{" "}
            <CustomInput
              value={state.fatherName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  fatherName: e.target.value,
                }))
              }
            />
            {studentData?.motherInfo && <span>and Mrs. </span>}
            <CustomInput
              value={state.motherName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  motherName: e.target.value,
                }))
              }
            />
          </span>
          ,Address -
          <CustomInput
            value={state.Address}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Address: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.Dist}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Dist: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.State}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                State: e.target.value,
              }))
            }
          />
          (
          <CustomInput
            value={state.PinCode}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                PinCode: e.target.value,
              }))
            }
          />
          ) is a bonafide student of class{" "}
          <CustomInput
            value={state.className}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                className: e.target.value,
              }))
            }
          />{" "}
          in this school.
          <br></br>
          He/She has deposited his/her fee of session{" "}
          <CustomInput
            value={state.session}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                session: e.target.value,
              }))
            }
          />{" "}
          is as under.
          <br></br>
          {state?.feeTypes?.length > 0 &&
            state?.feeTypes?.map((fee, index) => (
              <Stack key={index} direction="row" gap={2} width={"100%"}>
                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    width: "250px",
                    fontWeight: 700,
                  }}
                >
                  <CustomInput
                    value={fee?.feeType}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        feeTypes: state.feeTypes?.map((item, i) =>
                          i === index
                            ? { ...item, feeType: e.target.value }
                            : item
                        ),
                      }));
                    }}
                  />
                </Typography>
                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                    width: "15px",
                  }}
                >
                  {"-"}
                </Typography>
                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    width: "400px",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  Rs.
                  <CustomInput
                    value={fee?.generalFeeAmount ?? 0}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        feeTypes: state.feeTypes?.map((item, i) =>
                          i === index
                            ? { ...item, generalFeeAmount: e.target.value }
                            : item
                        ),
                      }));
                    }}
                    type="number"
                  />
                  X
                  <CustomInput
                    value={fee?.paidFeeCount ?? ""}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        feeTypes: state.feeTypes?.map((item, i) =>
                          i === index
                            ? { ...item, paidFeeCount: e.target.value || "" }
                            : item
                        ),
                      }));
                    }}
                    type="number"
                  />
                </Typography>
                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    width: "200px",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  {"="} Rs. {fee?.generalFeeAmount * (fee?.paidFeeCount ?? 0)}
                </Typography>
              </Stack>
            ))}
          {state?.feeSummary?.feeTypeCount > 0 && (
            <Typography
              className="content"
              style={{
                fontFamily: "monospace",
                fontWeight: 400,
              }}
            >
              --------------------------------------------------------------------------
              <Stack direction="row" gap={2}>
                {/* fee */}
                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                  }}
                >
                  Fee Rs:
                  <CustomInput
                    value={state?.feeSummary?.totalGeneralFeeSummary ?? 0}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        feeSummary: {
                          ...state.feeSummary,
                          totalGeneralFeeSummary: e.target.value,
                        },
                      }));
                    }}
                    type="number"
                  />
                </Typography>

                {/* discount */}

                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                  }}
                >
                  Discount Rs.
                  <CustomInput
                    value={
                      state?.feeSummary?.totalCategoryDiscountSummary ??
                      0 + state?.feeSummary?.totalRecurringDiscountSummary ??
                      0 + state?.feeSummary?.totalExtraDiscountSummary ??
                      0
                    }
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        feeSummary: {
                          ...state.feeSummary,
                          totalCategoryDiscountSummary: e.target.value,
                          totalRecurringDiscountSummary: e.target.value,
                          totalExtraDiscountSummary: e.target.value,
                        },
                      }));
                    }}
                    type="number"
                  />
                </Typography>

                {/* paid */}

                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                  }}
                >
                  Paid Rs.
                  <CustomInput
                    value={state?.feeSummary?.totalPaidFeeSummary ?? 0}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        feeSummary: {
                          ...state.feeSummary,
                          totalPaidFeeSummary: e.target.value,
                        },
                      }));
                    }}
                    type="number"
                  />
                </Typography>

                {/* balance */}

                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                  }}
                >
                  Balance Rs.
                  <CustomInput
                    value={state?.feeSummary?.totalBalanceFeeSummary ?? 0}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        feeSummary: {
                          ...state.feeSummary,
                          totalBalanceFeeSummary: e.target.value,
                        },
                      }));
                    }}
                    type="number"
                  />
                </Typography>
              </Stack>
              {/* ----------------------------------- */}
            </Typography>
          )}
        </Typography>
      );
      break;
    case "teacherExperience":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          It is certify that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />{" "}
          ,as worked as a{" "}
          <CustomInput
            value={state.designation}
            onChange={(e) =>
              setState((prev) => ({ ...prev, designation: e.target.value }))
            }
          />{" "}
          ,from{" "}
          <CustomInput
            type={"date"}
            value={new Date(state.joiningDate)}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                joiningDate: e?.target?.value,
              }))
            }
          />{" "}
          to{" "}
          <CustomInput
            type={"date"}
            value={state?.leavingDate}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                leavingDate: e?.target?.value,
              }))
            }
          />{" "}
          at
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            {" "}
            {schoolDetail?.schoolName ?? "--"}
          </span>
          . During this period, his/her conduct was good and his/her work was
          satisfactory. He/She is a dedicated and hardworking teacher. We wish
          him/her all the best for his/her future.
          {"\n"}
          He/She had a good command over the subject he/she thaught. He/She has
          good communication skills and was able to explain the concepts to the
          students in a very simple and effective manner. He/She was always
          punctual and regular in his/her work.
        </Typography>
      );
      break;
    default:
      content = "";
  }
  return content;
};
