import { Close } from "@mui/icons-material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { queryClient } from "App";
import CustomLoader from "app/components/common/CustomLoader";
import AddExpenseSubHead from "app/components/common/Dialogs/AddExpenseSubHead";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import { FirmCardDropDown } from "app/components/common/FirmCardDropDown";
import PaymentModeComponent from "app/components/common/PaymentModeComponent";
import { StaffCardDropDown } from "app/components/common/StaffCardDropDown";
import StaffSearchInput from "app/components/common/StaffSearchInput";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import useFolderWiseFileUploader from "app/hooks/upload/useFolderWiseFileUploader";
import {
  selectActiveSession,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import {
  addCompletedExpenseDetail,
  getAllExpenseHead,
  getAllExpenseSubHead,
  requestExpense,
} from "app/services/ledgerAccount";
import { getStaffByDepartmentDocId } from "app/services/management";
import { getAllDepartmentLogic } from "app/services/reception";
import {
  getAllBusinessCategory,
  getAllFirm,
} from "app/services/schoolService/firm.service";
import { getTransport } from "app/services/student-management";
import { MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
  TabsWrapper,
} from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
const LabelText = ({ label = "", value = "" }) => {
  return (
    <Stack direction={"row"} gap={1}>
      <Typography
        sx={{
          color: "primary.main",
          fontWeight: 600,
          opacity: 0.7,
          fontSize: "12px",
        }}
      >
        {label}{" "}
      </Typography>
      <Typography
        sx={{ color: "primary.main", fontWeight: 600, fontSize: "13px" }}
      >
        {value}{" "}
      </Typography>
    </Stack>
  );
};
export const StaffCard = ({ item }) => {
  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems={"center"} px={2}>
        {/* student profile pic */}
        <div>
          <img
            src={
              item?.profilePic ? item?.profilePic : "/image-placeholder.jpeg"
            }
            alt="student"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        {/* student details */}
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "15px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {item?.name}
          </Typography>

          <LabelText
            label="Department:- "
            value={item?.departmentName ?? "--"}
          />
          <LabelText label="Designation:-" value={item?.designation ?? "--"} />
        </Stack>
      </Stack>
    </Box>
  );
};
const dt = new Date();
const ExpensesDialog = ({ dialogs, setDialogs, refetch = () => {} }) => {
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [openExpenseSubHeadDialog, setOpenExpenseSubHeadDialog] =
    useState(false);
  const handleUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }

    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      image: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    tableData: null,
    viaStaffDetail: {},
  });

  const reqExpFormik = useFormik({
    initialValues: {
      subHeads: [],
      heads: [],
      head: "",
      subHead: "",
      vehicle: "",
      amt: "",
      purpose: "",
      isVehicle: false,
    },
    validationSchema: yup.object({
      head: yup.mixed().required("Head Selection is required"),
      subHead: yup.mixed().required("Sub Head Selection is required"),
      vehicle: yup.string().when("isVehicle", {
        is: true,
        then: yup.string().required("Vehicle Selection is required"),
      }),

      amt: yup.number().required("Amount is required"),
      purpose: yup.string().required("Purpose is required"),
    }),
    onSubmit: (values) => {
      const obj = {
        session: selectedSession,
        expenseSubHeadDocId: values.subHead,
        vehicleNumber: values.vehicle,
        requestedAmount: values.amt,
        purpose: values.purpose,
      };
      requestExpenseMutate(obj);
    },
  });
  const compExpFormik = useFormik({
    initialValues: {
      subHeads: [],
      heads: [],
      head: "",
      subHead: "",
      vehicle: "",
      type: "Staff",
      date: dt,
      dept: "",
      name: "",
      fName: "",
      amt: "",
      purpose: "",
      isVehicle: false,
    },
    validationSchema: yup.object({
      head: yup.mixed().required("Head Selection is required"),
      subHead: yup.mixed().required("Sub Head Selection is required"),
      vehicle: yup.string().when("isVehicle", {
        is: true,
        then: yup.string().required("Vehicle Selection is required"),
      }),
      amt: yup.number().required("Amount is required"),
      purpose: yup.string().required("Purpose is required"),
      type: yup.string().required("Type is required"),
      dept: yup.mixed().when("type", {
        is: (val) => val === "Staff",
        then: yup.mixed().required("Department is required"),
        otherwise: yup.mixed(),
      }),
      name: yup.string().when("type", {
        is: (val) => val === "Staff",
        then: yup.string().required("Name is required"),
        otherwise: yup.string(),
      }),
      fName: yup.string().when("type", {
        is: (val) => val === "Other",
        then: yup.string().required("Firm Name is required"),
        otherwise: yup.string(),
      }),
    }),
    onSubmit: (values) => {
      handleUploadServer(values);
    },
  });

  const { handleUploadImage } = useFolderWiseFileUploader();
  const handleUploadServer = async (values) => {
    setLoading(true);
    try {
      let attachmentUrl = state.image?.file
        ? await handleUploadImage(
            true,
            state.image.file,
            "withSession",
            "ledgerAccount",
            "expense"
          )
        : { fileURL: null };
      const obj = {
        session: selectedSession,
        expenseSubHeadDocId: values.subHead,
        ...(attachmentUrl && {
          attachmentUrl: attachmentUrl?.fileURL,
        }),
        vehicleNumber: values.vehicle,
        purpose: values.purpose,
        recipientPersonRole: values.type,
        recipientPersonDocId:
          values.type === "Staff" ? values.name : values.fName,
        expenseDate: values.date,
        paymentDetails: values.paymentDetails,
        ...(state.viaStaffDetail?.staffDocId && {
          viaPersonDocId: state?.viaStaffDetail?.staffDocId,
        }),
      };
      addCompletedExpenseDetailMutate(obj);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  const handleVia = (payload) => {
    setState((prev) => ({ ...prev, viaStaffDetail: payload }));
  };
  const depts = state?.departments?.map((item) => ({
    id: item._id,
    name: item.departmentName,
  }));
  //   get departments
  const {
    isLoading: getAllDepartmentsLoading,
    isFetching: getAllDepartmentFetching,
  } = useQuery({
    queryKey: ["getAllDepartments"],
    queryFn: () => getAllDepartmentLogic({ selectedSession, selectedMedium }),
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, departments: data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: value === "1" ? true : false,
  });
  // get staff names
  const { mutate: getStaffNames, isLoading: staffNamesLoading } = useMutation(
    getStaffByDepartmentDocId,
    {
      onSuccess: ({ data }) => {
        compExpFormik.setValues((prev) => ({ ...prev, staff: data?.list }));
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    }
  );
  //   add request expenses
  const { mutate: requestExpenseMutate, isLoading: requestExpenseLoading } =
    useMutation(requestExpense, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        setDialogs((prev) => ({
          ...prev,
          add: false,
          subHead: "",
        }));
        setState((prev) => ({ ...prev, viaStaffDetail: {} }));
        reqExpFormik.resetForm();
        queryClient.invalidateQueries({ queryKey: "getAllExpense" });
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  // get transport
  const { isLoading: getTransportLoading, isFetching: getTransportFetching } =
    useQuery({
      queryKey: ["getTransport"],
      queryFn: () => getTransport({ status: "ACTIVE" }),
      onSuccess: (res) => {
        reqExpFormik.setValues((prev) => ({
          ...prev,
          transportList: res?.data?.list?.map((item) => item.vehicleNumber),
        }));
        compExpFormik.setValues((prev) => ({
          ...prev,
          transportList: res?.data?.list?.map((item) => item.vehicleNumber),
        }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: compExpFormik.values.isVehicle || reqExpFormik.values.isVehicle,
    });
  // add completed expenses
  const {
    mutate: addCompletedExpenseDetailMutate,
    isLoading: addCompletedExpenseDetailLoading,
  } = useMutation(addCompletedExpenseDetail, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      setDialogs((prev) => ({
        ...prev,
        add: false,
        subHead: "",
      }));
      refetch();

      setState((prev) => ({ ...prev, viaStaffDetail: {} }));
      compExpFormik.resetForm();
      queryClient.invalidateQueries({ queryKey: "getAllExpense" });
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  //   get all expense head
  const {
    isLoading: getAllExpenseHeadLoading,
    isFetching: getAllExpenseHeadFetching,
  } = useQuery({
    queryKey: ["getAllExpenseHead", selectedSession, selectedMedium],
    queryFn: getAllExpenseHead,
    onSuccess: (res) => {
      reqExpFormik.setValues((prev) => ({
        ...prev,
        ...(dialogs?.headId && { head: dialogs.headId }),
        heads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseHeadName,
        })),
      }));
      compExpFormik.setValues((prev) => ({
        ...prev,
        ...(dialogs?.subHead && { subHead: dialogs.subHead }),
        ...(dialogs?.headId && { head: dialogs.headId }),
        heads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: dialogs?.add ? true : false,
  });
  // get all sub heads
  const {
    isLoading: getAllExpenseSubHeadForReqLoading,
    isFetching: getAllExpenseSubHeadForReqFetching,
    refetch: getAllExpenseSubHeadForReqRefetch,
  } = useQuery({
    queryKey: ["getAllExpenseSubHeadForReq", reqExpFormik.values.head],
    queryFn: () =>
      getAllExpenseSubHead({ expenseHeadDocId: reqExpFormik.values.head }),
    onSuccess: (res) => {
      reqExpFormik.setValues((prev) => ({
        ...prev,
        subHeads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseSubHeadName,
          description: item?.description,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: reqExpFormik.values.head ? true : false,
  });
  //   get all firm
  const { mutate: getAllFirmMutate, isLoading: getAllFirmLoading } =
    useMutation(getAllFirm, {
      onSuccess: (res) => {
        setState((prev) => ({ ...prev, firms: res?.data?.list }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  // get all business categories
  const { refetch: getAllBusinessCatQ, isLoading: getAllBusinessCatLoading } =
    useQuery({
      queryKey: "getAllBusinessCat",
      queryFn: () => getAllBusinessCategory(),
      enabled: false,
      onSuccess: ({ data }) => {
        compExpFormik.setValues((prev) => ({ ...prev, cat: data?.list }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  // get all request sub heads

  // get all completed sub heads
  const {
    isLoading: getAllExpenseSubHeadForComLoading,
    isFetching: getAllExpenseSubHeadForComFetching,
    refetch: getAllExpenseSubHeadForComRefetch,
  } = useQuery({
    queryKey: ["getAllExpenseSubHeadForCom", compExpFormik.values.head],
    queryFn: () =>
      getAllExpenseSubHead({ expenseHeadDocId: compExpFormik.values.head }),
    onSuccess: (res) => {
      compExpFormik.setValues((prev) => ({
        ...prev,
        subHeads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseSubHeadName,
          description: item.description,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: compExpFormik.values.head ? true : false,
  });
  return (
    <>
      <AddExpenseSubHead
        open={openExpenseSubHeadDialog}
        handleClose={() => setOpenExpenseSubHeadDialog(false)}
        headId={
          value === "1" ? reqExpFormik.values.head : compExpFormik.values.head
        }
        refetch={() => {
          getAllExpenseSubHeadForReqRefetch({
            expenseHeadDocId: reqExpFormik.values.head,
          });
          getAllExpenseSubHeadForComRefetch({
            expenseHeadDocId: compExpFormik.values.head,
          });
        }}
      />
      <CustomLoader
        show={
          loading ||
          getAllExpenseSubHeadForComLoading ||
          getAllExpenseSubHeadForComFetching ||
          getAllExpenseSubHeadForReqLoading ||
          getAllExpenseSubHeadForReqFetching ||
          getAllBusinessCatLoading ||
          getAllFirmLoading ||
          getAllExpenseHeadLoading ||
          getAllExpenseHeadFetching ||
          getTransportLoading ||
          getTransportFetching ||
          staffNamesLoading ||
          getAllDepartmentsLoading ||
          getAllDepartmentFetching
        }
      />
      <CommonDialog
        open={dialogs?.add}
        onClose={() => {
          setDialogs((prev) => ({
            ...prev,
            add: false,
            subHead: "",
          }));
          setState((prev) => ({ ...prev, viaStaffDetail: {} }));
          reqExpFormik.resetForm();
          compExpFormik.resetForm();
        }}
        minWidth="700px"
        title="Add Expense"
      >
        <DialogContent>
          <TabContext value={value}>
            <TabsWrapper>
              <TabList
                onChange={(e, newValue) => {
                  setValue(newValue);
                }}
              >
                <Tab label="Add Completed Expense" value="1" />
                <Tab label="Request Expense" value="2" />
              </TabList>
            </TabsWrapper>

            <TabPanel value="1" sx={{ p: "0 !important" }}>
              <form id="reqExpForm" onSubmit={reqExpFormik.handleSubmit}>
                <Grid container rowSpacing={1.5} columnSpacing={1} mt={1}>
                  <Grid item xs={6} md={4}>
                    <Label>Expense Head</Label>
                    <CustomSelectBox
                      name="head"
                      fullWidth
                      size="small"
                      value={compExpFormik.values.head}
                      displayEmpty
                      MenuProps={MenuProps}
                      onChange={(e) => {
                        compExpFormik.handleChange(e);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {compExpFormik.values.heads?.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </CustomSelectBox>
                    {compExpFormik.touched.head &&
                      compExpFormik.errors.head && (
                        <Typography sx={{ color: "red" }}>
                          {compExpFormik.errors.head}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Label>Sub Head</Label>
                    <CustomSelectBox
                      name="subHead"
                      disabled={!compExpFormik.values.head}
                      fullWidth
                      size="small"
                      displayEmpty
                      value={compExpFormik.values.subHead}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {compExpFormik.values.subHeads?.map((item) => (
                        <MenuItem
                          value={item?.id}
                          onClick={() =>
                            compExpFormik.setValues((prev) => ({
                              ...prev,
                              subHead: item.id,
                              description: item?.description,
                            }))
                          }
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                      <MenuItem
                        sx={{
                          color: "blue",
                          textDecoration: "underline",
                          fontStyle: "italic",
                        }}
                        value="Add New"
                        onClick={() => {
                          setOpenExpenseSubHeadDialog(true);
                          compExpFormik.setValues((prev) => ({
                            ...prev,
                            subHead: "",
                          }));
                        }}
                      >
                        Add New
                      </MenuItem>
                    </CustomSelectBox>
                    {compExpFormik.touched.subHead &&
                      compExpFormik.errors.subHead && (
                        <Typography sx={{ color: "red" }}>
                          {compExpFormik.errors.subHead}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Stack direction="row" alignItems="center">
                      <Label>Vehicle Expense ?</Label>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ mt: "-10px" }}
                      >
                        <Checkbox
                          checked={compExpFormik.values.isVehicle}
                          onChange={(e) =>
                            compExpFormik.setValues((prev) => ({
                              ...prev,
                              isVehicle: !prev.isVehicle,
                            }))
                          }
                        />
                        <Typography>Yes</Typography>
                      </Stack>
                    </Stack>
                    {compExpFormik.values.isVehicle && (
                      <>
                        <CustomSelectBox
                          fullWidth
                          size="small"
                          displayEmpty
                          name="vehicle"
                          value={compExpFormik.values.vehicle}
                          MenuProps={MenuProps}
                          onChange={(e) => {
                            compExpFormik.handleChange(e);
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {compExpFormik.values.transportList?.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </CustomSelectBox>
                        {compExpFormik.touched.vehicle &&
                          compExpFormik.errors.vehicle && (
                            <Typography sx={{ color: "red" }}>
                              {compExpFormik.errors.vehicle}
                            </Typography>
                          )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Label>Sub Head Description</Label>
                    <Typography>
                      {compExpFormik.values.description || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      gap={4}
                      alignItems={"center"}
                    >
                      <Box>
                        <TextFieldLabel title={"Via"} />
                        <StaffSearchInput returnResponse={handleVia} />
                      </Box>

                      <Stack
                        sx={{ flex: 1 }}
                        direction={"row"}
                        gap={2}
                        alignItems={"center"}
                      >
                        {Object.keys(state.viaStaffDetail).length > 0 && (
                          <>
                            <StaffCard item={state.viaStaffDetail} />
                            <Box>
                              <IconButton
                                sx={{ background: "#f6f6f6" }}
                                onClick={() =>
                                  setState((prev) => ({
                                    ...prev,
                                    viaStaffDetail: {},
                                  }))
                                }
                              >
                                <Close sx={{ color: "red" }} />
                              </IconButton>
                            </Box>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Label>Recipient Person Type</Label>
                    <CustomSelectBox
                      name="type"
                      fullWidth
                      size="small"
                      value={compExpFormik.values.type}
                      onChange={(e) => {
                        compExpFormik.handleChange(e);
                        e.target.value === "Other" && getAllBusinessCatQ();
                      }}
                      displayEmpty
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="Staff">Staff</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </CustomSelectBox>
                    {compExpFormik.touched.type &&
                      compExpFormik.errors.type && (
                        <Typography sx={{ color: "red" }}>
                          {compExpFormik.errors.type}
                        </Typography>
                      )}
                  </Grid>
                  {compExpFormik.values.type === "Staff" && (
                    <>
                      <Grid item xs={6} md={4}>
                        <Label>Department</Label>
                        <CustomSelectBox
                          MenuProps={MenuProps}
                          value={compExpFormik.values.dept}
                          displayEmpty
                          onChange={(e) => {
                            compExpFormik.setValues((prev) => ({
                              ...prev,
                              dept: e.target.value,
                            }));
                            getStaffNames(e.target.value);
                          }}
                          fullWidth
                          size="small"
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {depts?.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </CustomSelectBox>
                        {compExpFormik.touched.dept &&
                          compExpFormik.errors.dept && (
                            <Typography sx={{ color: "red" }}>
                              {compExpFormik.errors.dept}
                            </Typography>
                          )}
                      </Grid>

                      <Grid item xs={6} md={4}>
                        <Label>Recipient Name</Label>
                        <CustomSelectBox
                          disabled={!compExpFormik.values.dept}
                          fullWidth
                          size="small"
                          MenuProps={MenuProps}
                          name="name"
                          displayEmpty
                          value={compExpFormik.values.name}
                          renderValue={() =>
                            compExpFormik?.values?.name
                              ? compExpFormik?.values?.staff?.find(
                                  (item) =>
                                    item?.staffDocId ===
                                    compExpFormik?.values?.name
                                )?.firstName +
                                " " +
                                compExpFormik?.values?.staff?.find(
                                  (item) =>
                                    item?.staffDocId ===
                                    compExpFormik?.values?.name
                                )?.middleName +
                                " " +
                                compExpFormik?.values?.staff?.find(
                                  (item) =>
                                    item?.staffDocId ===
                                    compExpFormik?.values?.name
                                )?.lastName
                              : ""
                          }
                          onChange={compExpFormik.handleChange}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {compExpFormik.values.staff?.map((item) => (
                            <MenuItem value={item.staffDocId}>
                              <StaffCardDropDown item={item} />
                            </MenuItem>
                          ))}
                        </CustomSelectBox>
                        {compExpFormik.touched.name &&
                          compExpFormik.errors.name && (
                            <Typography sx={{ color: "red" }}>
                              {compExpFormik.errors.name}
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                  {compExpFormik.values.type === "Other" && (
                    <>
                      <Grid item xs={6} md={4}>
                        <Label>Business Category</Label>
                        <CustomSelectBox
                          sx={{ width: "17rem" }}
                          MenuProps={MenuProps}
                          name="selectedCat"
                          displayEmpty
                          value={compExpFormik.values.selectedCat || ""}
                          onChange={(e) => {
                            compExpFormik.handleChange(e);
                            getAllFirmMutate({
                              businessCategory: e.target.value,
                            });
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {compExpFormik.values.cat &&
                            compExpFormik.values.cat?.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </CustomSelectBox>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Label>Firm Name</Label>
                        <CustomSelectBox
                          fullWidth
                          size="small"
                          MenuProps={MenuProps}
                          name="fName"
                          displayEmpty
                          disabled={!compExpFormik.values.selectedCat}
                          value={compExpFormik.values.fName}
                          onChange={compExpFormik.handleChange}
                          renderValue={
                            compExpFormik.values.fName
                              ? () => {
                                  const item = state.firms.find(
                                    (item) =>
                                      item._id === compExpFormik.values.fName
                                  );
                                  return item?.firmName;
                                }
                              : null
                          }
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {state?.firms?.map((item) => (
                            <MenuItem value={item._id}>
                              <FirmCardDropDown item={item} />
                            </MenuItem>
                          ))}
                        </CustomSelectBox>
                        {compExpFormik.touched.fName &&
                          compExpFormik.errors.fName && (
                            <Typography sx={{ color: "red" }}>
                              {compExpFormik.errors.fName}
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Label>Purpose</Label>
                    <CustomTextField
                      fullWidth
                      size="small"
                      placeholder="Enter Purpose"
                      name="purpose"
                      value={compExpFormik.values.purpose}
                      onChange={compExpFormik.handleChange}
                    />
                    {compExpFormik.touched.purpose &&
                      compExpFormik.errors.purpose && (
                        <Typography sx={{ color: "red" }}>
                          {compExpFormik.errors.purpose}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Label>Expense Date</Label>
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      name="collectionDate"
                      value={compExpFormik.values.date}
                      onChange={(e) => {
                        compExpFormik.setValues((prev) => ({
                          ...prev,
                          date: e,
                        }));
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select"
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Label>Expended Amount</Label>
                    <CustomTextField
                      fullWidth
                      size="small"
                      placeholder="Enter Amount"
                      type="number"
                      value={compExpFormik.values.amt}
                      name="amt"
                      onChange={compExpFormik.handleChange}
                    />
                    {compExpFormik.touched.amt && compExpFormik.errors.amt && (
                      <Typography sx={{ color: "red" }}>
                        {compExpFormik.errors.amt}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Label>Attachment(if any)</Label>
                    <Box>
                      <img
                        src={
                          state?.image?.link
                            ? state?.image?.link
                            : "/image-placeholder.jpeg"
                        }
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        alt="ekalsutra"
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        component="label"
                      >
                        <input
                          type="file"
                          accept="image/*,application/pdf"
                          hidden
                          onChange={handleUpload}
                        />
                        Upload
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>

              <Stack mt={2}>
                <PaymentModeComponent
                  amount={compExpFormik.values.amt}
                  formId="comp-exp-pay"
                  onClickSubmit={(data) => {
                    compExpFormik.setValues((prev) => ({
                      ...prev,
                      paymentDetails: data,
                    }));
                    compExpFormik.handleSubmit();
                  }}
                />
              </Stack>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                p: "0 !important",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <form id="compExpForm" onSubmit={reqExpFormik.handleSubmit}>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={6} md={4}>
                    <Label>Expense Head</Label>
                    <CustomSelectBox
                      name="head"
                      value={reqExpFormik.values.head}
                      displayEmpty
                      fullWidth
                      MenuProps={MenuProps}
                      onChange={(e) => {
                        reqExpFormik.handleChange(e);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {reqExpFormik.values?.heads?.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </CustomSelectBox>
                    {reqExpFormik.touched.head && reqExpFormik.errors.head && (
                      <Typography sx={{ color: "red" }}>
                        {reqExpFormik.errors.head}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Label>Sub Head</Label>
                    <CustomSelectBox
                      name="subHead"
                      fullWidth
                      disabled={!reqExpFormik.values.head}
                      displayEmpty
                      value={reqExpFormik.values.subHead}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {reqExpFormik.values.subHeads?.map((item) => (
                        <MenuItem
                          value={item?.id}
                          onClick={() =>
                            reqExpFormik.setValues((prev) => ({
                              ...prev,
                              subHead: item.id,
                              description: item?.description,
                            }))
                          }
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                      <MenuItem
                        sx={{
                          color: "blue",
                          textDecoration: "underline",
                          fontStyle: "italic",
                        }}
                        value="Add New"
                        onClick={() => {
                          setOpenExpenseSubHeadDialog(true);
                          reqExpFormik.setValues((prev) => ({
                            ...prev,
                            subHead: "",
                          }));
                        }}
                      >
                        Add New
                      </MenuItem>
                    </CustomSelectBox>
                    {reqExpFormik.touched.subHead &&
                      reqExpFormik.errors.subHead && (
                        <Typography sx={{ color: "red" }}>
                          {reqExpFormik.errors.subHead}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Label>Vehicle Expense ?</Label>
                      <FormControlLabel
                        sx={{ mt: "-10px" }}
                        control={
                          <Checkbox
                            checked={reqExpFormik.values.isVehicle}
                            onChange={(e) =>
                              reqExpFormik.setValues((prev) => ({
                                ...prev,
                                isVehicle: !prev.isVehicle,
                              }))
                            }
                          />
                        }
                        label="Yes"
                      />
                    </Stack>
                    {reqExpFormik.values.isVehicle && (
                      <>
                        <CustomSelectBox
                          displayEmpty
                          name="vehicle"
                          fullWidth
                          value={reqExpFormik.values.vehicle}
                          MenuProps={MenuProps}
                          onChange={(e) => {
                            reqExpFormik.handleChange(e);
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Vehicle
                          </MenuItem>
                          {reqExpFormik.values.transportList?.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </CustomSelectBox>
                        {reqExpFormik.touched.vehicle &&
                          reqExpFormik.errors.vehicle && (
                            <Typography sx={{ color: "red" }}>
                              {reqExpFormik.errors.vehicle}
                            </Typography>
                          )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Label>Sub Head Description</Label>
                    <Typography>
                      {reqExpFormik.values.description || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Label>Amount</Label>
                    <CustomTextField
                      type="number"
                      name="amt"
                      fullWidth
                      placeholder="Enter Amount"
                      value={reqExpFormik.values.amt}
                      onChange={(e) => {
                        reqExpFormik.handleChange(e);
                      }}
                    />
                    {reqExpFormik.touched.amt && reqExpFormik.errors.amt && (
                      <Typography sx={{ color: "red" }}>
                        {reqExpFormik.errors.amt}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Label>Purpose</Label>
                    <CustomTextField
                      name="purpose"
                      fullWidth
                      placeholder="Enter Purpose"
                      value={reqExpFormik.values.purpose}
                      onChange={(e) => {
                        reqExpFormik.handleChange(e);
                      }}
                    />
                    {reqExpFormik.touched.purpose &&
                      reqExpFormik.errors.purpose && (
                        <Typography sx={{ color: "red" }}>
                          {reqExpFormik.errors.purpose}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </form>
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={
              value === "2"
                ? requestExpenseLoading
                : addCompletedExpenseDetailLoading
            }
            form={value === "2" ? "compExpForm" : "comp-exp-pay"}
            type="submit"
            variant={value === "2" ? "outlined" : "contained"}
            color={value === "2" ? "warning" : "secondary"}
            size="large"
          >
            {value === "2" ? "Send For Approval" : "Submit"}
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default ExpensesDialog;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 500,
  color: "#0C2F49",
}));
