import { LoadingButton } from "@mui/lab";
import {
  Container,
  Stack,
  Typography,
  styled,
  Select,
  MenuItem,
  Box,
  DialogContent,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  sessionMonths,
} from "app/modules/schools/selectors";
import {
  approveMonthlyPayroll,
  createAndViewMonthlyPayroll,
  deletePayroll,
  getPayrollPageData,
} from "app/services/hr";
import { FormatFullName, IndianCurrency, MenuProps } from "app/utils/helper";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { queryClient } from "App";
import CustomLoader from "app/components/common/CustomLoader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import appRoutes from "app/config/routes";
import { blue, green, orange, red } from "@mui/material/colors";
import CommonTable1 from "app/components/Tables/CommonTable1";
import dayjs from "dayjs";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import PaymentModeComponent2 from "app/components/common/PaymentModeComponent2";
import { paySalaryInPartPayment } from "app/services/schoolService/hr.service";
import TransactionDetailPayroll from "../TransactionDetailPayroll";
import BulkPrintDialog from "app/components/common/Dialogs/payroll/BulkPrintDialog";
import ExportDialog3 from "app/components/common/exportDialog/index3";
import PayrollFilter from "app/components/common/Dialogs/hr/payroll/PayrollFilter";
import { Helmet } from "react-helmet";

const PayrollPage = () => {
  const selectedSession = useSelector(selectActiveSession);
  const [includeEarningDeduction, setIncludeEarningDeduction] = useState(false);

  const [state, setState] = useState({
    data: [],
    year: "",
    month: "",
    payrollId: "",
    deletePayrollDocId: "",
    deleteDialog: false,
    deletePayrollDialog: false,
    monthPayrollData: {},
    summaryData: {},
    payDialog: false,
    payData: {},
    transactionDialog: {
      show: false,
      transactionArray: [],
    },
    falseState: false,
    allChecked: false,
    tableData: [],
    totalHeads: [],
    bulkPrintDialog: false,
    filterData: false,
    fetch: false,
  });
  const [falseState, setFalseState] = useState(false);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const handleOnclickFilter = (payload) => {
    setState((prev) => ({
      ...prev,
      filterData: payload,
      fetch: !prev.fetch,
      month: payload?.month,
      year: payload?.year,
    }));
  };
  const handleApproveAllPayroll = async () => {
    setLoading(true);
    await Promise.allSettled(
      state.tableData?.map((item) => {
        if (item?.checked) {
          return new Promise(async (resolve, reject) => {
            try {
              const d = await approveMonthlyPayroll(item?.payrollId);
              toast.success(d?.data?.message);
              resolve();
            } catch (error) {
              toast.error(error?.response?.data?.message);
              reject();
            }
          });
        }
        return null;
      })
    );
    setLoading(false);
    setState((prev) => ({ ...prev, allChecked: false }));
    getPayrollRefetch();
  };
  const handlePayFormSubmit = (data) => {
    const newData = {
      payrollDocId: state.payrollId,
      balanceAmount: data?.balanceAmount,
      transactionDate: data?.transactionDate,
      transactionList: data?.transactionList,
    };
    paySalaryInPartPaymentMutate(newData);
  };

  const Columns = [
    {
      header: "Sr.No",
      Cell: ({ cell: { row } }) => {
        return (
          <>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {row?.original?.status === "REQUESTED" && (
                <input
                  type="checkbox"
                  id="checkdiv"
                  checked={row?.original?.checked}
                  style={{ cursor: "pointer", width: "15px", height: "15px" }}
                  onChange={(e) => {
                    state.tableData[Number(row.id)]["checked"] =
                      !row?.original?.checked;
                    setFalseState(!falseState);
                  }}
                />
              )}
              <Typography>{row?.original?.srNo}</Typography>
            </Stack>
          </>
        );
      },

      Header: ({ column }) => (
        <Stack direction={"row"} alignItems={"center"}>
          <Typography>Sr.No</Typography>
          <input
            type="checkbox"
            checked={state.allChecked}
            style={{ cursor: "pointer", width: "15px", height: "15px" }}
            onChange={(e) =>
              setState((prev) => ({ ...prev, allChecked: !prev.allChecked }))
            }
          />
        </Stack>
      ),
      maxSize: 55,
    },
    {
      header: "Employee ID",
      accessorKey: "id",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            <Typography>{original?.id}</Typography>
          </>
        );
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Employee name",
      accessorKey: "name",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <LinkText
            to={`/${appRoutes.staffProfile}/${original?.staffDocId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {original?.name} &#8599;
          </LinkText>
        );
      },
      size: 70,
      Header: ({ column }) => (
        <div style={{ minWidth: "100px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Month-Year",
      accessorKey: "monthYear",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.monthYear}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Department",
      accessorKey: "dept",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.dept}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Designation",
      accessorKey: "desg",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.desg}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },

    {
      header: "Total Earning",
      accessorKey: "earning",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography> {original?.earning?.toFixed(2)}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Total Deduction",
      accessorKey: "deduction",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.deduction?.toFixed(2)}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Net Pay",
      accessorKey: "netPay",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.netPay.toFixed(2)}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Paid",
      accessorKey: "paidAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              setState((prev) => ({
                ...prev,
                transactionDialog: {
                  show: true,
                  transactionArray:
                    original?.data?.salaryDetail?.trnasactionArray,
                },
              }))
            }
          >
            {original?.paidAmount?.toFixed(2)}
          </Typography>
        );
      },
      size: 40,
    },
    {
      header: "Status",
      accessorKey: "status",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography
            sx={{
              color: ["PAID", "Paid"].includes(original?.status)
                ? green[800]
                : original?.status === "REJECTED"
                ? red[500]
                : original?.status === "APPROVED"
                ? green[400]
                : original?.status === "CREATED"
                ? orange[500]
                : original?.status === "Not Created"
                ? blue[500]
                : original?.status === "Partially Paid"
                ? green["A700"]
                : "#000",
              fontWeight: "bold",
            }}
          >
            {original?.status}
          </Typography>
        );
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ width: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },

    {
      header: "Actions",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            {[]}
            <Stack direction="column" gap={1} alignItems={"center"}>
              <Box>
                {["approved", "partially paid"].includes(
                  String(original?.status).toLocaleLowerCase()
                ) && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ width: "120px" }}
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        payData: {
                          ...original?.data,
                          ...{
                            ...original?.data?.salaryDetail,
                            // previouslyPaid:
                            //   original?.data?.salaryDetail?.paidAmount,
                          },
                        },
                        payDialog: true,
                        payrollId: original?.payrollId,
                      }));
                    }}
                  >
                    Pay
                  </Button>
                )}
              </Box>
              <Stack direction={"row"} gap={0.8}>
                {!["not created"].includes(
                  String(original?.status).toLocaleLowerCase()
                ) && (
                  <Link
                    to={`/hr/payroll/print-slip/${original?.data?.salaryDetail?._id}?month=${state.month}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconImage alt="edit" src="/view-icon.png" />
                  </Link>
                )}
                {["created", "requested"].includes(
                  String(original?.status).toLocaleLowerCase()
                ) && (
                  <Link
                    to={`${window.location.origin}/hr/payroll/create/${original?.data?.salaryDetail?._id}?month=${state.month}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconImage alt="edit" src="/edit-icon.png" />
                  </Link>
                )}
                {!["not created"].includes(
                  String(original?.status).toLocaleLowerCase()
                ) && (
                  <Box
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        deletePayrollDialog: true,
                        deletePayrollDocId: original?.payrollId,
                        deleteDialog: true,
                        payrollId: original?.payrollId,
                      }));
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <IconImage alt="edit" src="/delete-icon.png" />
                  </Box>
                )}
              </Stack>
            </Stack>

            {original?.status === "Not Created" && (
              <Typography
                sx={{ color: "blue", cursor: "pointer" }}
                onClick={() =>
                  createMonthlyPayrollMutate({
                    session: selectedSession,
                    staffDocId: original?.data?.staffDetail?._id,
                    month: original?.month,
                    year: original?.year,
                  })
                }
              >
                Create
              </Typography>
            )}
          </>
        );
      },
      size: 60,
    },
  ];

  let dynamicHeaders = state?.totalHeads?.map((head) => {
    let headTypeShort = head?.headType === "Earning" ? "Earn" : "Deduct";
    return {
      headName: `${headTypeShort} - ${head?.headName}`,
      config: `${headTypeShort} - ${head?.headName} - Config`,
      deduction: `${headTypeShort} - ${head?.headName} - Deduction`,
      amount: `${headTypeShort} - ${head?.headName} - Amount`,
      paymentMode: `${headTypeShort} - ${head?.headName} - Payment Mode`,
    };
  });

  // Define static headers
  let header = [
    "Sr No",
    "Employee Id",
    "Employee Name",
    "Father's Name",
    "DOB",
    "Joining Date",
    "Mobile Number",
    "Alternate Contact",
    "Gmail ID",
    "Gender",
    "PAN",
    "Address",
    "Department",
    "Designation",
    "Primary Role",
    "Bank Name",
    "Account Number",
    "IFSC CODE",
    "Holder Name",
    "Branch Name",
    "Gross Earning",
    "Gross Deduction",
    "Net Salary",
    "Paid",
    "Balance",
    "Neft Net Salary",
    "Neft Paid",
    "Neft Bal",
    "Cash Net Salary",
    "Cash Paid",
    "Cash Bal",
  ];

  // Map data to export array
  let exportArray = state.tableData?.map((item) => {
    let dynamicData = {};

    if (includeEarningDeduction) {
      state?.totalHeads?.forEach((head) => {
        let headPrefix = head?.headType === "Earning" ? "Earn" : "Deduct";
        let keyConfig = `${headPrefix} - ${head?.headName} - Config`;
        let keyDeduction = `${headPrefix} - ${head?.headName} - Deduction`;
        let keyAmount = `${headPrefix} - ${head?.headName} - Amount`;
        let keyPaymentMode = `${headPrefix} - ${head?.headName} - Payment Mode`;

        dynamicData[keyConfig] = "0";
        dynamicData[keyDeduction] = "0";
        dynamicData[keyAmount] = "0";
        dynamicData[keyPaymentMode] = "-";

        if (head?.headType === "Earning") {
          let earning = item?.data?.earnings?.find(
            (e) => e?.payrollHeadDocId === head?._id
          );
          if (earning) {
            dynamicData[keyConfig] = earning?.config || 0;
            dynamicData[keyDeduction] = earning?.deduction || 0;
            dynamicData[keyAmount] = earning?.amount || 0;
            dynamicData[keyPaymentMode] = earning?.paymentMode || "-";
          }
        } else if (head?.headType === "Deduction") {
          let deduction = item?.data?.deductions?.find(
            (d) => d?.payrollHeadDocId === head?._id
          );
          if (deduction) {
            dynamicData[keyConfig] = deduction?.config || 0;
            dynamicData[keyDeduction] = deduction?.deduction || 0;
            dynamicData[keyAmount] = deduction?.amount || 0;
            dynamicData[keyPaymentMode] = deduction?.paymentMode || "-";
          }
        }
      });
    }

    let result = {
      "Sr No": item?.srNo,
      "Employee Id": item?.id,
      "Employee Name": item?.name,
      "Father's Name": FormatFullName(
        item?.data?.staffDetail?.fatherInfo?.firstName,
        item?.data?.staffDetail?.fatherInfo?.firstName,
        item?.data?.staffDetail?.fatherInfo?.firstName
      ),
      DOB: item?.data?.staffDetail?.DOB
        ? dayjs(item?.data?.staffDetail?.DOB).format("DD-MM-YYYY")
        : "--",
      "Joining Date": item?.data?.joiningDate
        ? dayjs(item?.data?.joiningDate).format("DD-MM-YYYY")
        : "--",
      "Mobile Number": item?.data?.staffDetail?.mobileNumber,
      "Alternate Contact": item?.data?.staffDetail?.alternateNumber ?? "--",
      "Gmail ID": item?.data?.staffDetail?.email ?? "--",
      Gender: item?.data?.staffDetail?.gender,
      PAN: item?.data?.staffDetail?.PAN,
      Address: `${item?.data?.staffDetail?.currentAddress?.address}, ${item?.data?.staffDetail?.currentAddress?.dist}, ${item?.data?.staffDetail?.currentAddress?.state}`,
      Department: item?.data?.departmentName,
      Designation: item?.data?.designation,
      "Primary Role": item?.data?.primaryRole ?? "--",
      "Bank Name": item?.data?.staffDetail?.bankDetails?.bankName ?? "--",
      "Account Number":
        item?.data?.staffDetail?.bankDetails?.accountNumber ?? "--",
      "IFSC CODE": item?.data?.staffDetail?.bankDetails?.ifscCode ?? "--",
      "Holder Name": item?.data?.staffDetail?.bankDetails?.holderName ?? "--",
      "Branch Name": item?.data?.staffDetail?.bankDetails?.branch ?? "--",
      "Gross Earning": item?.data?.salaryDetail?.totalEarnings,
      "Gross Deduction": item?.data?.salaryDetail?.totalDeductions,
      "Net Salary": item?.data?.salaryDetail?.netPay,
      Paid: item?.data?.salaryDetail?.paidAmount,
      Balance: item?.data?.salaryDetail?.balanceAmount,
      // ------------- Payment Mode wise Salary --------------------------------
      "Neft Net Salary": item?.data?.salaryDetail?.netSal_pm?.t_NEFTMode,
      "Neft Paid": item?.data?.salaryDetail?.t_NEFTMode,
      "Neft Bal":
        Number(item?.data?.salaryDetail?.netSal_pm?.t_NEFTMode ?? 0) -
        Number(item?.data?.salaryDetail?.t_NEFTMode ?? 0),

      "Cash Net Salary": item?.data?.salaryDetail?.netSal_pm?.t_CashMode,
      "Cash Paid": item?.data?.salaryDetail?.t_CashMode,
      "Cash Bal":
        Number(item?.data?.salaryDetail?.netSal_pm?.t_CashMode ?? 0) -
        Number(item?.data?.salaryDetail?.t_CashMode ?? 0),

      ...(includeEarningDeduction ? dynamicData : {}),
    };

    return result;
  });

  const [openCheckBox, setOpenCheckBox] = useState(false);
  const navigate = useNavigate();
  const handleClosePayDialog = () => {
    setState((prev) => ({
      ...prev,
      payDialog: false,
      payData: {},
      payrollId: "",
    }));
  };

  const handleCloseDeletePayrollDialog = () => {
    setState((prev) => ({
      ...prev,
      deletePayrollDialog: false,
      deletePayrollDocId: "",
    }));
  };
  const {
    mutate: paySalaryInPartPaymentMutate,
    isLoading: paySalaryInPartPaymentLoading,
  } = useMutation(paySalaryInPartPayment, {
    onSuccess: (res) => {
      getPayrollRefetch();
      handleClosePayDialog();
      setState((prev) => ({ ...prev, pay: false }));
      toast.success(res?.data?.message);
      navigate(`/${appRoutes.payroll}?month=${searchParams.get("month")}`);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const {
    isLoading: getPayrollLoading,
    isFetching: getPayrollFetching,
    refetch: getPayrollRefetch,
  } = useQuery({
    queryKey: ["getPayrollData", state.fetch],
    queryFn: () => {
      if (state.filterData) {
        return getPayrollPageData({
          ...state.filterData,
        });
      } else {
        return getPayrollPageData({ month: state.month, year: state.year });
      }
    },
    onSuccess: (res) => {
      const d = res?.data?.list?.map((item, index) => ({
        srNo: index + 1 < 10 ? `0${index + 1}` : index + 1,
        checked: false,
        id: item?.employeeId || "--",
        name:
          `${item?.staffDetail?.firstName} ${item?.staffDetail?.middleName} ${item?.staffDetail?.lastName}` ||
          "--",
        monthYear: `${item?.month ?? "--"} ${item?.year ?? "--"}`,
        month: item?.month,
        year: item?.year,
        dept: item?.departmentName || "--",
        desg: item?.designation || "--",
        mobile: item?.staffDetail?.mobileNumber || "--",
        netPay: item?.salaryDetail?.netPay || 0,
        paidAmount: item?.salaryDetail?.paidAmount || 0,
        earning: item?.salaryDetail?.totalEarnings || 0,
        deduction: item?.salaryDetail?.totalDeductions || 0,
        status: item?.salaryDetail?.status || "--",
        data: item,
        staffDocId: item?.staffDetail?._id,
        payrollId: item?.salaryDetail?._id,
      }));
      setState((prev) => ({
        ...prev,
        tableData: d,
        summaryData: res?.data?.payrollSummary,
        totalHeads: res?.data?.totalHeads,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: state.month && state.year ? true : false,
  });

  const {
    mutate: createMonthlyPayrollMutate,
    isLoading: createMonthlyPayrollLoading,
  } = useMutation(createAndViewMonthlyPayroll, {
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, monthPayrollData: res?.data?.obj }));
      queryClient.invalidateQueries({ queryKey: "getPayrollData" });
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: deletePayrollMutate, isLoading: deletePayrollLoading } =
    useMutation(deletePayroll, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        handleCloseDeletePayrollDialog();
        queryClient.invalidateQueries({ queryKey: "getPayrollData" });
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      year: dayjs().format("YYYY"),
      month: searchParams.get("month")
        ? searchParams.get("month")
        : dayjs().format("MMMM"),
      filterData: {
        ...prev.filterData,
        year: dayjs().format("YYYY"),
        month: searchParams.get("month")
          ? searchParams.get("month")
          : dayjs().format("MMMM"),
      },
    }));
  }, []);
  useEffect(() => {
    if (state.allChecked) {
      let d = state.tableData?.map((item) => {
        if (item.status === "REQUESTED") {
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      });
      setState((prev) => ({
        ...prev,
        tableData: d,
      }));
    } else {
      let d = state.tableData?.map((item) => {
        if (item.status === "REQUESTED") {
          return { ...item, checked: false };
        } else {
          return { ...item, checked: false };
        }
      });
      setState((prev) => ({
        ...prev,
        tableData: d,
      }));
    }
  }, [state.allChecked]);

  return (
    <>
      <Helmet>
        <title>HR | Payroll</title>
      </Helmet>
      <CustomLoader
        show={
          getPayrollLoading || getPayrollFetching || createMonthlyPayrollLoading
        }
      />
      {/* payroll transaction detail */}
      <TransactionDetailPayroll
        open={state.transactionDialog.show}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            transactionDialog: { show: false, transactionArray: [] },
          }))
        }
        data={state.transactionDialog}
      />
      {/* bulk print dialog */}
      <BulkPrintDialog
        open={state?.bulkPrintDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, bulkPrintDialog: false }))
        }
        filterParams={{
          month: state.month,
          year: state.year,
        }}
      />

      <CommonDialog
        title="Pay Payroll"
        open={state.payDialog}
        onClose={handleClosePayDialog}
        minWidth="900px"
      >
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <PaymentModeComponent2
              onClickSubmit={handlePayFormSubmit}
              data={state?.payData}
              loading={paySalaryInPartPaymentLoading}
            />
          </Box>
        </DialogContent>
      </CommonDialog>
      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deletePayrollDialog}
        handleClose={handleCloseDeletePayrollDialog}
        handleDelete={() => {
          deletePayrollMutate(state.deletePayrollDocId);
        }}
        loading={deletePayrollLoading}
      />
      <ExportDialog3
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Payroll"
        extraSelection={true}
        extraSelectionHeaderName={"Earning and Deduction"}
        dynamicHeaders={dynamicHeaders}
        setIncludeEarningDeduction={setIncludeEarningDeduction}
      />
      <Container maxWidth="xl">
        {/* filter stack */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 2 }}
        >
          <PayrollFilter mutate={handleOnclickFilter} />
          {/* <Stack direction="row" gap={2} marginRight={2} sx={{ width: "15vw" }}>
            <CustomSelect
              MenuProps={MenuProps}
              value={state.year}
              displayEmpty
              onChange={(e) =>
                setState((prev) => ({ ...prev, year: e.target.value }))
              }
            >
              <MenuItem disabled value="">
                Select Year
              </MenuItem>
              {years.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </CustomSelect>

            <CustomSelect
              MenuProps={MenuProps}
              value={state.month}
              displayEmpty
              onChange={(e) =>
                setState((prev) => ({ ...prev, month: e.target.value }))
              }
            >
              <MenuItem disabled value="">
                Select Month
              </MenuItem>
              {sessionMonthList.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </CustomSelect>
          </Stack> */}

          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Stack>
        {/* summary stack */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <FeeBox>
            <Stack direction="row" alignItems={"center"} gap={2}>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Total Amount
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(state?.summaryData?.totalAmount)}
                </Typography>
              </Box>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Requested
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(state?.summaryData?.REQUESTED)}
                </Typography>
              </Box>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Approved
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(state?.summaryData?.APPROVED)}
                </Typography>
              </Box>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Paid
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(state?.summaryData?.PAID)}
                </Typography>
              </Box>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Not Created
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(state?.summaryData?.Not_Created)}
                </Typography>
              </Box>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Rejected
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(state?.summaryData?.REJECTED)}
                </Typography>
              </Box>
            </Stack>
          </FeeBox>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              setState((prev) => ({ ...prev, bulkPrintDialog: true }))
            }
            sx={{ textTransform: "capitalize", fontSize: "14px" }}
          >
            Print For Bulk
          </Button>
        </Stack>
        {/* table */}
        <Stack py={1}>
          <CommonTable1
            columns={Columns}
            data={state.tableData || []}
            maxHeight="50vh"
          />
        </Stack>

        {state.tableData.some((item) => item.checked) && (
          <Stack direction="row" justifyContent={"flex-end"}>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={handleApproveAllPayroll}
              loading={loading}
            >
              Approve
            </LoadingButton>
          </Stack>
        )}
      </Container>
    </>
  );
};

export default PayrollPage;
const LinkText = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: blue;
  }
`;
const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 10px;
  display: inline-block;
  margin-top: 10px;
`;
const IconImage = styled("img")`
  height: 28px;
  width: 28px;
  object-fit: contain;
`;
