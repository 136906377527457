import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Box, DialogContent, Tab } from "@mui/material";
import React, { useState } from "react";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import ViewGroupedFeeStructureAcademic from "./ViewGroupedFeeStructureAcademic";
import ViewGroupedFeeStructureSubject from "./ViewGroupedFeeStructureSubject";

const ViewGroupedFeeStructure = ({
  open,
  handleClose,
  refetch,
  classDocId,
}) => {
  const [value, setValue] = useState("1");

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="90%"
        title="Fee Structure View"
      >
        <TabContext value={value}>
          <Box sx={{ px: 4 }}>
            <TabsWrapper>
              <TabList
                onChange={(e, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Academic Fee" value="1" />
                <Tab label="Subject Fee" value="2" />
              </TabList>
            </TabsWrapper>
          </Box>
          <DialogContent sx={{ pt: "0 !important" }}>
            <TabPanel value="1" sx={{ p: "0 !important" }}>
              <ViewGroupedFeeStructureAcademic
                refetch={refetch}
                classDocId={classDocId}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ p: "0 !important" }}>
              <ViewGroupedFeeStructureSubject
                refetch={refetch}
                classDocId={classDocId}
              />
            </TabPanel>
          </DialogContent>
        </TabContext>
      </CommonDialog>
    </>
  );
};

export default ViewGroupedFeeStructure;
