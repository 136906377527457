import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonFilterDialog from "../../CommonFilterDialog";
import { FilterAlt } from "@mui/icons-material";
import { MenuProps } from "app/utils/helper";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectedMediumSelector,
  sessionMonths,
} from "app/modules/schools/selectors";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { useQuery } from "react-query";
import { getAllDepartmentLogic } from "app/services/reception";
import { toast } from "react-toastify";
import { getDesignationDropdown } from "app/services/schoolService/hr.service";
import dayjs from "dayjs";

const years = [
  1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 1951, 1955,
  1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978,
  1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991,
  1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004,
  2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017,
  2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040,
];

const PayrollFilter = ({ mutate = () => {} }) => {
  const sessionMonthList = useSelector(sessionMonths);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [state, setState] = useState({
    dialog: false,
    year: "",
    month: "",
    departmentMultiple: [],
    departmentList: [],
    finalPrimaryRole: [],
  });
  const [checkboxes, setCheckboxes] = useState({
    monthYear: true,
    departmentMultiple: false,
    designationMultiple: false,
  });
  const handleCloseDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleSave = () => {
    handleCloseDialog();
    mutate({
      ...(checkboxes.monthYear && {
        month: state?.month,
        year: state?.year,
      }),
      ...(checkboxes.departmentMultiple && {
        departmentList: JSON.stringify(state.departmentMultiple),
      }),
      ...(checkboxes.designationMultiple && {
        designationList: JSON.stringify(
          state.finalPrimaryRole
            .filter((role) => role.checked)
            .map((role) => role.designation)
        ),
      }),
    });
  };

  const { data: departmentData } = useQuery({
    queryKey: ["getDepartmentInfo", selectedSession, selectedMedium],
    queryFn: () => getAllDepartmentLogic({ selectedSession, selectedMedium }),
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: true,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      year: dayjs().format("YYYY"),
      month: dayjs().format("MMMM"),
    }));
  }, []);

  useEffect(() => {
    if (departmentData?.data?.list) {
      const updatedDepartmentList = departmentData.data.list.map((dept) => ({
        ...dept,
        checked: false,
      }));

      const selectedDepartments = updatedDepartmentList
        .filter((dept) => dept?.checked)
        .map((dept) => dept?._id);

      setState((prev) => ({
        ...prev,
        departmentList: updatedDepartmentList,
        departmentMultiple: selectedDepartments,
      }));
    }
  }, [departmentData]);

  useQuery({
    queryKey: ["getDesignationDropdown"],
    queryFn: () => getDesignationDropdown(),
    onSuccess: (data) => {
      const newPrimaryRoles = data?.data?.list?.map((role) => ({
        designation: role,
        checked: false,
      }));
      setState((prev) => ({ ...prev, finalPrimaryRole: newPrimaryRoles }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: true,
  });

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          setState((prev) => ({ ...prev, dialog: true }));
        }}
        color="secondary"
        sx={{
          width: "20rem",
          height: "4rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          borderRadius: "10px",
          background: "#0099ff",
          fontSize: "1.2rem",
        }}
      >
        Filter
        <FilterAlt />
        <Typography
          sx={{
            padding: "0 5px",
            background: "#fff",
            color: "black",
            borderRadius: "50%",
          }}
        >
          {Object.values(checkboxes).filter((item) => item === true).length}
        </Typography>
      </Button>
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseDialog}
        minWidth="400px"
        title="Filter Payroll"
        handleSaveAndView={handleSave}
      >
        <DialogContent>
          {/* Payroll Month & year */}
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.monthYear}
                  color="secondary"
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      monthYear: !prev.monthYear,
                    }));
                  }}
                />
              }
              label="Payroll Month & year"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.monthYear && (
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography>Year</Typography>
                  <CustomSelect
                    MenuProps={MenuProps}
                    value={state.year}
                    displayEmpty
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, year: e.target.value }))
                    }
                  >
                    <MenuItem disabled value="">
                      Select Year
                    </MenuItem>
                    {years.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </CustomSelect>
                </Stack>
                <Stack>
                  <Typography>Month</Typography>
                  <CustomSelect
                    MenuProps={MenuProps}
                    value={state.month}
                    displayEmpty
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, month: e.target.value }))
                    }
                  >
                    <MenuItem disabled value="">
                      Select Month
                    </MenuItem>
                    {sessionMonthList.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </CustomSelect>
                </Stack>
              </Stack>
            )}
          </Stack>

          {/* Multiple Department Dropdown  */}
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.departmentMultiple}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  departmentMultiple: !prev.departmentMultiple,
                }))
              }
              label="Department"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.departmentMultiple && (
              <Stack flex={1}>
                <TextFieldLabel title="Select Departments" />
                <CustomSelectBox
                  MenuProps={MenuProps}
                  multiple
                  displayEmpty
                  size="small"
                  fullWidth
                  value={state.departmentMultiple}
                  renderValue={() => {
                    const selectedDepartments = state.departmentList.filter(
                      (dept) => dept.checked
                    );
                    if (selectedDepartments.length > 0) {
                      return (
                        <Typography sx={{ fontWeight: 500 }}>
                          {selectedDepartments
                            .map((dept) => dept.departmentName)
                            .join(", ")}
                        </Typography>
                      );
                    } else {
                      return <Typography>Select</Typography>;
                    }
                  }}
                >
                  {state.departmentList.map((dept, i) => (
                    <Box key={i} sx={{ px: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            checked={dept.checked}
                            onChange={() => {
                              const newDepartmentList =
                                state.departmentList.map((d) =>
                                  d._id === dept._id
                                    ? { ...d, checked: !d.checked }
                                    : d
                                );
                              setState((prev) => ({
                                ...prev,
                                departmentList: newDepartmentList,
                                departmentMultiple: newDepartmentList
                                  .filter((d) => d.checked)
                                  .map((d) => d._id),
                              }));
                            }}
                          />
                        }
                        label={dept.departmentName}
                        componentsProps={{
                          typography: {
                            fontSize: "14px",
                            fontWeight: 600,
                          },
                        }}
                      />
                    </Box>
                  ))}
                </CustomSelectBox>
              </Stack>
            )}
          </Box>

          {/* Multiple Designation Dropdown  */}

          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.designationMultiple}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  designationMultiple: !prev.designationMultiple,
                }))
              }
              label="Designation"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.designationMultiple && (
              <Box>
                <Stack flex={1}>
                  <TextFieldLabel title="Select Designations" />
                  <CustomSelectBox
                    MenuProps={MenuProps}
                    multiple
                    displayEmpty
                    size="small"
                    fullWidth
                    // value={state.finalPrimaryRole
                    //   .filter((role) => role.checked)
                    //   .map((role) => role.designation)}
                    value={(state?.finalPrimaryRole || [])
                      ?.filter((role) => role?.checked)
                      ?.map((role) => role?.designation)}
                    renderValue={() => {
                      const selectedRoles = state?.finalPrimaryRole?.filter(
                        (role) => role?.checked
                      );
                      if (selectedRoles?.length > 0) {
                        return (
                          <Typography sx={{ fontWeight: 500 }}>
                            {selectedRoles
                              ?.map((role) => role?.designation)
                              .join(", ")}
                          </Typography>
                        );
                      } else {
                        return <Typography>Select</Typography>;
                      }
                    }}
                  >
                    {state?.finalPrimaryRole?.map((role, i) => (
                      <Box key={i} sx={{ px: 1 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              checked={role?.checked}
                              onChange={() => {
                                const newFinalPrimaryRole =
                                  state?.finalPrimaryRole?.map((r) =>
                                    r?.designation === role?.designation
                                      ? { ...r, checked: !r.checked }
                                      : r
                                  );
                                setState((prev) => ({
                                  ...prev,
                                  finalPrimaryRole: newFinalPrimaryRole,
                                }));
                              }}
                            />
                          }
                          label={role?.designation}
                          componentsProps={{
                            typography: {
                              fontSize: "14px",
                              fontWeight: 600,
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </CustomSelectBox>
                </Stack>
              </Box>
            )}
          </Box>
        </DialogContent>
      </CommonFilterDialog>
    </div>
  );
};

export default PayrollFilter;

const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  minWidth: "15rem",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));
