import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;
let payroll_url = `${SCHOOL_BASE}/payroll`;
const hr_url = `${SCHOOL_BASE}/hr`;

export const getPayrollSecurity = (params) => {
  return schoolTokenAxios.get(`${payroll_url}/getPayrollSecurity`, {
    params: params,
  });
};
export const addPayrollSecurity = (body) => {
  return schoolTokenAxios.post(`${payroll_url}/addPayrollSecurity`, body);
};

export const addLeaveByAdmin = (body) => {
  return schoolTokenAxios.put(`${hr_url}/addLeaveByAdmin`, body);
};

export const updatePayrollAttendance = (body) => {
  return schoolTokenAxios.put(`${payroll_url}/updatePayrollAttendance`, body);
};
export const getDesignationDropdown = (params) => {
  return schoolTokenAxios.get(`${hr_url}/getDesignationDropdown`, {
    params: params,
  });
};
export const getStaffDebtTransactions = (params) => {
  return schoolTokenAxios.get(`${payroll_url}/getStaffDebtTransactions`, {
    params: params,
  });
};
export const addDebtTransaction = (body) => {
  return schoolTokenAxios.post(`${payroll_url}/addDebtTransaction`, body);
};
export const deleteStaffDebt = (id) => {
  return schoolTokenAxios.delete(`${payroll_url}/deleteStaffDebt/${id}`);
};

export const getStaffCalculatedDebt = (params) => {
  return schoolTokenAxios.get(`${payroll_url}/getStaffCalculatedDebt`, {
    params: params,
  });
};
export const paySalaryInPartPayment = (body) => {
  return schoolTokenAxios.put(`${payroll_url}/paySalaryInPartPayment`, body);
};
export const getPartPaymentSalary = (params) => {
  return schoolTokenAxios.get(`${payroll_url}/getPartPaymentSalary`, {
    params,
  });
};
//  payroll head in employement
export const addEmpPayrollHead = (body) => {
  return schoolTokenAxios.post(`${payroll_url}/addEmpPayrollHead`, body);
};
export const updateEmpPayrollHead = (body) => {
  return schoolTokenAxios.put(`${payroll_url}/updateEmpPayrollHead`, body);
};
export const getAllEmpPayrollHead = (params) => {
  return schoolTokenAxios.get(`${payroll_url}/getAllEmpPayrollHead`, {
    params,
  });
};
export const deleteEmpPayrollHead = (id) => {
  return schoolTokenAxios.delete(`${payroll_url}/deleteEmpPayrollHead/${id}`);
};
//  create payroll
export const addPayrollTransaction = (body) => {
  return schoolTokenAxios.post(`${payroll_url}/addPayrollTransaction`, body);
};
export const updatePayrollTransaction = (body) => {
  return schoolTokenAxios.put(`${payroll_url}/updatePayrollTransaction`, body);
};
export const getPayrollTransaction = (params) => {
  return schoolTokenAxios.get(`${payroll_url}/getPayrollTransaction`, {
    params,
  });
};
export const deletePayrollTransaction = (id) => {
  return schoolTokenAxios.delete(
    `${payroll_url}/deletePayrollTransaction/${id}`
  );
};

export const updateEmployeeId = (body) => {
  return schoolTokenAxios.put(`${hr_url}/updateEmployeeId`, body);
};
