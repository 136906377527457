import Fallback from "app/components/Fallback";
import React from "react";

const Visuals = React.lazy(() =>
  import("app/modules/admissionEnquiry/pages/VisualsPage")
);

const Enquiry = React.lazy(() =>
  import("app/modules/admissionEnquiry/pages/EnquiryPage")
);

const FormFeeStructure = React.lazy(() =>
  import("app/modules/admissionEnquiry/pages/FormFeeStructure")
);

const Counselor = React.lazy(() =>
  import("app/modules/admissionEnquiry/pages/CounselorPage")
);

const PreviousSchool = React.lazy(() =>
  import("app/modules/admissionEnquiry/pages/PreviousSchoolPage")
);

const CouselorProfile = React.lazy(() =>
  import("app/modules/admissionEnquiry/pages/CouselorProfilePage")
);

export const VisualsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Visuals {...props} />
  </React.Suspense>
);

export const EnquiryPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Enquiry {...props} />
  </React.Suspense>
);

export const FormFeeStructurePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <FormFeeStructure {...props} />
  </React.Suspense>
);

export const CounselorPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Counselor {...props} />
  </React.Suspense>
);

export const PreviousSchoolPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <PreviousSchool {...props} />
  </React.Suspense>
);

export const CouselorProfilePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <CouselorProfile {...props} />
  </React.Suspense>
);
