import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  Container,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { getStudentsListLogic } from "app/services/student-management";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import {
  selectedMediumSelector,
  selectActiveSession,
  selectSelectedSchoolId,
} from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import CommonTableHorizontalScrollable from "app/components/Tables/CommonTableHorizontalScrollable";
import TableProfilePic from "app/components/common/TableProfilePic";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { bulkUpdateStudentAcademicInfo } from "app/services/schoolService/studentProfile";
import { MenuProps } from "app/utils/helper";
import { getClassDropdownLogic } from "app/services/reception";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import CustomLoader from "app/components/common/CustomLoader";
import StudentInfoChangeConfimationDialog from "../StudentReport/StudentInfoChangeConfimationDialog";
import YouTubeFloating from "app/components/common/floatingButton/YouTubeFloating";
import { StudentManagementBulkEditlinks } from "app/utils/youTubeLink";

const BulkUpdate = () => {
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const [rollNumbers, setRollNumbers] = useState([]);
  const [state, setState] = useState({
    studentList: [],
    editBulk: false,
    warningDialog: false,
    classDocId: "",
    sectionList: [],
    section: "",
  });

  const {
    data: classData,
    isLoading: getClassDataLoading,
    isFetching: getClassDataFetching,
  } = useQuery({
    queryKey: ["getClassInfo", selectedSession, selectedMedium],
    queryFn: () =>
      getClassDropdownLogic({ schoolDocId, selectedMedium, selectedSession }),
  });

  useEffect(() => {
    if (state.classDocId) {
      const filteredData = classData?.data?.list?.find(
        (item) => item?.classDocId === state?.classDocId
      );
      setState((prev) => ({
        ...prev,
        sectionList: filteredData?.sections,
      }));
    }
  }, [state.classDocId]);

  const columns = useMemo(() => {
    return [
      {
        header: "#",
        accessorKey: "#",
        Cell: ({ cell }) => (
          <Typography>{parseInt(cell.row.id) + 1}</Typography>
        ),
      },
      {
        header: "Roll No",
        Header: () => {
          return (
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography sx={{ fontSize: "15px" }}>Roll No.</Typography>
              {state.editBulk && (
                <>
                  <DoneIcon
                    sx={{
                      cursor: "pointer",
                      color: "success",
                      backgroundColor: "white",
                      border: "1px solid #B6C8D6",
                      borderRadius: "10px",
                      fontSize: "20px",
                      p: 0.1,
                    }}
                    onClick={waringDialogFunction}
                  />
                  <CloseIcon
                    onClick={handleCloseCancelEdit}
                    sx={{
                      cursor: "pointer",
                      color: "red",
                      backgroundColor: "white",
                      border: "1px solid #B6C8D6",
                      borderRadius: "10px",
                      fontSize: "20px",
                      p: 0.1,
                    }}
                  />
                </>
              )}
              {!state.editBulk && (
                <Button
                  onClick={() =>
                    setState((prev) => ({ ...prev, editBulk: true }))
                  }
                >
                  <img
                    alt="edit"
                    src="/edit-icon.png"
                    className="action_icon"
                    style={{ height: "20px", width: "20px" }}
                  />
                </Button>
              )}
            </Stack>
          );
        },

        Cell: ({ cell: { row } }) => {
          const studentId = row.original._id;
          const rollIndex = rollNumbers.findIndex(
            (item) => item.studentDocId === studentId
          );

          return state.editBulk ? (
            <CustomTextField
              placeholder="Roll No"
              size="small"
              type="number"
              value={
                rollIndex !== -1
                  ? rollNumbers[rollIndex].rollNumber
                  : row.original.rollNumber || ""
              }
              onChange={(e) => {
                const updatedRollNumbers = [...rollNumbers];
                if (rollIndex !== -1) {
                  // Update existing roll number
                  updatedRollNumbers[rollIndex].rollNumber = e.target.value;
                } else {
                  // Add new roll number entry
                  updatedRollNumbers.push({
                    studentDocId: studentId,
                    rollNumber: e.target.value,
                  });
                }
                setRollNumbers(updatedRollNumbers);
              }}
              sx={{ maxWidth: "100px" }}
            />
          ) : (
            <Typography sx={{ fontWeight: 600 }}>
              {row.original.rollNumber || "--"}
            </Typography>
          );
        },
      },
      {
        header: "Identity",
        accessorKey: "admissionNumber",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Box>
              <div title="SR Number">
                <Typography>SR No : {original?.srNumber ?? "--"}</Typography>
              </div>
              <div title="Admission Number">
                <Typography sx={{ color: "#73E11C" }}>
                  Adm No : {original?.admissionNumber ?? "--"}
                </Typography>
              </div>
              <div title="Reg. Number">
                <Typography sx={{ color: "#2789FD" }}>
                  Reg No : {original?.registrationNumber ?? "--"}
                </Typography>
              </div>
              <div title="PEN Number">
                <Typography>
                  PEN No : {original?.penNumber_center ?? "--"}
                </Typography>
              </div>
            </Box>
          );
        },
      },

      {
        header: "Student Name",
        accessorKey: "firstName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <>
              <Stack direction="row" alignItems="center" gap={0.6}>
                <Button>
                  <TableProfilePic image={original?.profilePic} />
                </Button>
                <Link
                  to={`${appRoutes.student_profile}/${original?._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>
                    {original?.firstName} {original?.middleName}{" "}
                    {original?.lastName}
                  </Typography>
                </Link>
                {original?.appNotificationToken && (
                  <Tooltip title="This Student have downloaded Ekalsutra App">
                    <InstallMobileIcon />
                  </Tooltip>
                )}
              </Stack>
            </>
          );
        },
      },
      {
        header: "Father Name",
        accessorKey: "fatherInfo.firstName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>
              {original?.fatherInfo?.firstName &&
                original?.fatherInfo?.firstName}{" "}
              {original?.fatherInfo?.middleName &&
                String(original?.fatherInfo?.middleName).charAt(0)}{" "}
              {original?.fatherInfo?.lastName && original?.fatherInfo?.lastName}
            </Typography>
          );
        },
      },
      {
        header: "Mother Name",
        accessorKey: "motherInfo.firstName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>
              {original?.motherInfo?.firstName &&
                original?.motherInfo?.firstName}{" "}
              {original?.motherInfo?.middleName &&
                String(original?.motherInfo?.middleName).charAt(0)}{" "}
              {original?.motherInfo?.lastName && original?.fatherInfo?.lastName}
            </Typography>
          );
        },
      },
      {
        header: "Gender",
        accessorKey: "gender",
        enableColumnOrdering: true,
      },
      {
        header: "Mobile Number",
        accessorKey: "mobileNumber",
        enableColumnOrdering: true,
      },
    ];
  }, [rollNumbers, state.editBulk]);

  const {
    mutate: bulkUpdateStudentAcademicInfoMutate,
    isLoading: bulkUpdateStudentAcademicInfoLoading,
  } = useMutation(
    "bulkUpdateStudentAcademicInfo",
    bulkUpdateStudentAcademicInfo,
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
        setRollNumbers([]);
        studentFetching();
        setState((prev) => ({ ...prev, editBulk: false }));
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    }
  );

  const waringDialogFunction = () => {
    setState((prev) => ({ ...prev, warningDialog: true }));
  };
  const handleCheckDoneAllMarks = () => {
    const body = {
      session: selectedSession,
      classDocId: state.classDocId,
      section: state.section,
      studentList: rollNumbers,
    };

    bulkUpdateStudentAcademicInfoMutate(body);

    setState((prev) => ({ ...prev, warningDialog: false }));
  };

  const handleCloseCancelEdit = () => {
    setRollNumbers([]); // Reset roll numbers
    setState((prev) => ({ ...prev, editBulk: false })); // Exit bulk edit mode
  };

  const {
    isLoading: studentListLoading,
    isRefetching: studentListRefetching,
    refetch: studentFetching,
    isFetching,
  } = useQuery({
    queryKey: ["getStudents1", selectedSession],
    queryFn: () => {
      let body = {
        medium: selectedMedium,
        ...(state.classDocId && { classDocId: state.classDocId }),
        ...(state.section && { section: state.section }),
      };

      if (body.section === "ALL") {
        delete body.section;
      }

      return getStudentsListLogic({ ...body, session: selectedSession });
    },
    enabled: false,
    onSuccess: (data) => {
      setState((prev) => ({ ...prev, studentList: data?.data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  return (
    <>
      <Helmet>
        <title>Student | Bulk Edit</title>
      </Helmet>
      <CustomLoader
        show={
          getClassDataLoading ||
          getClassDataFetching ||
          studentListLoading ||
          studentListRefetching
        }
      />

      <StudentInfoChangeConfimationDialog
        open={state.warningDialog}
        handleClose={() =>
          setState((prev) => ({ ...prev, warningDialog: false }))
        }
        text={
          "Duplicate roll numbers will not be checked during the update process. Are you sure you want to update the roll numbers?"
        }
        handleSubmit={() => handleCheckDoneAllMarks()}
        infoId={state.infoId}
        loading={bulkUpdateStudentAcademicInfoLoading}
      />

      <YouTubeFloating videoLink={StudentManagementBulkEditlinks} />

      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 1.5 }}
        >
          <Stack direction={"row"} alignItems="flex-end" gap={1}>
            <Stack>
              <TextFieldLabel title={"Class-Stream"} />
              <CustomSelectBox
                required
                displayEmpty
                size="small"
                value={state.classDocId}
                name="classDocId"
                fullWidth
                MenuProps={MenuProps}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {classData?.data?.list?.map((item, idx) => (
                  <MenuItem
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        classDocId: item.classDocId,
                        section: "",
                        sectionList: item?.sections,
                      }));
                    }}
                    key={item.classDocId}
                    value={item.classDocId}
                  >
                    {item.className_stream}
                  </MenuItem>
                ))}
              </CustomSelectBox>
            </Stack>
            <Stack>
              <TextFieldLabel title={"Section"} />
              <CustomSelectBox
                required
                displayEmpty
                size="small"
                value={state.section}
                name="section"
                fullWidth
                MenuProps={MenuProps}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {state?.sectionList?.map((item, idx) => (
                  <MenuItem
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        section: item,
                      }))
                    }
                    key={item}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </CustomSelectBox>
            </Stack>

            <LoadingButton
              loading={studentListLoading || isFetching}
              color="secondary"
              variant="contained"
              size="large"
              onClick={() => studentFetching()}
              sx={{ mb: 0.5, textTransform: "capitalize" }}
            >
              Search
            </LoadingButton>
          </Stack>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Total Student :{" "}
            <span style={{ fontSize: "18px" }}>
              {state.studentList?.length}
            </span>
          </Typography>
        </Stack>

        <Box sx={{ mt: 1 }}>
          <CommonTableHorizontalScrollable
            isFetching={isFetching || studentListLoading}
            data={state?.studentList}
            columns={columns}
            emptyMessage={"Students"}
          />
        </Box>
      </Container>
    </>
  );
};

export default BulkUpdate;
