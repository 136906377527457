import Fallback from "app/components/Fallback";
import React from "react";

const AuthorizedPerson = React.lazy(() =>
  import("app/modules/accessControl/pages/authorizedPerson/AuthorizedPerson")
);

const Permission = React.lazy(() =>
  import("app/modules/accessControl/pages/permission/PermissionPage")
);
const SessionOrders = React.lazy(() =>
  import("app/modules/accessControl/pages/sessionOrders/SessionOrders")
);

export const PermissionPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Permission {...props} />
  </React.Suspense>
);
export const AuthorizedPersonPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <AuthorizedPerson {...props} />
  </React.Suspense>
);

export const SessionOrdersPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SessionOrders {...props} />
  </React.Suspense>
);
