import { Box, Stack, Typography, styled, Button, colors } from "@mui/material";

import React, { useState } from "react";
import { IndianCurrency } from "app/utils/helper";
import CustomLoader from "app/components/common/CustomLoader";
import CommonTable1 from "app/components/Tables/CommonTable1";
import AddNewDebtToStaffFreshDialog from "app/components/common/Dialogs/hr/payroll/AddNewDebtToStaffFreshDialog";
import { getStaffDebtTransactions } from "app/services/schoolService/hr.service";
import { Add } from "@mui/icons-material";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import DebtTransactionDetailDialog from "app/components/common/Dialogs/hr/payroll/DebtTransactionDetail";
import AdvanceSalaryFilterDilog from "app/components/common/Dialogs/hr/payroll/AdvanceSalaryFilterDilog";

const DebtToStaff = () => {
  const [state, setState] = useState({
    addNewFreshDialog: false,
    staffData: {},
    transactionDetailDialog: "",
    filterData: null,
  });
  const handleCloseAddNewFresh = () => {
    setState((prev) => ({ ...prev, addNewFreshDialog: false, staffData: {} }));
  };
  const handleFilter = (filterData) => {
    setState((prev) => ({ ...prev, filterData }));
  };
  const Columns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell.row.id) < 9
            ? `0${parseInt(cell.row.id) + 1}`
            : parseInt(cell.row.id) + 1}
        </Typography>
      ),
      size: 20,
    },
    {
      header: "Employee ID",
      accessorKey: "employeeId",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            <Typography>{original?.employeeId}</Typography>
          </>
        );
      },
      size: 30,
    },
    {
      header: "Employee name",
      accessorKey: "staffName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.staffName}</Typography>;
      },
      size: 40,
    },
    {
      header: "Department",
      accessorKey: "departmentName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.departmentName}</Typography>;
      },
      size: 20,
    },
    {
      header: "Withdrawl",
      accessorKey: "withdrawalAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography
            sx={{
              color: colors.red[500],
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              setState((prev) => ({
                ...prev,
                transactionDetailDialog: original?._id?.staffDocId,
              }))
            }
          >
            {IndianCurrency(Math.abs(original?.withdrawalAmount))}
          </Typography>
        );
      },
      size: 30,
    },

    {
      header: "Deposited",
      accessorKey: "depositAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography sx={{ color: colors.green[500] }}>
            {IndianCurrency(Math.abs(original?.depositAmount))}
          </Typography>
        );
      },
      size: 30,
    },
    {
      header: "Balance",
      accessorKey: "amount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography sx={{ color: colors.blue[500] }}>
            {IndianCurrency(
              Number(original?.depositAmount) +
                Number(original?.withdrawalAmount)
            )}
          </Typography>
        );
      },
      size: 30,
    },

    {
      header: "Actions",
      Cell: ({ cell }) => {
        return (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            startIcon={<Add />}
            onClick={() =>
              setState((prev) => ({
                ...prev,
                addNewFreshDialog: true,
                staffData: cell?.row?.original,
              }))
            }
          >
            Add New
          </Button>
        );
      },
      size: 20,
    },
  ];

  const {
    isFetching: getStaffDebtTransactionsFetching,
    isLoading: getStaffDebtTransactionsLoding,
    data: getStaffDebtTransactionsData,
    refetch: getStaffDebtTransactionsRefetch,
  } = useQuery({
    queryKey: ["getStaffDebtTransactions", state.filterData],
    queryFn: () =>
      getStaffDebtTransactions({
        groupedByStaff: true,
        ...(state.filterData && state.filterData),
      }),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <CustomLoader
        show={
          getStaffDebtTransactionsFetching || getStaffDebtTransactionsLoding
        }
      />

      {/* add advance salary dialog */}

      <AddNewDebtToStaffFreshDialog
        onClose={handleCloseAddNewFresh}
        open={state.addNewFreshDialog}
        refetch={getStaffDebtTransactionsRefetch}
        staffDetail={state.staffData}
      />
      <DebtTransactionDetailDialog
        onClose={() =>
          setState((prev) => ({ ...prev, transactionDetailDialog: "" }))
        }
        open={state.transactionDetailDialog ? true : false}
        id={state.transactionDetailDialog}
        refetch={getStaffDebtTransactionsRefetch}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <AdvanceSalaryFilterDilog mutate={handleFilter} />
          <FeeBox>
            <Stack direction="row" alignItems={"center"} gap={2}>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Total Amount
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(
                    getStaffDebtTransactionsData?.data?.summary
                      ?.t_withdrawalAmount
                  )}
                </Typography>
              </Box>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Adjusted
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(
                    getStaffDebtTransactionsData?.data?.summary?.t_depositAmount
                  )}
                </Typography>
              </Box>
              <Box sx={{ mr: "1rem" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Balance
                </Typography>
                <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                  {IndianCurrency(
                    getStaffDebtTransactionsData?.data?.summary?.t_balance
                  )}
                </Typography>
              </Box>
            </Stack>
          </FeeBox>
        </Stack>
        <Stack direction={"row"} gap={2}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              width: "20rem",
              height: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              borderRadius: "10px",
              background: "#0099ff",
              fontSize: "1.2rem",
            }}
            onClick={() =>
              setState((prev) => ({ ...prev, addNewFreshDialog: true }))
            }
          >
            Request New
          </Button>
        </Stack>
      </Stack>
      <Stack py={1}>
        <CommonTable1
          columns={Columns}
          data={getStaffDebtTransactionsData?.data?.list || []}
        />
      </Stack>
    </>
  );
};

export default DebtToStaff;

const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 10px;
  display: inline-block;
  margin-top: 10px;
`;
const CustomGrid = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  color: #0c2f49;
  .grid-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 5px;
  }
  .grid-item {
    padding: 5px;
    font-size: 30px;
    text-align: left;
  }

  .sub {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  .content {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
`;
