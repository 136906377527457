import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import DialogHeader from "app/components/common/DialogHeader";
import {
  // isCollege,
  // isSchool,
  // selectActiveSemester,
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextFieldDisabled,
  TabsWrapper,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { useMutation, useQuery } from "react-query";
import { getClassDropdownLogic } from "app/services/reception";
import { toast } from "react-toastify";
import {
  addFeeRelaxationCategory,
  addFeeStructureGroup,
  getAllSessions,
  getFeeRelaxationCategory,
  getFeeTypeForMultipleClass,
} from "app/services/management";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import ClassDropDown from "./ClassDropdown";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import { Close, Delete } from "@mui/icons-material";
import dayjs from "dayjs";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import AddFeeTypeDialog from "./AddFeeTypeDialog";
import { getSubjectByMultipleClass } from "app/services/schoolService/subjectSettings.service";
import NewSubjectGroupDialog from "app/modules/school-management/pages/Subjects/components/NewSubjectGroupDialog";
import { getClassDropdown } from "app/services/schoolService/common.service";

const FeeStructure = ({ formik, classList }) => {
  const selectedSession = useSelector(selectActiveSession);
  // const selectedSemester = useSelector(selectActiveSemester);

  const [state, setState] = useState({
    addFeeTypeDialog: false,
    percentageOrRupees: false,
    newGroupDialog: false,
  });
  const addRelaxationCategoryFormik = useFormik({
    initialValues: {
      categoryName: "",
      dialog: false,
    },
    validationSchema: yup.object({
      categoryName: yup.string().required("Name is required"),
    }),
    onSubmit: (value) => {
      addFeeRelaxationCategoryMutate({
        feeRelaxationCategory: value?.categoryName,
      });
    },
  });
  const {
    isLoading: getFeeTypeLoading,
    isFetching: getFeeTypeFetching,
    data: getFeeTypeData,
    refetch: getFeeTypeRefetch,
  } = useQuery({
    queryKey: ["getFeeType", selectedSession, formik.values.postClassList],
    queryFn: () => {
      const classList = JSON.stringify([
        ...new Set(
          formik.values.postClassList?.map((item) => String(item?.queryClass))
        ),
      ]);
      return getFeeTypeForMultipleClass({
        classList,
        session: selectedSession,
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: formik.values.postClassList?.length > 0 ? true : false,
  });
  const {
    isLoading: getSubjectByMultipleClassLoading,
    isFetching: getSubjectByMultipleClassFetching,
    data: getSubjectByMultipleClassData,
    refetch: getSubjectByMultipleClassRefetch,
  } = useQuery({
    queryKey: [
      "getSubjectByMultipleClass",
      selectedSession,
      formik.values.postClassList,
    ],
    queryFn: () => {
      console.log("formik.values.postClassList", formik.values.postClassList);
      const reqClassDocIdList = JSON.stringify([
        ...new Set(
          formik.values.postClassList?.map((item) => String(item?.classDocId))
        ),
      ]);
      console.log(reqClassDocIdList);
      return getSubjectByMultipleClass({
        session: selectedSession,
        reqClassDocIdList,
        // semester: selectedSemester,
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: formik.values.postClassList?.length > 0 ? true : false,
  });

  console.log("cvbnmdxfcgvhbnm,", getSubjectByMultipleClassData);
  const {
    isLoading: getFeeRelaxationCategoryLoading,
    isFetching: getFeeRelaxationCategoryFetching,
    data: getFeeRelaxationCategoryData,
    refetch: getFeeRelaxationCategoryRefetch,
  } = useQuery({
    queryKey: ["getFeeRelaxationCategory"],
    queryFn: () => getFeeRelaxationCategory(),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    isLoading: addFeeRelaxationCategoryLoading,
    mutate: addFeeRelaxationCategoryMutate,
  } = useMutation(addFeeRelaxationCategory, {
    onSuccess: (success) => {
      addRelaxationCategoryFormik.resetForm();
      toast.success(success?.data?.message);
      formik.setValues((prev) => ({ ...prev, feeType: "" }));
      getFeeRelaxationCategoryRefetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const calulatePercentage = (amount, percentage) => {
    if (percentage) {
      return Number((Number(amount) * Number(percentage ?? 0)) / 100);
    } else {
      return 0;
    }
  };
  const getErrorRelaxation = (name, index) => {
    try {
      if (
        formik.touched?.moreRelaxationCategory[index][name] &&
        Boolean(formik.errors?.moreRelaxationCategory[index][name])
      ) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const getTouchedRelaxation = (name, index) => {
    try {
      if (formik.touched?.moreRelaxationCategory[index][name]) {
        return formik.errors?.moreRelaxationCategory[index][name];
      }
    } catch (error) {
      return "";
    }
  };

  console.log("formik.values", formik.values);
  return (
    <>
      {/* New Subject Group Dialog */}
      <NewSubjectGroupDialog
        open={state.newGroupDialog}
        handleCloseDialog={() => setState({ ...state, newGroupDialog: false })}
        refetch={() => getSubjectByMultipleClassRefetch()}
      />
      {/* add fee relaxation category */}
      <Dialog
        onClose={() => addRelaxationCategoryFormik.resetForm()}
        open={addRelaxationCategoryFormik.values.dialog}
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "450px" } },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            handleClose={() => addRelaxationCategoryFormik.resetForm()}
            title="Fee Relaxation Category Form"
          />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={addRelaxationCategoryFormik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <TextFieldLabel title={"Category Name"} required />
              <CustomTextField
                fullWidth
                size="small"
                placeholder="Type other category"
                value={addRelaxationCategoryFormik.values.categoryName}
                name="categoryName"
                onChange={addRelaxationCategoryFormik.handleChange}
                error={
                  addRelaxationCategoryFormik.touched.categoryName &&
                  Boolean(addRelaxationCategoryFormik.errors.categoryName)
                }
              />
              {addRelaxationCategoryFormik.touched.categoryName &&
                Boolean(addRelaxationCategoryFormik.errors.categoryName) && (
                  <Typography color="error">
                    {addRelaxationCategoryFormik.errors.categoryName}
                  </Typography>
                )}
              <Stack direction={"row"} justifyContent={"center"} py={1.5}>
                <LoadingButton
                  loading={addFeeRelaxationCategoryLoading}
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  color="secondary"
                  sx={{ mt: 2, width: "90%" }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <AddFeeTypeDialog
        open={state.addFeeTypeDialog}
        handleClose={() =>
          setState((prev) => ({ ...prev, addFeeTypeDialog: false }))
        }
        refetch={() => getFeeTypeRefetch()}
        getFeeTypeData={getFeeTypeData}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={5}>
          <TextFieldLabel title={"Class-Stream-Sec"} required />
          <CustomSelectBox
            size="small"
            fullWidth
            multiple
            MenuProps={MenuProps}
            value={formik.values.postClassList}
            error={
              formik.touched.postClassList &&
              Boolean(formik.errors.postClassList)
            }
            renderValue={() => {
              if (formik.values?.postClassList?.length !== 0) {
                return (
                  <Stack direction="row" gap={0.5}>
                    <Typography
                      sx={{
                        width: "90%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {formik.values.postClassList
                        .map((cls) => cls?.display)
                        .join(", ")}
                    </Typography>
                  </Stack>
                );
              }
              return <Typography>Select</Typography>;
            }}
          >
            {classList?.map((item, ind) => (
              <ClassDropDown
                item={item}
                index={ind}
                formikSetState={formik.setValues}
                formikState={formik.values}
                key={ind}
              />
            ))}
          </CustomSelectBox>
          {formik.touched.postClassList &&
            Boolean(formik.errors.postClassList) && (
              <Typography color="error">
                {formik.errors.postClassList}
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} md={3}>
          {/* <TextFieldLabel title={"Fee Type"} required /> */}
          <Stack direction={"row"} gap={0.3}>
            <Stack alignItems={"center"} direction={"row"}>
              <Radio
                color="secondary"
                onChange={() =>
                  formik.setValues((prev) => ({
                    ...prev,
                    feeTypeRadio: "academic",
                  }))
                }
                checked={formik.values.feeTypeRadio === "academic"}
              />
              <Typography sx={{ fontWeight: "450", fontSize: "12px" }}>
                Academic Fee
              </Typography>
            </Stack>
            <Stack alignItems={"center"} direction={"row"}>
              <Radio
                color="secondary"
                onChange={() =>
                  formik.setValues((prev) => ({
                    ...prev,
                    feeTypeRadio: "subject",
                  }))
                }
                checked={formik.values.feeTypeRadio === "subject"}
              />
              <Typography sx={{ fontWeight: "450", fontSize: "12px" }}>
                Subject Fee
              </Typography>
            </Stack>
          </Stack>
          {formik.values.feeTypeRadio === "academic" && (
            <>
              <CustomSelectBox
                MenuProps={MenuProps}
                displayEmpty
                fullWidth
                size="small"
                value={formik?.values.feeType}
                name="feeType"
                error={formik.touched.feeType && Boolean(formik.errors.feeType)}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {getFeeTypeData?.data?.list?.map((item, index) => (
                  <MenuItem
                    value={item?.feeType}
                    key={index}
                    onClick={(e) =>
                      formik.setValues((prev) => ({
                        ...prev,
                        feeType: item?.feeType,
                        occurrence: item?.feeOccurrence,
                      }))
                    }
                  >
                    {item?.feeType}
                  </MenuItem>
                ))}
                <MenuItem
                  value="Other"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      addFeeTypeDialog: true,
                    }))
                  }
                >
                  Other
                </MenuItem>
              </CustomSelectBox>
              {formik.touched.feeType && Boolean(formik.errors.feeType) && (
                <Typography color="error">{formik.errors.feeType}</Typography>
              )}
            </>
          )}
          {formik.values.feeTypeRadio === "subject" && (
            <>
              <CustomSelectBox
                MenuProps={MenuProps}
                displayEmpty
                fullWidth
                size="small"
                value={formik?.values.feeType}
                name="feeType"
                error={formik.touched.feeType && Boolean(formik.errors.feeType)}
                native
                onChange={(e) => {
                  console.log("e.target.value", e.target.value);
                  const value = e.target.value;
                  if (value === "Other") {
                    setState((prev) => ({
                      ...prev,
                      newGroupDialog: true,
                    }));
                  } else {
                    const [selectedSubjectName, selectedSubjectId] =
                      value.split("|");
                    const selectedSubject =
                      getSubjectByMultipleClassData?.data?.list?.find(
                        (item) =>
                          item?.subjectName === selectedSubjectName &&
                          item?._id === selectedSubjectId
                      );
                    console.log(
                      "selectedSubject",
                      selectedSubject?.subjectName
                    );
                    if (selectedSubject) {
                      formik.setValues((prev) => ({
                        ...prev,
                        feeType: `${selectedSubject?.subjectName}|${selectedSubject?._id}`, // Set feeType in the format `subjectName|_id`
                        subjectDocId: selectedSubject?._id,
                        occurrence: "recurring",
                      }));
                    }
                  }
                }}
              >
                <MenuItem value="" disabled component="option">
                  Select
                </MenuItem>
                {getSubjectByMultipleClassData?.data?.list?.map(
                  (item, index) => (
                    <MenuItem
                      value={`${item?.subjectName}|${item?._id}`}
                      key={index}
                      component="option"
                      // onChange={(e) =>
                      //   formik.setValues((prev) => ({
                      //     ...prev,
                      //     feeType: item?.subjectName,
                      //     subjectDocId: item?._id,
                      //     occurrence: "recurring",
                      //   }))
                      // }
                    >
                      {`${item?.subjectName} (${
                        item?.optional === true ? "Minor " : "Major "
                      } : ${
                        item?.practical === true ? "P" : "NP"
                      }) ${IndianCurrency(item?.feeAmount || 0)}`}
                    </MenuItem>
                  )
                )}
                <MenuItem
                  value="Other"
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      newGroupDialog: true,
                    }))
                  }
                  component="option"
                >
                  Other
                </MenuItem>
              </CustomSelectBox>
              {formik.touched.feeType && Boolean(formik.errors.feeType) && (
                <Typography color="error">{formik.errors.feeType}</Typography>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldLabel title={"Gender"} required />
          <Stack direction={"row"} alignItems={"center"}>
            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  checked={formik.values.gender === "allGender" ? true : false}
                  onClick={() =>
                    formik.setValues((prev) => ({
                      ...prev,
                      gender: "allGender",
                    }))
                  }
                />
              }
              label="All"
            />
            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  checked={formik.values.gender === "Male" ? true : false}
                  onClick={() =>
                    formik.setValues((prev) => ({
                      ...prev,
                      gender: "Male",
                    }))
                  }
                />
              }
              label="M"
            />
            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  checked={formik.values.gender === "Female" ? true : false}
                  onClick={() =>
                    formik.setValues((prev) => ({
                      ...prev,
                      gender: "Female",
                    }))
                  }
                />
              }
              label="F"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldLabel title={"Fee Relaxation Category"} />
          <CustomTextFieldDisabled value={"General"} fullWidth size="small" />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldLabel title={"General Fee Amount"} required />
          <CustomTextField
            fullWidth
            size="small"
            placeholder="Type amount"
            value={formik.values.generalFeeAmount}
            error={
              formik.touched.generalFeeAmount &&
              Boolean(formik.errors.generalFeeAmount)
            }
            onChange={(e) => {
              formik.setValues((prev) => ({
                ...prev,
                generalFeeAmount: e.target.value,
                finalFeeAmount:
                  Number(e.target.value) +
                  calulatePercentage(
                    e.target.value,
                    formik.values.taxpercentage
                  ),
              }));
            }}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>&#8377;</Typography>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.generalFeeAmount &&
            Boolean(formik.errors.generalFeeAmount) && (
              <Typography color="error">
                {formik.errors.generalFeeAmount}
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldLabel title={"Tax % (If Applicable)"} />
          <CustomTextField
            fullWidth
            size="small"
            placeholder="Type tax"
            value={formik.values.taxpercentage}
            onChange={(e) => {
              formik.setValues((prev) => ({
                ...prev,
                taxpercentage: e.target.value,
                finalFeeAmount:
                  Number(formik.values.generalFeeAmount) +
                  calulatePercentage(
                    formik.values.generalFeeAmount,
                    e.target.value
                  ),
              }));
            }}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>%</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldLabel title={"Final Fee Amount"} required />
          <CustomTextFieldDisabled
            fullWidth
            size="small"
            value={formik.values.finalFeeAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>&#8377;</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: { xs: window.innerWidth - 40, md: "100%" },
          overflow: "auto",
        }}
      >
        <FeeRelaxationWrapper sx={{ width: { xs: "850px", md: "100%" } }}>
          <div className="upper_box_wrap">
            <Grid
              container
              spacing={1.5}
              display={"flex"}
              alignItems={"center"}
            >
              <Grid item xs={1}>
                <Typography className="upper_box_headingTitle">
                  Sr No.
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className="upper_box_headingTitle">
                  Fee Relaxation Category
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="upper_box_headingTitle">
                  General Fee
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className="upper_box_headingTitle"
                  // sx={{ textAlign: "center" }}
                >
                  Discount
                </Typography>
                {/* <Stack direction={"row"} alignItems={"center"}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Radio
                    color="secondary"
                    checked={!state.percentageOrRupees}
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        percentageOrRupees: !prev.percentageOrRupees,
                      }))
                    }
                  />
                  <Typography sx={{ fontWeight: 600 }}>%</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"}>
                  <Radio
                    color="secondary"
                    checked={state.percentageOrRupees}
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        percentageOrRupees: !prev.percentageOrRupees,
                      }))
                    }
                  />
                  <Typography sx={{ fontWeight: 600 }}>&#8377;</Typography>
                </Stack>
              </Stack> */}
              </Grid>
              <Grid item xs={2}>
                <Typography className="upper_box_headingTitle">
                  Balance Fee
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography className="upper_box_headingTitle">
                  Final Fee
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={1.5} sx={{ padding: "10px" }}>
            <Grid item xs={1}>
              <Typography className="upper_box_headingTitle">1</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="upper_box_headingTitle">
                General
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="upper_box_headingTitle">
                &#8377; {formik.values.generalFeeAmount}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="upper_box_headingTitle">
                &#8377; 0
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="upper_box_headingTitle">
                {formik.values.generalFeeAmount}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography className="upper_box_headingTitle">
                &#8377; {formik.values.finalFeeAmount}
              </Typography>
            </Grid>
          </Grid>

          {formik.values.moreRelaxationCategory?.map((res, i) => (
            <Grid
              container
              spacing={1.5}
              sx={{ padding: "10px" }}
              display={"flex"}
              alignItems={"center"}
            >
              <Grid item xs={1}>
                <Typography className="upper_box_headingTitle">
                  {i + 2}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <CustomSelectBox
                  MenuProps={MenuProps}
                  displayEmpty
                  fullWidth
                  size="small"
                  value={res?.feeRelaxationCategory}
                  name="feeType"
                  onChange={(e) => {
                    res["feeRelaxationCategory"] = e.target.value;
                    formik.setValues((prev) => ({ ...prev }));
                  }}
                  error={
                    getErrorRelaxation("feeRelaxationCategory", i) &&
                    getTouchedRelaxation("feeRelaxationCategory", i)
                  }
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {getFeeRelaxationCategoryData?.data?.list?.map(
                    (item, index) => (
                      <MenuItem
                        value={item?.feeRelaxationCategory}
                        key={item?._id}
                      >
                        {item?.feeRelaxationCategory}
                      </MenuItem>
                    )
                  )}
                  <MenuItem
                    value="Other"
                    onClick={() =>
                      addRelaxationCategoryFormik.setValues((prev) => ({
                        ...prev,
                        dialog: true,
                      }))
                    }
                  >
                    Other
                  </MenuItem>
                </CustomSelectBox>
                {getErrorRelaxation("feeRelaxationCategory", i) &&
                  getTouchedRelaxation("feeRelaxationCategory", i) && (
                    <Typography sx={{ color: "red" }}>
                      Cateory reuired
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={2}>
                <Typography className="upper_box_headingTitle">
                  &#8377; {formik.values.generalFeeAmount}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="upper_box_headingTitle">
                  <CustomTextField
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Type discount"
                    value={res?.categoryDiscountAmount}
                    onChange={(e) => {
                      res["categoryDiscountAmount"] = e.target.value;
                      formik.setValues((prev) => ({ ...prev }));
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="upper_box_headingTitle">
                  &#8377;{" "}
                  {Number(formik.values.generalFeeAmount) -
                    Number(res?.categoryDiscountAmount)}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography className="upper_box_headingTitle">
                    &#8377;{" "}
                    {Number(formik.values.generalFeeAmount) -
                      Number(res?.categoryDiscountAmount) +
                      calulatePercentage(
                        Number(formik.values.generalFeeAmount) -
                          Number(res?.categoryDiscountAmount),
                        formik.values.taxpercentage
                      )}
                  </Typography>
                  <Close
                    color="error"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      formik.setValues((prev) => ({
                        ...prev,
                        moreRelaxationCategory:
                          prev?.moreRelaxationCategory?.filter(
                            (_, ind) => ind !== i
                          ),
                      }))
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          ))}
          {/* add more button */}
          <Typography
            className="upper_box_addmore"
            onClick={() =>
              formik.setValues((prev) => ({
                ...prev,
                moreRelaxationCategory: [
                  ...prev.moreRelaxationCategory,
                  {
                    feeRelaxationCategory: "",
                    generalFeeAmount: formik.values.generalFeeAmount ?? 0,
                    categoryDiscountAmount: "",
                  },
                ],
              }))
            }
          >
            +Add more
          </Typography>
        </FeeRelaxationWrapper>
      </Box>

      {/* items */}
    </>
  );
};
const DueDates = ({ formik }) => {
  const [selectAll, setSelectAll] = useState(false);
  const calculateMaximumDate = (date) => {
    const dte = new Date(date);
    return new Date(
      `${dte.getMonth() + 1}-${dayjs(dte).daysInMonth()}-${dte.getFullYear()}`
    );
  };
  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    formik.setValues((prev) => ({
      ...prev,
      reoccuringData: prev?.reoccuringData?.map((res) => ({
        ...res,
        checked,
      })),
    }));
  };

  const handleCheckboxClick = (item) => {
    item.checked = !item.checked;
    formik.setValues((prev) => {
      const allChecked = prev.reoccuringData.every((item) => item.checked);
      setSelectAll(allChecked);
      return { ...prev };
    });
  };
  const calculateMinimumDate = (date) => {
    const dte = new Date(date);
    return new Date(`${dte.getMonth() + 1}-01-${dte.getFullYear()}`);
  };
  const sameAsAll = (value) => {
    const sameDate = new Date(value);
    formik.setValues((prev) => ({
      ...prev,
      reoccuringData: prev?.reoccuringData?.map((res) => ({
        ...res,
        value: new Date(res?.value).setDate(sameDate.getDate()),
      })),
    }));
  };
  return (
    <>
      {formik?.values?.occurrence !== "reoccuring" && (
        <Stack
          direction="row"
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ pt: 0.5 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={selectAll}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            }
            label="Select All"
          />
        </Stack>
      )}
      {formik.values.occurrence === "oneTime"
        ? formik?.values?.oneTimeData?.map((item, i) => (
            <Stack
              key={i}
              direction="row"
              gap={2}
              justifyContent={"center"}
              sx={{ pt: 0.5 }}
            >
              <Box sx={{ width: "200px" }}>
                <FormControlLabel
                  control={
                    <Radio
                      color="secondary"
                      checked={
                        formik.values.oneTimeDateValue === item?.id
                          ? true
                          : false
                      }
                      onClick={() =>
                        formik.setValues((prev) => ({
                          ...prev,
                          oneTimeDateValue: item?.id,
                        }))
                      }
                    />
                  }
                  label={dayjs(item?.label).format("MMMM-YYYY")}
                />
              </Box>
              <DesktopDatePicker
                // maxDate={calculateMaximumDate(item.value)}
                minDate={calculateMinimumDate(item.value)}
                disabled={
                  formik.values.oneTimeDateValue === item?.id ? false : true
                }
                inputFormat="DD MMM, YYYY"
                onChange={(e) => {
                  item["value"] = e;
                  formik.setValues((prev) => ({ ...prev }));
                }}
                value={item.value}
                renderInput={(params) =>
                  formik.values.oneTimeDateValue === item?.id ? (
                    <CustomTextField
                      {...params}
                      size="small"
                      fullWidth
                      sx={{ width: "200px" }}
                    />
                  ) : (
                    <CustomTextFieldDisabled
                      {...params}
                      size="small"
                      fullWidth
                      sx={{ width: "200px" }}
                    />
                  )
                }
              />
            </Stack>
          ))
        : formik?.values?.reoccuringData?.map((item, i) => (
            <>
              <Stack
                key={i}
                direction="row"
                gap={2}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ pt: 0.5 }}
              >
                <Box sx={{ width: "200px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={item?.checked}
                        // onClick={() => {
                        //   item.checked = !item.checked;
                        //   formik.setValues((prev) => ({
                        //     ...prev,
                        //   }));
                        // }}
                        onClick={() => handleCheckboxClick(item)}
                      />
                    }
                    label={dayjs(item?.label).format("MMMM-YYYY")}
                  />
                </Box>
                <DatePicker
                  // maxDate={calculateMaximumDate(item.value)}
                  minDate={calculateMinimumDate(item.value)}
                  disabled={item.checked ? false : true}
                  inputFormat="DD MMM, YYYY"
                  onChange={(e) => {
                    item["value"] = e;
                    formik.setValues((prev) => ({ ...prev }));
                  }}
                  value={item.value}
                  renderInput={(params) =>
                    item.checked ? (
                      <CustomTextField
                        {...params}
                        size="small"
                        fullWidth
                        sx={{ width: "200px" }}
                      />
                    ) : (
                      <CustomTextFieldDisabled
                        {...params}
                        size="small"
                        fullWidth
                        sx={{ width: "200px" }}
                      />
                    )
                  }
                />
                <Box sx={{ width: "80px" }}>
                  <Typography
                    onClick={() => sameAsAll(item.value)}
                    sx={{
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    same as all
                  </Typography>
                </Box>
              </Stack>
            </>
          ))}
    </>
  );
};
const FineDetail = ({ formik }) => {
  const getErrorFine = (name, index) => {
    try {
      if (
        formik.touched?.slotWiseData[index][name] &&
        Boolean(formik.errors?.slotWiseData[index][name])
      ) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const getTouchedFine = (name, index) => {
    try {
      if (formik.touched?.slotWiseData[index][name]) {
        return formik.errors?.slotWiseData[index][name];
      }
    } catch (error) {
      return "";
    }
  };
  return (
    <>
      <Typography sx={{ fontWeight: 600 }}>
        Fine Calculation Rate : &nbsp; &nbsp;
      </Typography>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Radio
              color="secondary"
              checked={
                formik.values.fineCalculationRate === "fixedAmount"
                  ? true
                  : false
              }
              onClick={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  fineCalculationRate: "fixedAmount",
                }))
              }
            />
          }
          label="Fixed Amount"
        />
        <FormControlLabel
          control={
            <Radio
              color="secondary"
              checked={
                formik.values.fineCalculationRate === "slotWise" ? true : false
              }
              onClick={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  fineCalculationRate: "slotWise",
                }))
              }
            />
          }
          label="Slot wise (Per Day)"
        />
        <FormControlLabel
          control={
            <Radio
              color="secondary"
              checked={
                formik.values.fineCalculationRate === "slotWiseFixed"
                  ? true
                  : false
              }
              onClick={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  fineCalculationRate: "slotWiseFixed",
                }))
              }
            />
          }
          label="Slot wise (Fixed Amount)"
        />
      </Stack>
      {formik.values.fineCalculationRate === "fixedAmount" && (
        <>
          <CustomTextField
            sx={{ width: "200px", mt: 1 }}
            fullWidth
            size="small"
            placeholder="Type amount"
            value={formik.values.fixedAmount}
            name="fixedAmount"
            onChange={formik.handleChange}
            type="number"
            error={
              formik.touched.fixedAmount && Boolean(formik.errors.fixedAmount)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>&#8377;</Typography>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.fixedAmount && Boolean(formik.errors.fixedAmount) && (
            <Typography color="error">{formik.errors.fixedAmount}</Typography>
          )}
        </>
      )}
      {formik.values.fineCalculationRate !== "fixedAmount" && (
        <>
          <Box sx={{ width: "50%" }}>
            <Grid container spacing={1} columns={8}>
              <Grid item xs={2}>
                <Head1>Day</Head1>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <Head1>Day</Head1>
              </Grid>
              <Grid item xs={2}>
                <Head1>Amount</Head1>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            {formik.values.slotWiseData?.map((item, i) => (
              <Grid
                container
                spacing={1}
                columns={8}
                key={i}
                display={"flex"}
                alignItems={"center"}
                sx={{ pt: 0.5 }}
              >
                <Grid item xs={2}>
                  <CustomTextField
                    error={
                      getErrorFine("fromDay", i) && getTouchedFine("fromDay", i)
                    }
                    fullWidth
                    size="small"
                    placeholder="Type day"
                    value={item?.fromDay}
                    type="number"
                    onChange={(e) => {
                      item["fromDay"] = e.target.value;
                      formik.setValues((prev) => ({ ...prev }));
                    }}
                  />
                  {getErrorFine("fromDay", i) &&
                    getTouchedFine("fromDay", i) && (
                      <Typography color="red">required</Typography>
                    )}
                </Grid>
                <Grid item xs={1}>
                  <Head1 textAlign={"center"}>To</Head1>
                </Grid>
                <Grid item xs={2}>
                  <CustomTextField
                    error={
                      getErrorFine("toDay", i) && getTouchedFine("toDay", i)
                    }
                    fullWidth
                    size="small"
                    placeholder="Type day"
                    value={item?.toDay}
                    type="number"
                    onChange={(e) => {
                      item["toDay"] = e.target.value;
                      formik.setValues((prev) => ({ ...prev }));
                    }}
                  />
                  {getErrorFine("toDay", i) && getTouchedFine("toDay", i) && (
                    <Typography color="red">required</Typography>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <CustomTextField
                    error={
                      getErrorFine("amount", i) && getTouchedFine("amount", i)
                    }
                    fullWidth
                    size="small"
                    placeholder="Type amount"
                    value={item?.amount}
                    onChange={(e) => {
                      item["amount"] = e.target.value;
                      formik.setValues((prev) => ({ ...prev }));
                    }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography>&#8377;</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {getErrorFine("amount", i) && getTouchedFine("amount", i) && (
                    <Typography color="red">required</Typography>
                  )}
                </Grid>
                <Grid item xs={1}>
                  {i !== 0 && (
                    <Delete
                      sx={{ cursor: "pointer" }}
                      color="error"
                      onClick={() =>
                        formik.setValues((prev) => ({
                          ...prev,
                          slotWiseData: prev.slotWiseData.filter(
                            (_, ind) => i !== ind
                          ),
                        }))
                      }
                    />
                  )}
                </Grid>
              </Grid>
            ))}
            <Grid
              container
              spacing={1}
              columns={8}
              display={"flex"}
              alignItems={"center"}
              sx={{ mt: 0.5 }}
            >
              <Grid item xs={3}>
                <Head1 textAlign={"center"} fontSize={16}>
                  Greater Than
                </Head1>
              </Grid>

              <Grid item xs={2}>
                <CustomTextFieldDisabled
                  fullWidth
                  size="small"
                  value={
                    formik.values.slotWiseData[
                      formik.values.slotWiseData?.length - 1
                    ].toDay
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <CustomTextField
                  fullWidth
                  size="small"
                  placeholder="amount"
                  value={formik.values.graterThanAmount}
                  error={
                    formik.touched.graterThanAmount &&
                    Boolean(formik.errors.graterThanAmount)
                  }
                  onChange={(e) => {
                    formik.setValues((prev) => ({
                      ...prev,
                      graterThanAmount: e.target.value,
                    }));
                  }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>&#8377;</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.touched.graterThanAmount &&
                  Boolean(formik.errors.graterThanAmount) && (
                    <Typography color="error">
                      {formik.errors.graterThanAmount}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={1} />
            </Grid>
            <AddMoreButton
              onClick={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  slotWiseData: [
                    ...prev.slotWiseData,
                    {
                      fromDay:
                        prev.slotWiseData[prev.slotWiseData?.length - 1].toDay,
                      toDay:
                        Number(
                          prev.slotWiseData[prev.slotWiseData?.length - 1].toDay
                        ) + 1,
                      amount: 0,
                    },
                  ],
                }))
              }
            >
              + Add More
            </AddMoreButton>
          </Box>
        </>
      )}
    </>
  );
};

const AddGroupFeeStructureDialog = ({
  showDialog = false,
  onClose = () => {},
  refetch,
}) => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  // const verifyIsCollege = useSelector(isCollege);
  // const verifyIsSchool = useSelector(isSchool);
  // const selectedSemester = useSelector(selectActiveSemester);
  const [state, setState] = useState({
    tabValue: "1",
    classList: [],
    optional: false,
  });
  const formik = useFormik({
    initialValues: {
      classDocId: "",
      feeType: "",
      subjectDocId: "",
      feeTypeRadio: "academic",
      classList: [],
      postClassList: [],
      classData: [],
      gender: "allGender",
      generalFeeAmount: "",
      taxpercentage: 0,
      finalFeeAmount: "",
      moreRelaxationCategory: [],
      occurrence: "oneTime",
      oneTimeDateValue: 1,
      oneTimeData: [],
      reoccuringData: [],
      fineCalculationRate: "fixedAmount",
      fixedAmount: 0,
      slotWiseData: [{ fromDay: 1, toDay: 2, amount: "" }],
      slotWiseFixedData: [{ fromDay: 1, toDay: 2, amount: "" }],
      graterThanAmount: "",
    },
    validationSchema: yup.object({
      ...(state.tabValue === "1" && {
        postClassList: yup.array().min(1, "Atleast one class required"),
        feeType: yup.string().required("Fee Type required"),
        generalFeeAmount: yup.string().required("General Amount required"),
        moreRelaxationCategory: yup.array().of(
          yup.object({
            feeRelaxationCategory: yup.string().required("Category required"),
          })
        ),
      }),
      ...(state.tabValue === "3" && {
        graterThanAmount: yup.string().when("fineCalculationRate", {
          is: (values) =>
            values === "slotWise" || values === "slotWiseFixed" ? true : false,
          then: yup.string().required("required"),
        }),
        fixedAmount: yup.string().when("fineCalculationRate", {
          is: "fixedAmount",
          then: yup.string().required("required"),
        }),
        slotWiseData: yup.array().when("fineCalculationRate", {
          is: (value) => (value !== "fixedAmount" ? true : false),
          then: yup.array().of(
            yup.object().shape({
              fromDay: yup.string().required("required"),
              toDay: yup.string().required("required"),
              amount: yup.string().required("required"),
            })
          ),
        }),
      }),
    }),
    onSubmit: (values) => {
      if (state.tabValue === "3") {
        let monthYearList = [];
        if (values.occurrence === "oneTime") {
          const data = values.oneTimeData?.find(
            (item) => item?.id === values.oneTimeDateValue
          );
          monthYearList.push({
            year: dayjs(data?.label).format("YYYY"),
            month: dayjs(data?.label).format("MMMM"),
            dueDate: dayjs(data?.value).format("YYYY-MM-DD"),
          });
        } else {
          values.reoccuringData?.forEach((item) => {
            if (item.checked) {
              monthYearList.push({
                year: dayjs(item?.label).format("YYYY"),
                month: dayjs(item?.label).format("MMMM"),
                dueDate: dayjs(item?.value).format("YYYY-MM-DD"),
              });
            }
          });
        }
        const body = {
          optional: state.optional,
          session: selectedSession,
          // feeType: values.feeType,
          feeType:
            values?.feeTypeRadio === "subject"
              ? values.feeType.split("|")[0]
              : values.feeType,
          gender: values.gender,
          subjectFee: values?.feeTypeRadio === "subject" ? true : false,
          // ...(values?.feeTypeRadio === "academic" && {
          subjectDocId: values?.subjectDocId,
          // }),
          classList: values.postClassList?.map((item) => ({
            classDocId: item?.classDocId,
            section: item?.section,
          })),
          generalFeeAmount: Number(values.generalFeeAmount),
          feeStructureList: [
            {
              feeRelaxationCategory: "General",
              generalFeeAmount: Number(values.generalFeeAmount),
              categoryDiscountAmount: 0,
              taxRate: values.taxpercentage ? Number(values.taxpercentage) : 0,
            },
            ...values.moreRelaxationCategory?.map((item) => ({
              feeRelaxationCategory: item?.feeRelaxationCategory,
              generalFeeAmount: Number(values.generalFeeAmount),
              categoryDiscountAmount: Number(item?.categoryDiscountAmount),
              taxRate: values.taxpercentage ? Number(values.taxpercentage) : 0,
            })),
          ],

          monthYearList,
          isFixedAmount:
            values.fineCalculationRate === "fixedAmount" ? true : false,
          is_slotWise_perDay:
            values.fineCalculationRate === "slotWise" ? true : false,
          is_slotWise_fixedAmount:
            values.fineCalculationRate === "slotWiseFixed" ? true : false,
          fixedAmount:
            values.fineCalculationRate === "fixedAmount"
              ? values.fixedAmount
              : 0,
          slotWise:
            values.fineCalculationRate === "fixedAmount"
              ? null
              : {
                  slotWiseFineRate: values.slotWiseData?.map((item) => ({
                    from: Number(item?.fromDay),
                    upTo: Number(item?.toDay),
                    amount: Number(item?.amount),
                  })),
                  amount: Number(values.graterThanAmount),
                  greaterThanDay:
                    values.slotWiseData[values.slotWiseData?.length - 1].toDay,
                },
        };
        addFeeStructureGroupMutate(body);
      }
    },
  });
  const {
    mutate: addFeeStructureGroupMutate,
    isLoading: addFeeStructureGroupLoadign,
  } = useMutation(addFeeStructureGroup, {
    onSuccess: (success) => {
      toast.success(success.data?.message);
      refetch();
      formik.resetForm();
      setState((prev) => ({
        ...prev,
        // classList: [],
        tabValue: "1",
      }));
      onClose();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const handleCloseAddNewDialog = () => {
    formik.resetForm();
    onClose();
    setState((prev) => ({ ...prev, tabValue: "1" }));
  };
  const handleTabsChange = (_, value) => {
    switch (value) {
      case "1":
        setState((prev) => ({ ...prev, tabValue: value }));
        break;
      case "2":
        if (formik.isValid) {
          setState((prev) => ({ ...prev, tabValue: value }));
          break;
        }
        formik.handleSubmit();
        setTimeout(() => {
          if (formik.isValid) {
            setState((prev) => ({ ...prev, tabValue: value }));
          }
        }, 1000);
        break;
      case "3":
        setState((prev) => ({ ...prev, tabValue: value }));
        break;

      default:
        break;
    }
  };
  const { isFetched, data: getClassDropdownData } = useQuery({
    queryKey: [
      "getClassDropdown",
      // ...(verifyIsSchool ? [selectedMedium] : []),
      // ...(verifyIsCollege ? [selectedSemester] : []),
    ],
    queryFn: () =>
      getClassDropdown(schoolDocId, {
        session: selectedSession,
        // ...(verifyIsCollege && { semester: selectedSemester }),
        // ...(verifyIsSchool && { medium: selectedMedium }),
      }),
    // getClassDropdown({
    //   schoolDocId,
    //   selectedSession,
    //   selectedMedium,
    // }),

    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  // const { isFetched, data: getClassDropdownData } = useQuery({
  //   queryKey: ["getClassInfo", selectedSession, selectedMedium],
  //   queryFn: () =>
  //     getClassDropdownLogic({
  //       schoolDocId,
  //       selectedSession,
  //       selectedMedium,
  //     }),

  //   onError: (error) => {
  //     toast.error(error?.response?.data?.message);
  //   },
  // });
  const { data: sessionsResponse, isFetched: sessionFetched } = useQuery({
    queryKey: ["getAllSessions"],
    queryFn: () => getAllSessions(),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  useEffect(() => {
    if (isFetched) {
      try {
        if (
          getClassDropdownData?.data &&
          getClassDropdownData?.data?.list?.length > 0
        ) {
          const arr = [];
          getClassDropdownData?.data?.list?.forEach((item) => {
            const body = {
              classDocId: item?.classDocId,
              className: item?.className_stream,
              queryClassName: item?.className,
              sections: [],
              checked: false,
            };
            item?.sections?.forEach((res) => {
              body.sections.push({
                section: res,
                checked: false,
              });
            });
            arr.push(body);
          });
          setState((prev) => ({ ...prev, classList: arr }));
        }
      } catch (error) {}
    }
  }, [showDialog, isFetched]);

  useEffect(() => {
    if (sessionFetched) {
      const filteredData = sessionsResponse?.data?.list?.find(
        (item) => item?.sessionName === selectedSession
      );
      if (filteredData !== undefined) {
        const sessionStartDate = dayjs(filteredData.startDate);
        const sessionEndDate = dayjs(filteredData.endDate);
        const oneTimeData = [];
        const reoccuringData = [];
        const dt = new Date(sessionStartDate);
        for (
          let index = 0;
          index < sessionEndDate.diff(sessionStartDate, "M") + 1;
          index++
        ) {
          const month =
            dt.getMonth() + 1 < 10
              ? `0${dt.getMonth() + 1}`
              : dt.getMonth() + 1;

          oneTimeData.push({
            id: index + 1,
            value: new Date(`${month}-01-${dt.getFullYear()}`),
            label: new Date(`${month}-01-${dt.getFullYear()}`),
          });
          reoccuringData.push({
            id: index + 1,
            value: new Date(`${month}-01-${dt.getFullYear()}`),
            label: new Date(`${month}-01-${dt.getFullYear()}`),
            checked: index === 0 ? true : false,
          });
          dt.setMonth(dt.getMonth() + 1);
        }
        formik.setValues((prev) => ({ ...prev, reoccuringData, oneTimeData }));
      }
    }
  }, [sessionFetched, selectedSession, showDialog]);

  return (
    <Dialog
      onClose={handleCloseAddNewDialog}
      open={showDialog}
      PaperProps={{
        sx: { minWidth: { xs: "98%", md: "850px" } },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <DialogHeader
          handleClose={handleCloseAddNewDialog}
          title={`Group Fee Structure Form : ${selectedSession}`}
        />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <TabContext value={state.tabValue}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <TabsWrapper>
                <TabList>
                  <Tab label="Fee Structure" value="1" />
                  <Tab label="Due Dates" value="2" />
                  <Tab label="Fine Detail" value="3" />
                </TabList>
              </TabsWrapper>
              <Stack direction="row" gap={1} alignItems={"center"}>
                <Typography fontSize={14} fontWeight={600}>
                  Optional:{" "}
                </Typography>
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  checked={!state.optional}
                  onChange={() =>
                    setState((prev) => ({ ...prev, optional: false }))
                  }
                  label="No"
                  componentsProps={{
                    typography: {
                      fontSize: "14px",
                    },
                  }}
                />
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  checked={state.optional}
                  onChange={() =>
                    setState((prev) => ({ ...prev, optional: true }))
                  }
                  label="Yes"
                  componentsProps={{
                    typography: {
                      fontSize: "14px",
                    },
                  }}
                />
              </Stack>
            </Stack>
            <TabPanel value="1" sx={{ p: "0 !important" }}>
              <Box sx={{ mt: 1 }}>
                <FeeStructure formik={formik} classList={state.classList} />
                <Stack direction={"row"} justifyContent={"flex-end"} pt={1}>
                  <Button
                    onClick={(e) => handleTabsChange(e, "2")}
                    variant="outlined"
                    size="large"
                    color="secondary"
                    sx={{
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      width: "20%",
                    }}
                  >
                    Next
                  </Button>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ p: "0 !important" }}>
              <Box sx={{ mt: 1 }}>
                <DueDates formik={formik} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  pt={1}
                  gap={2}
                >
                  <Button
                    onClick={(e) => handleTabsChange(e, "1")}
                    variant="outlined"
                    size="large"
                    color="secondary"
                    sx={{
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      width: "20%",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={(e) => handleTabsChange(e, "3")}
                    variant="outlined"
                    size="large"
                    color="secondary"
                    sx={{
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      width: "20%",
                    }}
                  >
                    Next
                  </Button>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel value="3" sx={{ p: "0 !important" }}>
              <Box sx={{ mt: 1 }}>
                <FineDetail formik={formik} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  pt={1}
                  gap={2}
                >
                  <Button
                    onClick={(e) => handleTabsChange(e, "2")}
                    variant="outlined"
                    size="large"
                    color="secondary"
                    sx={{
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      width: "20%",
                    }}
                  >
                    Previous
                  </Button>
                  <LoadingButton
                    loading={addFeeStructureGroupLoadign}
                    onClick={formik.handleSubmit}
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      width: "20%",
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddGroupFeeStructureDialog;
const FeeRelaxationWrapper = styled(Box)`
  border-radius: 15px;
  border: 1px solid #b6c8d6;
  margin-top: 10px;
  .upper_box_wrap {
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid #b6c8d6;
    background: #f6f8fc;
    padding: 10px;
  }
  .upper_box_headingTitle {
    font-size: 14px;
    font-weight: 500;
  }
  .upper_box_addmore {
    color: #2789fd;
    cursor: pointer;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    margin: 10px;
    display: inline;
  }
`;
const Head1 = styled(Typography)`
  font-weight: 600;
  font-size: 14;
`;
const AddMoreButton = styled(Typography)`
  color: #2789fd;

  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  cursor: pointer;
  display: inline;
  margin-top: 10px;
`;
