import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  // isCollege,
  // isSchool,
  // selectActiveSemester,
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import {
  addNewAvailableSubject,
  getAvailbaleSubjects,
  mapSubjectWithClass,
} from "app/services/schoolService/subjectSettings.service";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { MenuProps } from "app/utils/helper";
import Chip from "app/components/common/CustomChip";
import { getClassDropdown } from "app/services/schoolService/common.service";

const NewSubjectGroupDialog = ({
  open,
  handleCloseDialog,
  refetch = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  // const verifyIsSchool = useSelector(isSchool);
  // const verifyIsCollege = useSelector(isCollege);
  // const selectedSemester = useSelector(selectActiveSemester);

  const [state, setState] = useState({
    classDocIdList: [],
    classNameList: [],
    subjectTypeRadio: false,
    practicalRadio: false,
  });
  const formik = useFormik({
    initialValues: {
      availableSubjectDocId: "",
    },
    validationSchema: yup.object({
      availableSubjectDocId: yup.string().required("Subject Name is required"),
    }),
    onSubmit: (values) => {
      if (state.classDocIdList.length === 0) {
        toast.warning("Please select at least one class");
        return;
      }
      mapSubjectWithClassMutate({
        availableSubjectDocId: values?.availableSubjectDocId,
        classList: state.classDocIdList,
        optional: state.subjectTypeRadio,
        practical: state.practicalRadio,
      });
    },
  });

  console.log("hgjmn", formik.errors);
  const {
    mutate: mapSubjectWithClassMutate,
    isLoading: mapSubjectWithClassLoading,
  } = useMutation(mapSubjectWithClass, {
    onSuccess: (res) => {
      refetch();
      toast.success(res?.data?.message);
      handleCloseDialog();
      formik.resetForm();
      setState((prev) => ({
        ...prev,
        classDocIdList: [],
        classNameList: [],
        subjectTypeRadio: false,
        practicalRadio: false,
      }));
    },
    onError: (err) => {
      console.log(err);
      toast.error(err?.message);
    },
  });

  const { data: getAvailbaleSubjectsData } = useQuery({
    queryKey: ["getAvailbaleSubjects"],
    queryFn: () =>
      getAvailbaleSubjects({
        session: selectedSession,
      }),
    onSuccess: (data) => {},
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: open ? true : false,
  });

  const { data: getClassDropdownData } = useQuery({
    queryKey: [
      "getClassDropdown",
      selectedSchoolId,
      // ...(verifyIsSchool ? [selectedMedium] : []),
      // ...(verifyIsCollege ? [selectedSemester] : []),
    ],
    queryFn: () =>
      getClassDropdown(selectedSchoolId, {
        // ...(verifyIsCollege && { semester: selectedSemester }),
        // ...(verifyIsSchool && { medium: selectedMedium }),
      }),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: open ? true : false,
  });

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleCloseDialog}
        title="Map Subject With Class"
        minWidth="40vw"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit} id="submit">
            <Grid container spacing={2}>
              {/*Availbae subject */}
              <Grid item xs={6}>
                <TextFieldLabel title={"Subject Name"} required />
                <CustomSelectBox
                  name="availableSubjectDocId"
                  fullWidth
                  MenuProps={MenuProps}
                  onBlur={formik.handleBlur}
                  displayEmpty
                  value={formik.values.availableSubjectDocId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.availableSubjectDocId &&
                    formik.errors.availableSubjectDocId
                  }
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {getAvailbaleSubjectsData?.data?.list?.map((item) => (
                    <MenuItem value={item?._id}>{item?.subjectName}</MenuItem>
                  ))}
                </CustomSelectBox>
              </Grid>
              {/*Multiple Class selection*/}
              <Grid item xs={6}>
                <TextFieldLabel title={"Class-Stream"} required />
                <CustomSelectBox
                  fullWidth
                  displayEmpty
                  multiple
                  value={state.classDocIdList || []}
                  size="small"
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    const selectedClassDocIds = e.target.value;
                    const selectedClassNames = getClassDropdownData?.data?.list
                      .filter((item) =>
                        selectedClassDocIds.includes(item.classDocId)
                      )
                      .map((item) => item.className_stream);
                    setState((prev) => ({
                      ...prev,
                      classDocIdList: selectedClassDocIds,
                      classNameList: selectedClassNames,
                    }));
                  }}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {getClassDropdownData?.data?.list.map((item, idx) => (
                    <MenuItem
                      key={idx}
                      value={item.classDocId}
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          classDocIdList: item.classDocId,
                          classNameList: item?.className_stream,
                        }))
                      }
                    >
                      <Checkbox
                        checked={
                          state.classDocIdList.indexOf(item?.classDocId) > -1
                        }
                      />
                      {item?.className_stream}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
              </Grid>
              {/*Multiple Class selection Chip*/}
              {state.classNameList.length > 0 && (
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    my={2}
                    gap={2}
                    alignItems="center"
                    flexWrap="wrap"
                    mx={2}
                  >
                    {state?.classNameList?.map((item) => (
                      <Chip title={item} deleteIcon={false} />
                    ))}
                  </Stack>
                </Grid>
              )}
              {/*Subject Type*/}
              <Grid item xs={6}>
                <TextFieldLabel title={"Subject Type"} />
                <Stack alignItems={"center"} direction={"row"}>
                  <Radio
                    color="secondary"
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        subjectTypeRadio: false,
                      }))
                    }
                    checked={!state.subjectTypeRadio}
                  />
                  <Typography sx={{ fontWeight: "450", fontSize: "14px" }}>
                    MAIN / MAJOR
                  </Typography>
                </Stack>
                <Stack alignItems={"center"} direction={"row"}>
                  <Radio
                    color="secondary"
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        subjectTypeRadio: true,
                      }))
                    }
                    checked={state.subjectTypeRadio}
                  />
                  <Typography sx={{ fontWeight: "450", fontSize: "14px" }}>
                    OPTIONAL / MINOR
                  </Typography>
                </Stack>
              </Grid>
              {/*Practical*/}
              <Grid item xs={6}>
                <TextFieldLabel title={"Practical"} />
                <Stack alignItems={"center"} direction={"row"}>
                  <Radio
                    color="secondary"
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        practicalRadio: true,
                      }))
                    }
                    checked={state.practicalRadio}
                  />
                  <Typography sx={{ fontWeight: "450", fontSize: "14px" }}>
                    YES
                  </Typography>
                </Stack>
                <Stack alignItems={"center"} direction={"row"}>
                  <Radio
                    color="secondary"
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        practicalRadio: false,
                      }))
                    }
                    checked={!state.practicalRadio}
                  />
                  <Typography sx={{ fontWeight: "450", fontSize: "14px" }}>
                    NO
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={mapSubjectWithClassLoading}
            variant="contained"
            color="secondary"
            size="large"
            // type="submit"
            // form="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default NewSubjectGroupDialog;
