import React, { useState } from "react";
import {
  Stack,
  Typography,
  Checkbox,
  MenuItem,
  useMediaQuery,
  DialogActions,
  Grid,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { addBookGroup, getBooksForSale } from "app/services/ledgerAccount";
import { toast } from "react-toastify";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import Chip from "app/components/common/CustomChip";
import { LoadingButton } from "@mui/lab";
import CustomLoader from "app/components/common/CustomLoader";
import { selectActiveSession } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";

const CustomizeBookContentForGroup = ({
  refetch,
  classDocId = "",
  dialogClose,
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const [state, setState] = useState({
    tableData: [],
    selectedClass: "",
    selectedSeller: "",
    customize: {
      class: "",
      section: "",
      gName: "",
      bookList: [],
    },
    section: "",
  });
  const isMobile = useMediaQuery("(max-width: 768px)");
  const formikCustomize = useFormik({
    initialValues: {
      class: "",
      section: "",
      groupName: "",
      list: [],
    },
    validationSchema: yup.object({
      groupName: yup.string().required("Group Name is required"),
      list: yup.array().required("Please Select some books"),
    }),
    onSubmit: (values) => {
      addBookGroupMutate({
        session: selectedSession,
        classDocId: classDocId,
        bookGroupName: values.groupName,
        bookForSaleDocIdList: values.list,
      });
    },
  });
  const bookList = state?.tableData?.map((item) => ({
    id: item._id,
    title: item.bookTitle,
    sRate: item?.sellingRate,
    bRate: item?.buyingRate,
  }));
  const getBookData = (id) => bookList?.filter((item) => item.id === id)[0];

  const { mutate: addBookGroupMutate, isLoading: addBookGroupLoading } =
    useMutation(addBookGroup, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        refetch();
        dialogClose();
        formikCustomize.resetForm();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });

  const {
    data: getBookForSaleCustomizeData,
    isLoading: getBooksForSaleCustomizeLoading,
    isFetching: getBooksForSaleCustomizeFetching,
  } = useQuery({
    queryKey: ["getBooksForSaleCustomize", classDocId],
    queryFn: () =>
      getBooksForSale({
        session: selectedSession,
        classDocId: classDocId,
      }),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, tableData: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: classDocId ? true : false,
  });
  return (
    <>
      <CustomLoader
        show={
          getBooksForSaleCustomizeLoading || getBooksForSaleCustomizeFetching
        }
      />
      <form id="customizeForm" onSubmit={formikCustomize.handleSubmit}>
        <Stack p={2}>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"Group Name"} />

              <CustomTextField
                fullWidth
                placeholder="Enter Group Name"
                name="groupName"
                value={formikCustomize.values.groupName}
                onChange={(e) => {
                  formikCustomize.handleChange(e);
                  setState((prev) => ({
                    ...prev,
                    customize: {
                      ...prev.customize,
                      gName: e.target.value,
                    },
                  }));
                }}
              />
              {formikCustomize.errors.groupName &&
                formikCustomize.touched.groupName && (
                  <Typography sx={{ color: "red" }}>
                    {formikCustomize.errors.groupName}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"Book Title"} />
              <CustomSelectBox
                renderValue={(selected) => {
                  if (formikCustomize.values.list?.length > 0) {
                    return formikCustomize.values.list
                      ?.map((book) => getBookData(book)?.title)
                      .join(", ");
                  }
                  return <Typography>Select</Typography>;
                }}
                sx={{ width: isMobile ? "100%" : "17rem" }}
                MenuProps={MenuProps}
                name="list"
                multiple={true}
                value={formikCustomize.values.list}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {getBookForSaleCustomizeData?.data?.list?.map((book) => (
                  <MenuItem
                    value={book?._id}
                    onClick={() => {
                      if (formikCustomize.values.list.includes(book?._id)) {
                        formikCustomize.setValues((prev) => ({
                          ...prev,
                          list: prev.list.filter((res) => res !== book?._id),
                        }));
                      } else {
                        formikCustomize.setValues((prev) => ({
                          ...prev,
                          list: [...prev.list, book._id],
                        }));
                      }
                    }}
                    sx={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        checked={formikCustomize.values.list.includes(
                          book?._id
                        )}
                      />
                      <Typography>{`${book?.bookTitle} (${IndianCurrency(
                        book?.sellingRate
                      )})`}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </CustomSelectBox>
              {formikCustomize.errors.list && formikCustomize.touched.list && (
                <Typography sx={{ color: "red" }}>
                  {formikCustomize.errors.list}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Stack
            direction="row"
            my={2}
            gap={2}
            alignItems="center"
            flexWrap="wrap"
          >
            {formikCustomize.values.list?.map((book) => (
              <Chip
                title={`${getBookData(book)?.title} (${IndianCurrency(
                  getBookData(book)?.sRate
                )}) /-`}
                handleClose={() =>
                  formikCustomize.setValues((prev) => ({
                    ...prev,
                    list: prev.list.filter((res) => res !== book),
                  }))
                }
              />
            ))}
          </Stack>
        </Stack>
      </form>

      <DialogActions>
        <LoadingButton
          form={"customizeForm"}
          loading={addBookGroupLoading}
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CustomizeBookContentForGroup;
