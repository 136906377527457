import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchoolId,
} from "app/modules/schools/selectors";
import { Storage } from "aws-amplify";
import { useMutation } from "react-query";
import { uploadS3File } from "app/services/schoolService/misc.service";

const useFolderWiseFileUploader = () => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  let toastId = null;
  const [state, setState] = useState({
    profilePic: "",
    loading: false,
    errorMessage: "",
  });

  const { mutate: uploadS3FileMutate } = useMutation(
    "uploadS3File",
    uploadS3File,
    {
      onSuccess: (success) => {},
      onError: (err) => {
        toast.error(
          err?.response?.data?.message ?? "Failed to upload file to S3"
        );
      },
    }
  );

  const uploadFile = async (file, s3Location, contentType) => {
    try {
      const fileS3Response = await Storage.put(s3Location, file, {
        level: "public",
        contentType: contentType,
        progressCallback(progress) {
          const progressPercentage = Math.round(
            (progress.loaded / progress.total) * 100
          );
          if (!toastId) {
            toastId = toast.loading(`Uploading ${progressPercentage}%`);
          } else {
            toast.update(toastId, {
              render: `Uploading ${progressPercentage}%`,
              type: toast.TYPE.INFO,
              isLoading: true,
              autoClose: false,
            });
          }
        },
      });

      toast.update(toastId, {
        render: "Uploaded successfully",
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        autoClose: 5000,
      });

      setState((prev) => ({
        ...prev,
        loading: false,
        errorMessage: "",
      }));

      toastId = null;
      return {
        fileS3Path: `s3://ekalsutra/public/` + fileS3Response?.key,
        fileURL: `https://ekalsutra.s3.ap-south-1.amazonaws.com/public/${fileS3Response?.key}`,
      };
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: "Error uploading file",
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 5000,
      });
      setState((prev) => ({
        ...prev,
        loading: false,
        errorMessage: "Error uploading file",
      }));
      return { error };
    } finally {
      toastId = null;
      file = null;
      s3Location = null;
      contentType = null;
    }
  };
  const formatFileName = (name) => {
    if (!name) return "";

    const formattedName = name
      .replace(/[^\w\s.-]/g, "")
      .replace(/\s+/g, "_")
      .toLowerCase();

    return formattedName;
  };

  const handleUploadImage = async (
    isFile = false,
    e,
    sessionStatus = "withoutSession",
    moduleName,
    screenName
  ) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      // Determine folder structure
      const folderPrefix =
        sessionStatus === "withSession" ? `${selectedSession}` : "";
      const s3FilePath = `${schoolDocId}/${
        folderPrefix ? `${folderPrefix}/` : ""
      }${moduleName}/${screenName}/${Date.now()}/${
        isFile
          ? formatFileName(e?.name)
          : formatFileName(e.target.files[0]?.name)
      }`;

      let uploadPayload = isFile ? e : e.target.files[0];

      const attachmentUrl = await uploadFile(
        uploadPayload,
        s3FilePath,
        uploadPayload?.type
      );

      setState((prev) => ({
        ...prev,
        profilePic: attachmentUrl?.fileURL || "",
        loading: false,
        errorMessage: attachmentUrl?.error || "",
      }));

      uploadS3FileMutate({
        module: moduleName,
        screenName: screenName,
        fileName: isFile ? e?.name : e.target.files[0]?.name,
        fileUrl: attachmentUrl?.fileURL,
        filePath: s3FilePath,
        fileType: uploadPayload?.type,
        fileSizeInBytes: uploadPayload?.size,
      });

      return attachmentUrl;
    } catch (error) {
      console.error("Error uploading file: ", error);
      toast.error("Error uploading file");
      setState((prev) => ({
        ...prev,
        loading: false,
        errorMessage: "Error uploading file",
      }));
    }
  };

  return { state, handleUploadImage, setState };
};

export default useFolderWiseFileUploader;
