import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let student_url = `${SCHOOL_BASE}/student`;
// export const getStudentByName = (params) => {
//   return schoolTokenAxios.get(`${student_url}/getStudentByName`, {
//     params: params,
//   });
// };
export const getStudentProfile = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentProfile`, {
    params: params,
  });
};
export const getDistinctHouseInfo = (params) => {
  return schoolTokenAxios.get(`${student_url}/getDistinctHouseInfo`, {
    params: params,
  });
};
export const getDeletedStudent = (params) => {
  return schoolTokenAxios.get(`${student_url}/getDeletedStudent`, {
    params: params,
  });
};

export const getStudentsByDocIdList = (body) => {
  return schoolTokenAxios.put(`${student_url}/getStudentsByDocIdList`, body);
};

export const bulkUpdateStudentAcademicInfo = (body) => {
  return schoolTokenAxios.put(
    `${student_url}/bulkUpdateStudentAcademicInfo`,
    body
  );
};

export const getTransportForStudent = (params) => {
  return schoolTokenAxios.get(`${student_url}/getTransportForStudent`, {
    params: params,
  });
};

export const getAllStudentNumber = (params) => {
  return schoolTokenAxios.get(`${student_url}/getAllStudentNumber`, {
    params: params,
  });
};

export const getClassVsStudent = (params) => {
  return schoolTokenAxios.get(`${student_url}/getClassVsStudent`, {
    params: params,
  });
};

export const getAllNewAdmissions = (params) => {
  return schoolTokenAxios.get(`${student_url}/getAllNewAdmissions`, {
    params: params,
  });
};

export const getStudentByAdhar = (id) => {
  return schoolTokenAxios.get(`${student_url}/getStudentByAdhar/${id}`);
};

export const getStudentHealthInfo = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentHealthInfo`, {
    params: params,
  });
};

export const updateStudentPersonalInfoBySchool = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateStudentPersonalInfoBySchool`,
    payload
  );
};

export const retrieveMigratedStudent = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/retrieveMigratedStudent`,
    payload
  );
};
export const updateProfileVerificationStatus = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateProfileVerificationStatus`,
    payload
  );
};
export const deleteStudentPartially = (payload) => {
  return schoolTokenAxios.put(`${student_url}/deleteStudentPartially`, payload);
};
export const retrievePartillyDeletedStudent = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/retrievePartillyDeletedStudent`,
    payload
  );
};

export const updateParentInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateParentInfo`, payload);
};

export const updateAddressInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateAddressInfo`, payload);
};

export const updateBankInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateBankInfo`, payload);
};

export const updateStudentHealthInfo = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateStudentHealthInfo`,
    payload
  );
};

export const changeStudentProfilePic = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/changeStudentProfilePic`,
    payload
  );
};

export const updateHostelInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateHostelInfo`, payload);
};

export const updateHouseInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateHouseInfo`, payload);
};
//********** */ Document Uploading and Deletion***********

export const addDocumentForStudent = (payload) => {
  return schoolTokenAxios.post(`${student_url}/addDocumentForStudent`, payload);
};

export const uploadDocumentForStudent = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/uploadDocumentForStudent`,
    payload
  );
};

export const updateStudentFeeRelaxation = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateStudentFeeRelaxation`,
    payload
  );
};

export const getAllDocumentByStudentDocId = (body) => {
  return schoolTokenAxios.get(
    `${student_url}/getAllDocumentByStudentDocId?studentDocId=${body?.studentDocId}`,
    {
      body,
    }
  );
};

export const deleteDocument = (id) => {
  return schoolTokenAxios.delete(`${student_url}/deleteDocument/${id}`);
};

// *********************************************************
// Admission Approval Module Strats From Here
// *********************************************************
export const getStudentAdmissionRequests = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentAdmissionRequests`, {
    params: params,
  });
};

export const getStudentProfileByAdmissionRequestDocId = (params) => {
  return schoolTokenAxios.get(
    `${student_url}/getStudentProfileByAdmissionRequestDocId`,
    {
      params: params,
    }
  );
};

export const rejectAdmissionRequest = (admissionRequestDocId) => {
  return schoolTokenAxios.get(
    `${student_url}/rejectAdmissionRequest/${admissionRequestDocId}`
  );
};

export const admitStudentAdmissionApproval = (admissionRequestDocId) => {
  return schoolTokenAxios.put(
    `${student_url}/admitStudentAdmissionApproval/${admissionRequestDocId}`
  );
};

export const updateAcademicInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateAcademicInfo`, payload);
};

export const updateSRInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateSRInfo`, payload);
};

export const updateStudentClassInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateStudentClassInfo`, payload);
};

// *********************************************************
// Students Module Strats From Here
// *********************************************************

export const checkAdharExist = (adharNumber) => {
  return schoolTokenAxios.get(`${student_url}/checkAdharExist/${adharNumber}`);
};

export const checkAdharExistInSameSchool = (adharNumber) => {
  return schoolTokenAxios.get(
    `${student_url}/checkAdharExistInSameSchool/${adharNumber}`
  );
};

export const getWholeStudentsName = (params) => {
  return schoolTokenAxios.get(`${student_url}/getWholeStudentsName`, {
    params: params,
  });
};

export const getStudents1 = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudents1`, {
    params: params,
  });
};

export const getStudentsForGivenList = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/getStudentsForGivenList`,
    payload
  );
};

export const getRollNumberInRequestedOrder = (params) => {
  return schoolTokenAxios.get(`${student_url}/getRollNumberInRequestedOrder`, {
    params: params,
  });
};

export const updateRollNumberInRequestedOrder = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateRollNumberInRequestedOrder`,
    payload
  );
};

export const getLastAdmissionCount = (params) => {
  return schoolTokenAxios.get(`${student_url}/getLastAdmissionCount`, {
    params: params,
  });
};
export const getStudentReportForTable = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentReportForTable`, {
    params: params,
  });
};

// Student Profile Related API

export const updateTransportInfoInStudentProfile = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateTransportInfoInStudentProfile`,
    payload
  );
};
// *********************************************************
// Transit Module Strats From Here
// *********************************************************
//Promotion Route Starts From Here

export const getStudentForPromotionOrMigration = (params) => {
  return schoolTokenAxios.get(
    `${student_url}/getStudentForPromotionOrMigration`,
    {
      params: params,
    }
  );
};

export const promoteStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/promoteStudent`, payload);
};

export const getStudentDataForMigrate = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentDataForMigrate`, {
    params: params,
  });
};

export const migrateStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/migrateStudent`, payload);
};

export const getAlumni = (params) => {
  return schoolTokenAxios.get(`${student_url}/getAlumni`, {
    params: params,
  });
};

export const updateTransferCertificate = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateTransferCertificate`,
    payload
  );
};

export const getTransferCertificate = (params) => {
  return schoolTokenAxios.get(`${student_url}/getTransferCertificate`, {
    params: params,
  });
};

// Version 3 Starts Here

// registerFreshStudent

export const registerFreshStudent = (payload) => {
  return schoolTokenAxios.post(`${student_url}/registerFreshStudent`, payload);
};

export const suspendStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/suspendStudent`, payload);
};
export const inActivateStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/inActivateStudent`, payload);
};
export const activateStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/activateStudent`, payload);
};

export const retrieveSuspendedStudent = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/retrieveSuspendedStudent`,
    payload
  );
};

// export const addStudentByExcel = (payload) => {
//   return schoolTokenAxios.post(`${student_url}/addStudentByExcel`, payload);
// };

export const addStudentByExcel = (file) => {
  const formData = new FormData();
  formData.append("studentData", file);
  for (var pair of formData.entries()) {
  }

  return schoolTokenAxios.post(`${student_url}/addStudentByExcel`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
