import { createSelector } from "@reduxjs/toolkit";

export const isGroupOfInstituteSelector = (state) =>
  state.schools.isGroupOfInstitute;
export const schoolListSelector = (state) => state.schools.list;
export const selectSelectedSchoolId = (state) => state.schools.selectedSchoolId;

export const selectSelectedSchool = createSelector(
  [schoolListSelector, selectSelectedSchoolId],
  (list, id) => list.find((x) => x._id === id)
);

export const selectMedium = (state) => state.schools.mediumList;
export const selectSession = (state) => state.schools.sessionList;
export const selectSemester = (state) => state.schools.semesterList;
export const isSchool = (state) => state.schools.isSchool;
export const isCollege = (state) => state.schools.isCollege;
export const sessionMonths = (state) => state.schools?.sessionMonth;

export const selectActiveSession = (state) => state.schools.selectedSession;
export const selectActiveSemester = (state) =>
  state.schools.selectedActiveSemester;
export const selectedMediumSelector = (state) => state.schools.selectedMedium;
export const selectedSemesterSelector = (state) =>
  state.schools.selectedActiveSemester;

export const getMediumList = createSelector(
  [schoolListSelector, selectSelectedSchoolId],
  (list, id) => {
    let selectSchoolDetail = list.find((x) => x._id === id);
    let mediumList = [];
    if (selectSchoolDetail) {
      mediumList = selectSchoolDetail.medium;
    }
    return mediumList;
  }
);

export const userPrimaryRoleSelector = createSelector(
  [schoolListSelector, selectSelectedSchoolId],
  (list, id) => {
    let selectSchoolDetail = list.find((x) => x._id === id);
    let primaryRole;
    if (selectSchoolDetail) {
      primaryRole = selectSchoolDetail.primaryRole;
    }
    return primaryRole;
  }
);

export const userNameSelector = (state) => {
  let fullName = `${state.schools.userDetail?.firstName} ${state.schools.userDetail?.lastName}`;

  return fullName;
};

export const userFirstNameSelector = (state) =>
  state.schools.userDetail?.firstName;

export const userDetailSelector = (state) => state.schools.userDetail;

export const sideBarOptionsSelector = (state) =>
  state.schools.menuOptionsToShow;

export const selectedSchoolDetailSelector = (state) =>
  state.schools.selectedSchoolDetail;

export const schoolAccessTokenSelector = (state) =>
  state.schools.schoolAccessToken;
