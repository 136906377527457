import { Container, useMediaQuery } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Box } from "@mui/system";
import FeeStructureComponent from "./FeeStructureComponent";
import FeeType from "./FeeType";
import FeeRelaxationCategory from "./FeeRelaxationCategory";
import YouTubeFloating from "app/components/common/floatingButton/YouTubeFloating";
import { FeeStructureLinks } from "app/utils/youTubeLink";

const FeeStructure = () => {
  const [value, setValue] = React.useState("1");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>Transport Detail</title>
      </Helmet>

      <YouTubeFloating videoLink={FeeStructureLinks} />

      <Container
        maxWidth="xl"
        style={{ height: "100%" }}
        disableGutters={isMobile ? true : false}
      >
        <TabContext value={value}>
          <TabsWrapper>
            <TabList onChange={handleChange}>
              <Tab label="Fee Structure" value="1" />
              <Tab label="Fee Type" value="2" />
              <Tab label="Fee Relaxation Category" value="3" />
            </TabList>
          </TabsWrapper>
          <TabPanel value="1" sx={{ p: "0 !important", height: "92%" }}>
            <Box sx={{ mt: 1, height: "100%" }}>
              <FeeStructureComponent />
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ p: "0 !important", height: "92%" }}>
            <Box sx={{ mt: 1, height: "100%" }}>
              <FeeType />
            </Box>
          </TabPanel>
          <TabPanel value="3" sx={{ p: "0 !important", height: "92%" }}>
            <Box sx={{ mt: 1, height: "100%" }}>
              <FeeRelaxationCategory />
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
};

export default FeeStructure;
