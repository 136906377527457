import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let access_url = `${SCHOOL_BASE}/access`;

export const getSchoolPermissions = (params) => {
  return schoolTokenAxios.get(`${access_url}/getSchoolPermissions`, {
    params: params,
  });
};

export const getRolePermissionMap = (params) => {
  return schoolTokenAxios.get(`${access_url}/getRolePermissionMap`, {
    params: params,
  });
};

export const addRolePermissionMap = (payload) => {
  return schoolTokenAxios.post(`${access_url}/addRolePermissionMap`, payload);
};

export const updateRolePermissionMap = (payload) => {
  return schoolTokenAxios.put(`${access_url}/updateRolePermissionMap`, payload);
};

export const deleteRolePermissionMap = (payload) => {
  return schoolTokenAxios.put(`${access_url}/deleteRolePermissionMap`, payload);
};

export const getSchoolStaffPermissions = (params) => {
  return schoolTokenAxios.get(`${access_url}/getSchoolStaffPermissions`, {
    params: params,
  });
};

export const updateSchoolStaffPermission = (payload) => {
  return schoolTokenAxios.put(
    `${access_url}/updateSchoolStaffPermission`,
    payload
  );
};

export const removeStaffPermission = (payload) => {
  return schoolTokenAxios.put(`${access_url}/removeStaffPermission`, payload);
};

export const grantRoleBasedAccess = (payload) => {
  return schoolTokenAxios.put(`${access_url}/grantRoleBasedAccess`, payload);
};

export const getMyPermissionByScreen = (params) => {
  return schoolTokenAxios.get(`${access_url}/getMyPermissionByScreen`, {
    params,
  });
};
