import { createSlice } from "@reduxjs/toolkit";
import {
  getSchoolList,
  getSchoolSessionList,
  getLoggedInUserDetail,
  getSchoolToken,
} from "./thunk";

const initialState = {
  isGroupOfInstitute: false,
  count: 0,
  list: [],
  selectedSchoolId: null,
  selectedSchoolDetail: null,
  schoolAccessToken: null,
  schoolRefreshToken: null,
  menuOptionsToShow: null,
  sessionList: [],
  sessionMonth: [],
  mediumList: [],
  // semesterList: [],
  selectedSession: "",
  selectedMedium: "",
  // selectedSemester: "",
  // selectedActiveSemester: "",
  isSchool: false,
  isCollege: false,
  userDetail: null,
  token_isLoading: false,
};

export const schoolsSlice = createSlice({
  name: "schools",
  initialState,
  reducers: {
    setSelectedSession: (state, action) => {
      state.selectedSession = action.payload.sessionName;
    },
    setSessionMonth: (state, action) => {
      state.sessionMonth = action.payload;
    },
    setSelectedMedium: (state, action) => {
      state.selectedMedium = action.payload.medium;
    },
    // setSelectedSemester: (state, action) => {
    //   state.selectedSemester = action.payload.semester;
    // },
    // setSelectedActiveSemester: (state, action) => {
    //   state.selectedActiveSemester = action.payload.activeSemester;
    // },
    resetSchoolSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSchoolList.fulfilled, (state, action) => {
      state.isGroupOfInstitute = action.payload.obj.isGroupOfInstitute;
      state.count = action.payload.obj.count;
      state.list = action.payload.obj.instituteList;
    });
    builder.addCase(getSchoolSessionList.fulfilled, (state, action) => {
      state.sessionList = action.payload;
    });

    builder.addCase(getLoggedInUserDetail.fulfilled, (state, action) => {
      state.userDetail = action.payload;
    });
    builder.addCase(getSchoolToken.pending, (state) => {
      state.token_isLoading = true;
    });
    builder.addCase(getSchoolToken.fulfilled, (state, action) => {
      console.log("action.payload", action.payload.school_detail);
      state.schoolAccessToken = action.payload.accessToken;
      state.schoolRefreshToken = action.payload.refreshToken;
      state.menuOptionsToShow = action.payload.menuOptionsToShow;

      // Set Selected School Id
      state.selectedSchoolId = action.payload.school_detail._id;

      // Set Active session of school
      state.selectedSession = action.payload.school_detail.activeSession;

      // Set Semester of college
      // state.selectedActiveSemester =
      //   action.payload.school_detail.activeSemester;

      // Set Session List
      state.sessionList = action.payload.school_detail.sessionList;

      // Set Medium List
      state.mediumList = action.payload.school_detail.medium;
      // state.semesterList = action.payload.school_detail.semesterList;
      // state.isSchool = action.payload.school_detail.isSchool;
      // state.isCollege = action.payload.school_detail.isCollege;

      // set selected medium
      if (
        action.payload.school_detail &&
        action.payload.school_detail.medium &&
        action.payload.school_detail.medium.length > 0
      ) {
        state.selectedMedium = action.payload.school_detail.medium[0];
      }
      // set selected medium
      // if (
      //   action.payload.school_detail &&
      //   action.payload.school_detail.semesterList &&
      //   action.payload.school_detail.semesterList.length > 0
      // ) {
      //   state.selectedSemester = action.payload.school_detail.semesterList[0];
      // }

      // Set Selected School Detail
      state.selectedSchoolDetail = action.payload.school_detail;
      state.token_isLoading = false;
    });
    builder.addCase(getSchoolToken.rejected, (state) => {
      state.token_isLoading = false;
    });
  },
});

export default schoolsSlice.reducer;
