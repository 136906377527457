import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let misc_url = `${SCHOOL_BASE}/misc`;

export const uploadS3File = (body) => {
  return schoolTokenAxios.post(`${misc_url}/uploadS3File`, body);
};

export const getS3Folder = (params) => {
  return schoolTokenAxios.get(`${misc_url}/getS3Folder`, {
    params,
  });
};

export const getS3File = (params) => {
  return schoolTokenAxios.get(`${misc_url}/getS3File`, {
    params,
  });
};

export const deleteS3FileObject = (s3FileObjectDocId) => {
  return schoolTokenAxios.delete(
    `${misc_url}/deleteS3FileObject/${s3FileObjectDocId}`
  );
};
