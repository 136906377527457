import { Box, Container, Tab } from "@mui/material";
import React from "react";
import TransferCertificate from "./TransferCertificate";
import Alumni from "./Alumni";
import { Helmet } from "react-helmet";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import OfflineTC from "./OfflineTC";
import YouTubeFloating from "app/components/common/floatingButton/YouTubeFloating";
import { StudentManagementTransferCertificatelinks } from "app/utils/youTubeLink";

const AlumniIndex = () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>Alumni</title>
      </Helmet>

      <YouTubeFloating videoLink={StudentManagementTransferCertificatelinks} />

      <Container maxWidth="xl">
        <TabContext value={value}>
          <TabsWrapper>
            <TabList onChange={handleChange}>
              <Tab label="Transfer Certificate" value="1" />
              <Tab label="Alumni" value="2" />
              <Tab label="Offline TC" value="3" />
              {/* <Tab label="Biometric Attendance" value="3" /> */}
            </TabList>
          </TabsWrapper>
          <TabPanel value="1" sx={{ p: "0 !important" }}>
            <Box sx={{ py: 1.5 }}>
              <TransferCertificate />
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ p: "0 !important" }}>
            <Box sx={{ py: 1.5 }}>
              <Alumni />
            </Box>
          </TabPanel>
          <TabPanel value="3" sx={{ p: "0 !important" }}>
            <Box sx={{ py: 1.5 }}>
              <OfflineTC />
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
};

export default AlumniIndex;
